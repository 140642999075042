import store from '@/store';

export default {
  backToLogin() {
    // let newUrl =
    //   window.location.protocol +
    //   '//' +
    //   window.location.host +
    //   window.location.pathname +
    //   '?ReturnUrl=%2fmanagement%2fending-letter%2f';
    //
    // window.history.pushState({path: newUrl}, '', newUrl);
    window.location.pathname = '/login';
  },
  formatDate(date) {
    if (!date) return null;
    let [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
  },
  getDateFromIso(date) {
    if (!date) return null;
    let localDate = new Date(date).toLocaleDateString('en-GB');
    let [simpleDay, simpleMonth, simpleYear] = localDate.split('/');
    return `${simpleDay}.${simpleMonth}.${simpleYear}`;
  },
  getTimeFromIso(date) {
    if (!date) return null;
    let localTime = new Date(date).toLocaleTimeString('en-GB');
    let [hourse, minutes] = localTime.split(':');
    return `${hourse}:${minutes}`;
  },
  getDateFromUtc: function (date) {
    if (!date) return null;
    let isoDate = `${date}Z`;
    let localDate = new Date(isoDate).toLocaleDateString('en-GB');
    let [simpleDay, simpleMonth, simpleYear] = localDate.split('/');
    return `${simpleDay}.${simpleMonth}.${simpleYear}`;
  },
  getTimeFromUtc: function (date) {
    if (!date) return null;
    let isoDate = `${date}Z`;
    let localTime = new Date(isoDate).toLocaleTimeString('en-GB');
    let [hourse, minutes] = localTime.split(':');
    return `${hourse}:${minutes}`;
  },
  getSimpleDate: function (date) {
    if (!date) return null;
    let dateOnly = date.split('T')[0];
    let [simpleYear, simpleMonth, simpleDay] = dateOnly.split('-');
    return `${simpleDay}.${simpleMonth}.${simpleYear}`;
  },
  getSimpleTime: function (date) {
    if (!date) return null;
    let time = date.split('T')[1];
    let [hourse, minutes] = time.split(':');
    return `${hourse}:${minutes}`;
  },
  downloadURL(url) {
    var hiddenIFrameID = 'hiddenDownloader',
        iframe = document.getElementById(hiddenIFrameID);
    if (iframe === null) {
      iframe = document.createElement('iframe');
      iframe.id = hiddenIFrameID;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }
    iframe.src = url;
  },
  downloadBlob(url, fileName) {
    let link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  getUrlParameter(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  checkNull(string) {
    if (string === 'null' || string === null) {
      return '';
    } else {
      return string;
    }
  },
  checkLength(string) {
    if (string && string.length > 0) {
      return string;
    } else {
      return null;
    }
  },
  setCookie(name, value, days) {
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + days);
    let c_value = encodeURIComponent(value) + ((days == null) ? '' : ('; expires=' + exdate.toUTCString()));
    document.cookie = name + '=' + c_value;
  },
  getCookie(name) {
    let i, x, y, ARRcookies = document.cookie.split(';');
    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x === name) {
        return unescape(y);
      }
    }
  },
  isAdminHost() {
    let host = window.location.hostname;
    return host.indexOf('admin') >= 0;
  },
  isLocalhost() {
    let host = window.location.hostname;
    return host.indexOf('localhost') >= 0;
  },
  getHeaderParameter(name, headerString) {
    let i, x, y, ARRvalues = headerString.split(';');
    for (i = 0; i < ARRvalues.length; i++) {
      x = ARRvalues[i].substr(0, ARRvalues[i].indexOf('='));
      y = ARRvalues[i].substr(ARRvalues[i].indexOf('=') + 1);
      x = x.replace(/^\s+|\s+$/g, '');
      if (x === name) {
        return unescape(y);
      }
    }
  },
  encodeBlob(data) {
    // encoding csv data
    let charCode, byteArray = [];
    // LE BOM
    byteArray.push(255, 254);
    for (let i = 0; i < data.length; ++i) {
      charCode = data.charCodeAt(i);
      // LE Bytes
      byteArray.push(charCode & 0xff);
      byteArray.push(charCode / 256 >>> 0);
    }
    return new Blob([new Uint8Array(byteArray)], {type:'text/csv;charset=UTF-16LE;'});
  },
  showGlobalSnackbar(color, text) {
    store.commit('changeGlobalSnackbarColor', color);
    store.commit('changeGlobalSnackbarText', text);
    store.commit('changeGlobalSnackbar', true);
  },
  getErrors(response) {
    if (response.status === 500) {
      return '!!! SERVER ERROR !!!';
    } else {
      if (!response.data && !response.data.errors && !response.data.message) return '!! SOMETHING WENT WRONG !!';
      if (response.data.errors) {
        let errors = Object.keys(response.data.errors).map(key => [key, response.data.errors[key]]);
        return errors[0][1].join(' ');
      }
      if (response.data.message) {
        return response.data.message;
      }
    }
  },
};
