export const VISIT_TYPES = {
  SIMPLE_KEY: 'simple',
  SIMPLE_NAME: 'Normal henvisning',
  EDI_KEY: 'edi',
  EDI_NAME: 'EDI henvisning',
}

export const VISIT_TYPES_LIST = [
  {
    key: VISIT_TYPES.SIMPLE_KEY,
    name: VISIT_TYPES.SIMPLE_NAME
  },
  {
    key: VISIT_TYPES.EDI_KEY,
    name: VISIT_TYPES.EDI_NAME
  }
]
