<template>
  <v-container class="management-tabs__container py-0 pb-16">
    <TrackingFilters/>
    <TrackingData v-if="showDataTable"/>
  </v-container>
</template>

<script>
  import TrackingFilters from '../../components/endingLetter/EndingLetterFilters';
  import TrackingData from '../../components/endingLetter/EndingLetterData';

  export default {
    name: 'EndingLetter',

    components: {
      TrackingFilters,
      TrackingData,
    },

    mounted: function () {
      // Google Analytics
      ga('set', 'page', 'management/ending-letter');
      ga('send', 'pageview');
      // End Google Analytics
    },

    computed: {
      showDataTable: function () {
        return this.$store.state.endingLetters.showDataTable;
      },
    },

    destroyed: function () {
      this.$store.commit('endingLetters/updateShowDataTable', false);
    },
  };
</script>
