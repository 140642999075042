<template>
  <v-col cols="12" xl="4" class="px-0 d-flex justify-xl-end align-center">
    <v-icon v-if="success && !loading" color="success" class="mr-2">mdi-check</v-icon>
    <v-progress-circular v-if="loading" indeterminate color="blue-grey" :width="2" :size="15" class="mr-2"/>
    <v-checkbox class="my-0 py-0" :input-value="sendEdiTerminationAsEmail" readonly @click="updateTerminationAsEmail()" hide-details>
      <template v-slot:label>
        Send EDI ubrev via systemmail
      </template>
    </v-checkbox>
  </v-col>
</template>

<script>
  import api from '@/api/api';

  export default {
    name: 'SendAsEmailCheckbox',

    props: ['sendEdiTerminationAsEmail', 'visitItem', 'number'],

    data: function () {
      return {
        loading: false,
        success: false
      }
    },

    methods: {
      updateTerminationAsEmail: async function () {
        if (this.loading) return;
        try {
          this.loading = true;
          await api.updateEdiLetterAsEmail(this.visitItem.id, this.number, {sendEdiTerminationAsEmail: !this.sendEdiTerminationAsEmail});
          let newVisit = await api.getTerminationLetters({VisitNumber: this.visitItem.id});
          this.loading = false;
          this.success = true;
          this.$emit('updateVisitItem', newVisit.data.visits[0]);
        } catch (e) {
          this.loading = false;
        }
      },
    },
  };
</script>
