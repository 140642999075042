<template>
  <v-container fluid class="py-6">
    <v-row align="center" justify="space-between" class="my-0">
      <v-col cols="12" md="4" class="py-0 mb-4 mb-md-0">
        <v-select class="my-0 py-0" v-model="mode" :items="modeVariants" item-text="name" item-value="key" label="Vælg funktion for markerede sager" hide-details/>
      </v-col>
      <template v-if="mode === 'status'">
        <v-col cols="12" md="4" class="py-0 mb-3 mb-md-0">
          <v-select class="my-0 py-0" v-model="selectedStatus" :items="statusTypes" item-text="label" item-value="key" label="Vælg ny status" hide-details>
            <template v-slot:selection="{ item, index }">
              <div class="d-flex flex-nowrap align-center" style="max-width: 100%">
                <span :class="[item.color, 'status-label', 'mr-2']"/>
                <span class="text-body-2 text-truncate d-block">{{item.label}}</span>
              </div>
            </template>
            <template v-slot:item="{ item, index }">
              <span :class="[item.color, 'status-label', 'mr-2']"/>
              <span class="text-body-2">{{item.label}}</span>
            </template>
          </v-select>
        </v-col>
        <v-spacer/>
        <v-col cols="12" md="auto" class="py-0">
          <v-btn depressed outlined :color="statusButtonColor" :loading="statusLoading" :disabled="statusLoading || selectedVisits.length <= 0" @click="massStatusUpdate">Udfør og gem</v-btn>
        </v-col>
      </template>
      <template v-if="mode === 'export'">
        <v-col cols="12" md="auto" class="py-0">
          <v-btn depressed outlined :color="exportButtonColor" :loading="exportLoading" :disabled="exportLoading || selectedVisits.length <= 0" @click="csvExportVisits">Eksporter markerede</v-btn>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
  import api from '../../api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'OverviewMassStatus',

    props: ['selectedVisits'],

    data: function () {
      return {
        modeVariants: [
          {
            key: 'status',
            name: 'Ændre status for markerede patienter'
          },
          {
            key: 'export',
            name: 'Eksporter markerede patienter'
          }
        ],
        mode: 'status',
        statusLoading: false,
        statusButtonColor: 'primary',
        selectedStatus: 0,
        exportLoading: false,
        exportButtonColor: 'primary',
      };
    },

    computed: {
      statusTypes: function () {
        return this.$store.state.overview.statusTypes;
      },
    },

    methods: {
      massStatusUpdate: async function() {
        try {
          let newData = new FormData();
          this.selectedVisits.forEach((item) => {
            newData.append('VisitIds[]', item.id);
          });
          newData.append('Status', this.selectedStatus);
          this.statusLoading = true;
          await api.massStatusUpdate(newData);
          this.$emit('massStatusUpdate', this.selectedStatus);
          this.selectedStatus = 0;
          this.statusLoading = false;
        } catch (e) {
          this.statusLoading = false;
          this.statusButtonColor = 'error';
        }
      },
      csvExportVisits: async function () {
        try {
          let queryData = {};
          let visitIds = [];
          this.selectedVisits.forEach((item) => {
            visitIds.push(item.id);
          });
          queryData.ids = visitIds;
          this.exportLoading = true;
          let data = await api.exportVisitsById(queryData);
          methods.downloadBlob(URL.createObjectURL(methods.encodeBlob(data.data)), methods.getHeaderParameter('filename', data.headers['content-disposition']));
          this.$emit('massCsvExportUpdate');
          this.exportLoading = false;
        } catch (e) {
          this.exportLoading = false;
          this.exportButtonColor = 'error';
        }
      },
    }
  };
</script>
