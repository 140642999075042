<template>
  <div>
    <v-container class="management-tabs__container py-0">
      <v-alert type="success" text class="py-10">
        <h3 class="mb-3">TAK FOR DIN HENVISNING</h3>
        <p class="my-0">Din henvisning ligger nu under listen over henvisninger. Du kan altid tjekke status på dine sager under oversigten.</p>
      </v-alert>
    </v-container>
    <v-container fluid class="pb-12">
      <v-row justify="center" class="my-0">
        <v-col cols="auto" class="py-0">
          <v-btn depressed x-large color="primary" to="/management/henvisning-tandlaeger">Lav en ny henvisning</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'VisitSent',

    mounted: function () {
      // Google Analytics
      ga('set', 'page', 'management/visit-sent');
      ga('send', 'pageview');
      // End Google Analytics
    },
  };
</script>