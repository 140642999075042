<template>
  <v-container fluid class="py-6">
    <div class="d-flex justify-space-between align-center">
      <div>
        <slot name="title"/>
      </div>
      <v-btn
        v-if="!csvExport"
        depressed
        :color="btnColor ? btnColor : 'primary'"
        :class="btnClass"
        @click="fileTrigger"
      >
        Upload csv her
      </v-btn>
      <v-progress-linear
        v-else
        rounded
        striped
        :color="progressColor ? progressColor : 'primary'"
        :value="csvExportValue"
        height="36"
        style="width: 168px"
      >
        <template v-slot:default="{ value }">
          <strong class="white--text">{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
      <input ref="fileUploader" class="d-none" accept=".csv" type="file" @input="addFile($event)"/>
    </div>
  </v-container>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: "CsvExport",

    props: ['btnColor', 'btnClass', 'progressColor'],

    data: function () {
      return {
        file: null,
        buttonColor: 'primary',
      };
    },

    computed: {
      csvExport: function () {
        return this.$store.state.csvExport
      },
      csvExportValue: function () {
        return this.$store.state.csvExportValue
      },
      csvExportId: function () {
        return this.$store.state.csvExportId
      },
    },

    methods: {
      fileTrigger: function () {
        this.$refs.fileUploader.click();
      },
      addFile: function (data) {
        if (data.target.files.length > 0) {
          let fileType = data.target.files[0].name.split('.').pop().toLowerCase();
          switch (fileType) {
            case 'csv':
              this.file = data.target.files[0];
              this.uploadFile();
              break;
            default:
              methods.showGlobalSnackbar('error', 'Denne filtype er ikke godkendt i dette felt.');
          }
          data.target.value = '';
        }
      },
      trackProcess: async function() {
        let trackUploadProcess = setInterval(async () => {
          try {
            let fileData = await api.trackUploadAppointmentCsv({fileTrackId: this.csvExportId});
            fileData.data[0] && (this.$store.commit('updateCsvExportValue', fileData.data[0]['progressPercentage']));
            if (fileData.data[0] && fileData.data[0]['status'] === 1) {
              this.$emit('exportFinished');
              this.$store.commit('updateCsvExportId', null);
              this.$store.commit('updateCsvExport', false);
              this.$store.commit('updateAppointmentAlert', false);
              clearInterval(trackUploadProcess);
            }
            if (fileData.data[0] && fileData.data[0]['status'] === 2) {
              methods.showGlobalSnackbar('error', 'Eksport csv mislykkedes.');
              this.$emit('exportFinished');
              this.$store.commit('updateCsvExportId', null);
              this.$store.commit('updateCsvExport', false);
              clearInterval(trackUploadProcess);
            }
          } catch (e) {
            clearInterval(trackUploadProcess);
            methods.showGlobalSnackbar('error', 'Eksport csv mislykkedes.');
          }
        }, 3000);
      },
      uploadFile: async function () {
        let queryData = new FormData();
        queryData.append(`AppointmentFile`, this.file);
        this.$store.commit('updateCsvExportValue', 0);
        try {
          this.$store.commit('updateCsvExport', true);
          let uploadData = await api.uploadAppointmentCsv(queryData);
          this.$store.commit('updateCsvExportId', uploadData.data['fileTrackId']);
          this.trackProcess();
        } catch (e) {
          this.$store.commit('updateCsvExport', false);
          methods.showGlobalSnackbar('error', 'Eksport csv mislykkedes.');
        }
      }
    }
  };
</script>
