<template>
  <div class="download-radiographs py-5">
    <v-container>
      <v-row justify="center">
        <v-col cols="9">
          <v-card outlined>
            <v-card-text>
              <a href="/" class="download-radiographs__logo-holder">
                <img src="../images/kk-logo.svg" alt="KIRURGIKLINIK"/>
              </a>
              <v-progress-linear v-if="loading" indeterminate color="blue-grey" :height="1"/>
              <v-alert v-else-if="expired" type="info" color="#3b6287">
                Kære patient,
                <br>Linket til at downloade dine røntgen er desværre udløbet. Hvis du ønsker et nyt link, så kontakt os venligst på: <a href="mailto:kontakt@kirurgiklinik.dk" style="color: white;">kontakt@kirurgiklinik.dk</a>
                <br>
                <br>Med venlig hilsen
                <br>Kirurgiklinikken
              </v-alert>
              <div v-else>
                <div v-if="radiographs.length">
                  <div class="text-center mb-5" style="font-size: 15px; line-height: 1.2">Bemærk filerne gemmes i appen Arkiver på iPhone, og appen Downloads på android.</div>
                  <div class="mb-5">
                    <v-chip class="ma-1" color="grey lighten-2" label v-for="file in radiographs" :key="file.radiographId" @click="downloadFile(file.radiographId, file.fileName)">
                      <span class="text-truncate" style="max-width: 220px">{{ file.fileName }}</span>
                      <span class="download-radiographs__icon-holder">
                      <v-progress-circular v-if="downloadQueue.includes(file.radiographId)" :size="15" :width="1" indeterminate/>
                      <v-icon v-else size="22">mdi-download</v-icon>
                    </span>
                    </v-chip>
                  </div>
                  <v-btn block depressed x-large color="primary" :disabled="zipLoading" :loading="zipLoading" @click.stop="downloadZip">
                    <v-icon left size="22">icon-download</v-icon>
                    Alle filer - ZIP
                  </v-btn>
                </div>
                <div v-else class="text-body-1 grey--text py-5">Ingen data...</div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'DownloadRadiographs',

    metaInfo: {
      meta: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    },

    data: function () {
      return {
        loading: false,
        expired: false,
        zipLoading: false,
        loginStatus: false,
        radiographs: [],
        downloadQueue: [],
      };
    },

    mounted: async function () {
      this.getRadiographs();
    },

    methods: {
      getToken: function () {
        return methods.getUrlParameter('tk');
      },
      getRadiographs: async function () {
        if (!this.getToken()) return;
        try {
          this.loading = true;
          let radiographsData = await api.getRadiographs(this.getToken());
          radiographsData.data && radiographsData.data.length && (this.radiographs = radiographsData.data);
          this.loading = false;
        } catch (e) {
          if (e.response.status === 401 || e.response.status === 403) {
            this.expired = true;
          }
          this.loading = false;
        }
      },
      downloadFile: async function (radiographId, fileName) {
        this.downloadQueue = [...this.downloadQueue, radiographId];
        try {
          let fileData = await api.downloadRadiograph(radiographId, this.getToken());
          const url = window.URL.createObjectURL(new Blob([fileData.data]));
          methods.downloadBlob(url, fileName);
          this.downloadQueue = this.downloadQueue.filter(id => id !== radiographId);
        } catch (e) {
        }
      },
      downloadZip: async function () {
        try {
          this.zipLoading = true;
          let fileData = await api.downloadRadiographZip(this.getToken());
          const url = window.URL.createObjectURL(new Blob([fileData.data]));
          methods.downloadBlob(url, 'Kirurgiklinik.zip');
          this.zipLoading = false;
        } catch (e) {
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .download-radiographs {
    background: #233241;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__icon-holder {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 10px;
    }

    &__logo-holder {
      display: block;
      width: 150px;
      margin: 15px auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
</style>
