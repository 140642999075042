<template>
  <div class="mb-13">
    <v-row class="my-0">
      <v-col cols="12" lg="6" class="py-0 pt-2 mb-5 mb-lg-0 pr-xl-5">
        <HeadingBox icon="mdi-timer-outline" title="Send behandlingstid til patient manuelt" :loading="appointmentsLoading">
          <div v-if="!visitItem['patientEmail']" class="d-flex align-center text-body-2 mb-3 red--text">
            <v-icon size="20" class="mr-1 red--text">mdi-alert-circle</v-icon>
            Patienten har ingen e-mail
          </div>
          <div v-else class="d-flex align-center text-body-2 mb-3">
            <v-icon size="20" class="mr-1">mdi-at</v-icon>
            {{ visitItem['patientEmail'] }}
          </div>
          <template v-if="appointmentsList.length > 0">
            <div v-for="appointmentItem in appointmentsList" class="mb-2 d-flex flex-wrap align-center">
              <span class="text-body-1 mr-4 text-no-wrap">{{ appointmentItem['formattedDate'] }}</span>
              <v-icon v-if="appointmentItem['sendAppointmentInfoType'] === 0" size="20" class="mr-4">mdi-at</v-icon>
              <v-icon v-if="appointmentItem['sendAppointmentInfoType'] === 1" size="20" class="mr-4">mdi-email</v-icon>
              <v-btn v-if="appointmentItem['content']" icon @click="openAppointmentDialog(appointmentItem['content'])">
                <v-icon size="20" class="grey--text text--darken-2">mdi-eye</v-icon>
              </v-btn>
            </div>
          </template>
          <span v-if="appointmentsList.length === 0" class="d-block text-body-1 grey--text">Ingen data...</span>
        </HeadingBox>
      </v-col>
      <v-col cols="12" lg="6" class="py-0 pl-md-16 pl-lg-0">
        <div class="d-md-flex align-center justify-lg-end">
          <!--<v-radio-group hide-details row v-model="appointmentType" class="my-0 pt-0 mr-9 mb-4 mb-md-0">-->
          <!--  <v-radio class="my-2 mr-14" label="Email" :value="0" color="primary"/>-->
          <!--  <v-radio class="my-2" label="SMS" :value="1" color="primary"/>-->
          <!--</v-radio-group>-->
          <v-btn min-width="150" depressed :color="appointmentSendButtonColor" :loading="appointmentSendLoading" :disabled="appointmentSendLoading || appointmentsLoading || !visitItem.patientEmail" @click="sendAppointment">Send tid</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="appointmentDialog" width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closeAppointmentDialog">
      <v-card>
        <v-card-text class="py-4">
          <div v-html="appointmentDialogContent" class="pa-3 rounded" style="background-color: rgba(0, 0, 0, 0.05);"/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="black darken-1" text @click="appointmentDialog = false; closeAppointmentDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import HeadingBox from '../common/HeadingBox';
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'OverviewAppointment',

    components: {HeadingBox},

    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        appointmentDialog: false,
        appointmentDialogContent: null,
        appointmentsLoading: false,
        appointmentsList: [],
        appointmentType: 0,
        appointmentSendLoading: false,
        appointmentSendButtonColor: 'primary',
      };
    },

    mounted: function () {
      this.getAppointments();
    },

    methods: {
      openAppointmentDialog: function (content) {
        this.appointmentDialogContent = content;
        this.appointmentDialog = true;
      },
      closeAppointmentDialog: function () {
        setTimeout(() => {
          this.appointmentDialogContent = null;
        }, 100);
      },
      getAppointments: async function () {
        this.appointmentsLoading = true;
        let newAppointments = await api.getAppointments(this.visitItem.id);
        this.appointmentsLoading = false;
        newAppointments.data.forEach((item) => {
          item.formattedDate = `${methods.getDateFromUtc(item['sendDateTime'])}, ${methods.getTimeFromUtc(item['sendDateTime'])}`;
        });
        this.appointmentsList = newAppointments.data;
      },
      sendAppointment: async function () {
        try {
          this.appointmentSendLoading = true;
          let newData = {};
          newData.sendAppointmentInfoType = this.appointmentType;
          newData.email = this.visitItem.patientEmail;
          let newAppointments = await api.sendAppointment(this.visitItem.id, newData);
          if (newAppointments.status === 204) {
            methods.showGlobalSnackbar('error', 'Udfyld venligst E-mail-feltet.');
          } else {
            newAppointments.data.forEach((item) => {
              item.formattedDate = `${methods.getDateFromUtc(item['sendDateTime'])}, ${methods.getTimeFromUtc(item['sendDateTime'])}`;
            });
            this.appointmentsList = newAppointments.data;
            this.appointmentSendLoading = false;
          }
        } catch (e) {
          if (e.response.status === 403) {
            methods.showGlobalSnackbar('error', 'Der eksisterer allerede en patient med denne mail - blot med andet cpr. nummer. Patientens mail skal være unik.');
          }
          this.appointmentSendLoading = false;
          this.appointmentSendButtonColor = 'error';
        }
      },
    },
  };
</script>
