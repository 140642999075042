<template>
  <div :class="['heading-box', ...classArray || '']">
    <v-icon class="heading-icon" size="22">{{icon}}</v-icon>
    <div class="mb-4">
      <h2 class="text-h6 font-weight-bold mb-1 d-flex align-center">
        {{title}}
        <v-progress-circular v-if="loading" indeterminate color="blue-grey" :width="2" :size="20" class="ml-5"/>
      </h2>
      <p v-if="subtitle" class="text-body-2 mb-0">{{subtitle}}</p>
    </div>
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'HeadingBox',

    props: {
      title: {
        type: String,
      },
      icon: {
        type: String,
      },
      subtitle: {
        type: String,
      },
      classArray: {
        type: Array,
      },
      loading: {
        type: Boolean
      }
    },
  };
</script>
