<template>
  <div class="data-section">
    <v-data-table class="data-table" :headers="tableHeaders" :items="drafts" :loading-text="loadingText" loader-height="2" :loading="tableLoading" item-key="id" hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalDrafts" :sort-by="defaultOrderBy" :sort-desc="defaultOrderByDesc" @update:options="serverSort">
      <template v-slot:item.patientFullName="{ item }">
        <span>{{ item.patientFullName ? item.patientFullName.trim() : '--' }}</span>
      </template>
      <template v-slot:item.createDate="{ item }">
        <DateTimeCell :date="item.createDate"/>
      </template>
      <template v-slot:item.modifyDate="{ item }">
        <DateTimeCell :date="item.modifyDate"/>
      </template>
      <template v-slot:item.modifierFullName="{ item }">
        <span>{{ item.modifierFullName ? item.modifierFullName.trim() : '--' }}</span>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn depressed class="mb-2 mb-md-0 px-2" min-width="0" color="primary" @click="editDraft(item.id)">
          Editer
          <v-icon color="white" right size="23" class="ml-2">mdi-chevron-right</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn icon @click="deleteDraft(item)" :loading="item.deleteLoading" :disabled="item.deleteLoading">
          <v-icon size="23" class="grey--text text--darken-2">mdi-delete-forever</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :total-visible="7" class="py-4" @input="changeCurrentPage"/>
  </div>
</template>

<script>
  import api from '../../api/api';
  import DateTimeCell from '../common/DateTimeCell';

  export default {
    name: 'DraftData',

    components: {DateTimeCell},

    data: function () {
      return {
        drafts: [],
        tableHeaders: [
          {text: 'Nr.', value: 'id', width: '9%'},
          {text: 'CPR-nummer', value: 'patientCPR', width: '13%'},
          {text: 'Patient', value: 'patientFullName', width: '15%'},
          {text: 'Oprettet dato', value: 'createDate', width: '15%'},
          {text: 'Sidst editeret', value: 'modifyDate', width: '15%'},
          {text: 'Editeret af', value: 'modifierFullName', width: '15%', sortable: false},
          {value: 'edit', sortable: false},
          {value: 'delete', sortable: false},
        ],
        loadingText: 'Loading... Please wait',
        defaultOrderBy: 'modifyDate',
        defaultOrderByDesc: true,
        totalDrafts: 0,
        totalPages: 0,
        queryParams: {
          OrderBy: null,
          OrderByAscending: null,
          CurrentPage: 1,
          PageSize: 20,
        },
      };
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.draft.tableLoading;
      },
      filterParams: function () {
        return this.$store.state.draft.filterParams;
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getDrafts();
      },
    },

    methods: {
      sortOrderValue: function (val) {
        switch (val.sortBy[0]) {
          case 'id':
            return 1;
          case 'patientCPR':
            return 2;
          case 'patientFullName':
            return 3;
          case 'createDate':
            return 4;
          case 'modifyDate':
            return 5;
          case 'modifierFullName':
            return 6;
          default:
            return 0;
        }
      },
      serverSort: function (val) {
        this.queryParams.OrderBy = this.sortOrderValue(val);
        this.queryParams.OrderByAscending = !val.sortDesc[0];
        this.getDrafts();
      },
      changeCurrentPage: function (value) {
        this.queryParams.CurrentPage = value;
        this.getDrafts();
      },
      editDraft: function (id) {
        this.$router.push({path: '/management/henvisning-tandlaeger', query: {visitId: id}, hash: 'patient-form'});
      },
      setDrafts: function (data) {
        data.entities.forEach((item) => {
          item.deleteLoading = false;
        });
        this.totalPages = Math.ceil(
            data.totalEntities / this.queryParams.PageSize,
        );
        this.drafts = data.entities;
        this.totalDrafts = data.totalEntities;
      },
      deleteDraft: async function (item) {
        try {
          item.deleteLoading = true;
          await api.deleteDraft(item.id);
          let {data} = await api.getDraftsList({...this.queryParams, ...this.filterParams});
          this.setDrafts(data);
        } catch (e) {
          item.deleteLoading = false;
        }
      },
      getDrafts: async function () {
        try {
          this.drafts = [];
          this.$store.commit('draft/updateTableLoading', true);
          let {data} = await api.getDraftsList({...this.queryParams, ...this.filterParams});
          this.setDrafts(data);
          this.$store.commit('draft/updateTableLoading', false);
        } catch (e) {
          this.drafts = [];
          this.$store.commit('draft/updateTableLoading', false);
        }
      },
    },
  };
</script>
