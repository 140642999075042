<template>
  <div>
    <v-container fluid class="mb-4">
      <p class="mb-0">Indtast patient information.</p>
    </v-container>
    <v-container fluid class="spr-search blue-grey lighten-5 mb-12">
      <v-row align="center" justify="end" class="my-0">
        <v-col cols="12" lg="4" xl="6">
          <p class="mb-4 my-lg-0">Indtast CPR nummer og vi henter automatisk patientens adresse.</p>
        </v-col>
        <v-col cols="12" lg="8" xl="6">
          <v-row align="center" class="my-0">
            <v-col class="py-0 mb-4 mb-md-0 ml-lg-auto" cols="4" md="2" xl="3">
              <v-text-field dense hide-details="auto" v-model="searchCprNumber" maxlength="6" @paste.prevent="resetSearchButton(); searchCprPasteHandler($event)" @input="resetSearchButton(); searchCprInputHandler($event)" @keyup.enter="getExternalPatientInfo"/>
            </v-col>
            <v-col cols="auto" class="py-0 px-0">
              <v-icon size="15">icon-minus</v-icon>
            </v-col>
            <v-col class="py-0 mb-4 mb-md-0" cols="4" md="2" xl="3">
              <v-text-field ref="searchCprAdd" dense hide-details="auto" v-model="searchCprNumberAdd" maxlength="4" @input="resetSearchButton" @keyup.enter="getExternalPatientInfo"/>
            </v-col>
            <v-col class="py-0" md="auto">
              <v-btn depressed :color="searchButtonColor" @click="getExternalPatientInfo" :loading="searchLoading" :disabled="searchLoading">
                Hent adresse
                <v-icon right size="25" class="ml-4">mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-form id="patient-form" ref="patientForm" v-model="formValid" class="blue-grey lighten-5" style="position: relative">
      <v-progress-linear indeterminate height="2" :active="formLoading" :absolute="true"/>
      <v-container fluid v-if="isAdmin">
        <span class="mr-5"><strong>Oprettet af:</strong> {{ creatorInfo ? `${creatorInfo.creatorName}, ${creatorInfo.creatorClinic}, ${creatorInfo.creationTime}` : '...' }}</span>
        <span><strong>Sidst editeret af:</strong> {{ modifierInfo ? `${modifierInfo.modifierName}, ${modifierInfo.modifierClinic}, ${modifierInfo.modifyTime}` : '...' }}</span>
      </v-container>
      <v-container fluid class="pt-5 pb-7">
        <v-row class="my-0 mb-5 mb-md-0">
          <v-col cols="12" md="6" class="py-0">
            <v-row class="my-0">
              <v-col cols="12" lg="auto" class="py-0">
                <div class="text-subtitle-1 my-2">CPR-nr *:</div>
              </v-col>
              <v-col cols="5" lg="4" class="py-0">
                <v-text-field v-model="cprNumber" @paste.prevent="cprPasteHandler($event); clearForm(); getDrafts();" @input="clearForm(); getDrafts(); cprInputHandler($event)" @keyup.enter="getInternalPatientInfo" dense maxlength="6" filled :rules="[rules.minLength_6]"/>
              </v-col>
              <v-col cols="auto" class="py-0 px-0 mt-2">
                <v-icon size="15">icon-minus</v-icon>
              </v-col>
              <v-col cols="5" lg="4" class="py-0">
                <v-text-field ref="cprAdd" v-model="cprNumberAdd" @input="clearForm(); getDrafts()" @keyup.enter="getInternalPatientInfo" dense maxlength="4" filled :rules="[rules.minLength_4]"/>
              </v-col>
              <v-col cols="auto" class="py-0 px-0 mt-2" v-if="draftsLoading">
                <v-progress-circular indeterminate color="blue-grey" :width="2" :size="20"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" class="py-0 mt-md-12 mt-lg-1">
            <v-btn depressed :color="visitButtonColor" @click="getInternalPatientInfo" :loading="visitLoading" :disabled="visitLoading">
              Gem data
              <v-icon right size="25" class="ml-4">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="draftVisits.length > 0" class="my-0 mb-5">
          <v-col cols="12" lg="1" class="py-0">
            <div class="px-0 text-subtitle-1 my-1">Drafts:</div>
          </v-col>
          <v-col cols="12" lg="11" class="py-0">
            <template v-for="draftItem in draftVisits">
              <v-btn class="my-1 mr-2" depressed small rounded @click="setDraft(draftItem)" :loading="draftItem.loading" :disabled="draftItem.loading" :color="visitId === draftItem.id ? 'primary' : 'grey lighten-2'">
                <v-icon size="20" left>mdi-square-edit-outline</v-icon>
                {{ draftItem.formattedDate }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
        <v-row v-if="isAdmin" align="center" class="my-0">
          <v-col cols="12" md="3" class="py-0">
            <v-select v-model="visitType" :items="VISIT_TYPES_LIST" item-value="key" item-text="name" label="Henvisning type" outlined/>
          </v-col>
          <v-col v-if="visitType === VISIT_TYPES.EDI_KEY" cols="12" md="3" class="py-0">
            <v-text-field v-model="externalId" label="EDI ID *" filled :rules="[rules.required, rules.digit]"/>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="firstName" label="Fornavn *" filled :rules="[rules.required, rules.excludeCharacters]"/>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="lastName" label="Efternavn *" filled :rules="[rules.required, rules.excludeCharacters]"/>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="profession" label="Erhverv" filled/>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="address" label="Adresse *" filled :rules="[rules.required]"/>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="zip" label="Postnr *" filled :rules="[rules.required]"/>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="city" label="Bynavn *" filled :rules="[rules.required]"/>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="mobilePhone" label="Mobil nr." filled/>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="phoneNumber" label="Tlf" filled/>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0">
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="patientEmail" label="Patient Email" hint="Hvis patienten ikke har mail, så lad feltet stå tomt" persistent-hint @keyup="emailChange($event)" @blur="validateEmail" @focus="clearValidateEmail" filled :rules="patientEmailRules"/>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <v-text-field v-model="confirmEmail" label="Bekræft email" @keyup="confirmEmailChange($event)" filled :rules="[emailRepeat]"/>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0 mt-md-10">
          <v-col cols="12" lg="2" class="py-0">
            <div class="text-subtitle-1 my-2">Henvisningstype:</div>
          </v-col>
          <v-col cols="auto" class="py-0">
            <v-radio-group hide-details row v-model="referenceType" class="my-0 pt-0">
              <v-radio class="my-2" label="Almindelig" :value="0" color="primary"/>
              <v-radio class="my-2" label="Haste henvisning" :value="1" color="primary"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0">
          <v-col cols="12" lg="2" class="py-0">
            <div class="text-subtitle-1 my-2">Indkaldelse:</div>
          </v-col>
          <v-col cols="auto" class="py-0">
            <v-radio-group hide-details row v-model="callType" class="my-0 pt-0">
              <v-radio class="my-2" label="Ringer selv" :value="0" color="primary"/>
              <v-radio class="my-2" label="Har en tid" :value="1" color="primary"/>
              <v-radio class="my-2" label="Bedes indkaldt" :value="2" color="primary"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0 mb-6">
          <v-col cols="12" lg="2" class="py-0">
            <div class="text-subtitle-1 my-2">Betaling:</div>
          </v-col>
          <v-col cols="auto" class="py-0">
            <v-radio-group hide-details row v-model="paymentType" class="my-0 pt-0">
              <v-radio class="my-2" label="Egenbet" :value="0" color="primary"/>
              <v-radio class="my-2" label="Forsikringssag" :value="1" color="primary"/>
              <v-radio class="my-2" label="Regionstandpleje" :value="2" color="primary"/>
              <v-radio class="my-2" label="BUT" :value="3" color="primary"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <div v-if="paymentType === 1 || paymentType === 2 || paymentType === 3" class="mb-12">
          <template v-if="paymentType === 1">
            <v-row class="my-0">
              <v-col cols="12" md="6" lg="5" offset-lg="2" class="py-0">
                <v-text-field v-model="insuranceCompany" label="Forsikringsselskab" filled/>
              </v-col>
              <v-col cols="12" md="6" lg="5" class="py-0">
                <v-text-field v-model="policeClaim" label="Police-/skadenummer" filled/>
              </v-col>
            </v-row>
          </template>
          <template v-if="paymentType === 2 || paymentType === 3">
            <v-row class="my-0">
              <v-col cols="12" md="6" lg="5" offset-lg="2" class="py-0">
                <v-text-field v-model="regionNumber" label="EAN nr." filled/>
              </v-col>
              <v-col cols="12" md="6" lg="5" class="py-0">
                <v-text-field v-model="regionAddress" label="Adresse" filled/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" lg="5" offset-lg="2" class="py-0">
                <v-text-field v-model="regionInstitution" label="Institution" filled/>
              </v-col>
              <v-col cols="12" md="6" lg="5" class="py-0">
                <v-row class="my-0">
                  <v-col cols="12" md="auto" class="py-0 mt-md-1">
                    <div class="text-subtitle-1 my-2">Postnr./By</div>
                  </v-col>
                  <v-col cols="4" md="3" class="py-0">
                    <v-text-field v-model="regionZip" filled/>
                  </v-col>
                  <v-col cols="auto" class="py-0 px-0 mt-4">
                    <v-icon size="15">icon-minus</v-icon>
                  </v-col>
                  <v-col class="py-0">
                    <v-text-field v-model="regionCity" filled/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>
          <v-row align="center" class="my-0 mt-3">
            <v-col cols="12" lg="10" offset-lg="2" class="py-0">
              <div class="text-subtitle-1 my-2">Upload behandlingsgodkendelse / Bevilling</div>
            </v-col>
            <v-col cols="auto" offset-lg="2" class="py-0">
              <v-radio-group hide-details row v-model="appropriationType" class="my-0 pt-0">
                <v-radio class="my-2" label="Uploaded nu" :value="0" color="primary"/>
                <v-radio class="my-2" label="Sendt via post" :value="1" color="primary"/>
                <v-radio class="my-2" label="Sendt via email" :value="2" color="primary"/>
                <v-radio v-if="paymentType === 2 || paymentType === 3" class="my-2" label="Ingen ekstra materiale" :value="3" color="primary"/>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="appropriationType === 0" align="center" class="my-0 mt-3">
            <v-col cols="12" md="5" offset-lg="2">
              <v-chip v-if="appropriationFileName" class="my-5" :color="appropriationChipColor" close @click:close="clearAppropriationFile">
                <span style="max-width: 200px" class="text-truncate d-inline-block mr-2">{{ appropriationFileName }}</span>
              </v-chip>
              <v-file-input v-else v-model="appropriationFile" show-size label="File" accept=".pdf,.doc,.docx" hint="(.pdf,.doc,.docx)" persistent-hint :clearable="false" @change="changeAppropriationFile($event)" :rules="[rules.file]"/>
            </v-col>
          </v-row>
        </div>
        <v-row class="my-0">
          <v-col>
            <v-textarea v-model="diagnosis" label="Diagnose" outlined rows="2" :auto-grow="true" row-height="30"/>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="12" md="6">
            <v-textarea v-model="previousTreatment" label="Tidligere behandling" outlined rows="2" :auto-grow="true" row-height="30"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea v-model="desiredTreatment" label="Ønsket behandling *" outlined rows="2" :auto-grow="true" row-height="30" :rules="[rules.required]"/>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col>
            <v-textarea v-model="comment" label="Evt. kommentar" outlined rows="2" :auto-grow="true" row-height="30"/>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0 mb-2">
          <v-col cols="12" lg="3" class="py-0">
            <div class="text-subtitle-1 my-2">Medlem af DANMARK?</div>
          </v-col>
          <v-col cols="auto" class="py-0">
            <v-radio-group hide-details row v-model="memberOfDenmark" class="my-0 pt-0">
              <v-radio class="my-2" label="Ja" :value="true" color="primary"/>
              <v-radio class="my-2" label="Nej" :value="false" color="primary"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row align="center" class="my-0 mb-4">
          <v-col cols="12" lg="3" class="py-0">
            <div class="text-subtitle-1 my-2">Sygesikring DANMARK Gruppe:</div>
          </v-col>
          <v-col cols="auto" class="py-0">
            <v-radio-group hide-details row v-model="healthInsuranceType" class="my-0 pt-0">
              <v-radio class="my-2" label="Basis" :value="0" color="primary" :disabled="!memberOfDenmark"/>
              <v-radio class="my-2" label="Gruppe5" :value="3" color="primary" :disabled="!memberOfDenmark"/>
              <v-radio class="my-2" label="Gruppe1" :value="1" color="primary" :disabled="!memberOfDenmark"/>
              <v-radio class="my-2" label="Gruppe2" :value="2" color="primary" :disabled="!memberOfDenmark"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-2">
          <v-col cols="12" lg="3" class="py-0">
            <div class="text-subtitle-1">Upload journalkopi/ dokument (pdf/doc-fil):</div>
            <span class="text-subtitle-1 red--text">OBS: Ikke røntgen</span>
          </v-col>
          <v-col cols="12" lg="9" class="py-0">
            <div class="mb-2">
              <v-chip v-for="(item, index) in journalFiles" :key="item.id" class="mr-2 my-1" close @click:close="journalRemoveFile(index)">
                <span style="max-width: 200px" class="text-truncate d-inline-block">{{ item.name }}</span>
              </v-chip>
            </div>
            <v-btn depressed class="mt-3" color="primary" @click="journalFileTrigger">
              Gennemse...
            </v-btn>
            <input ref="journalAttach" class="d-none" type="file" accept=".pdf, .doc, .docx, .rtf, .tiff, .tif, .xml, .xls, .xlsx, .csv, .zip" multiple @input="journalAddFile($event)"/>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <NavigationBlock @validateInit="validate" :submitLoading="submitLoading"/>
  </div>
</template>

<script>
  import NavigationBlock from './NavigationBlock';
  import validationRules from '../../utils/validationRules';
  import api from '../../api/api';
  import methods from '../../utils/methods';
  import {VISIT_TYPES, VISIT_TYPES_LIST} from '@/constants/visitTypes';

  export default {
    name: 'PatientForm',

    components: {NavigationBlock},

    data: function () {
      return {
        VISIT_TYPES,
        VISIT_TYPES_LIST,
        formLoading: false,
        searchCprNumber: '',
        searchCprNumberAdd: '',
        searchLoading: false,
        searchButtonColor: 'primary',
        visitLoading: false,
        visitButtonColor: 'primary',
        submitLoading: false,
        rules: {...validationRules},
        formValid: false,
        draftsLoading: false,
        cprNumber: '',
        cprNumberAdd: '',
        draftVisits: [],
        visitType: VISIT_TYPES.SIMPLE_KEY,
        externalId: '',
        firstName: '',
        lastName: '',
        profession: '',
        address: '',
        zip: '',
        city: '',
        mobilePhone: '',
        phoneNumber: '',
        patientEmail: '',
        patientEmailRules: [],
        confirmEmail: '',
        referenceType: 0,
        callType: 0,
        paymentType: 0,
        insuranceCompany: '',
        policeClaim: '',
        regionNumber: '',
        regionAddress: '',
        regionInstitution: '',
        regionZip: '',
        regionCity: '',
        appropriationType: 0,
        appropriationFile: null,
        appropriationFileName: '',
        appropriationChipColor: '',
        diagnosis: '',
        previousTreatment: '',
        desiredTreatment: '',
        comment: '',
        memberOfDenmark: true,
        healthInsuranceType: 0,
        journalFiles: []
      };
    },

    mounted: async function () {
      this.$store.commit('visit/changeAvailableStep', 1);
      this.$store.commit('visit/changeActiveStep', 1);
      this.$store.commit('visit/changeVisitId', null);
      this.$store.commit('visit/changeEditMode', false);
      this.$store.commit('visit/changeCreatorInfo', null);
      this.$store.commit('visit/changeModifierInfo', null);
      if (methods.getUrlParameter('visitId')) {
        try {
          this.formLoading = true;
          let {data} = await api.getVisitById(methods.getUrlParameter('visitId'));
          this.formLoading = false;
          this.$store.commit('visit/changeVisitId', parseInt(methods.getUrlParameter('visitId')));
          this.$store.commit('visit/changeEditMode', true);
          this.$router.replace({path: `${this.$router.currentRoute.path}`, query: {}, hash: `${this.$router.currentRoute.hash}`});
          let [cpr, cprAdd] = data.cpr.split('-');
          this.cprNumber = cpr;
          this.cprNumberAdd = cprAdd;
          this.setEditableValues(data);
          this.getDrafts();
        } catch (e) {
          this.formLoading = false;
          methods.showGlobalSnackbar('error', 'Server error. Noget gik galt. Prøv igen senere.');
        }
      }
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      availableStep: function () {
        return this.$store.state.visit.availableStep;
      },
      emailRepeat: function () {
        return this.patientEmail === this.confirmEmail || 'Email does not match';
      },
      visitId: function () {
        return this.$store.state.visit.visitId;
      },
      creatorInfo: function () {
        return this.$store.state.visit.creatorInfo;
      },
      modifierInfo: function () {
        return this.$store.state.visit.modifierInfo;
      },
    },

    watch: {
      paymentType: function (val) {
        switch (val) {
          case 0:
            this.insuranceCompany = '';
            this.policeClaim = '';
            this.appropriationType = 0;
            this.appropriationFile = null;
            this.appropriationFileName = '';
            this.regionNumber = '';
            this.regionAddress = '';
            this.regionInstitution = '';
            this.regionZip = '';
            this.regionCity = '';
            break;
          case 1:
            this.regionNumber = '';
            this.regionAddress = '';
            this.regionInstitution = '';
            this.regionZip = '';
            this.regionCity = '';
            if (this.appropriationType === 3) {
              this.appropriationType = 0;
            }
            break;
          case 2:
            this.insuranceCompany = '';
            this.policeClaim = '';
            break;
          case 3:
            this.insuranceCompany = '';
            this.policeClaim = '';
            break;
        }
      },
      appropriationType: function (val) {
        if (val !== 0) {
          this.appropriationFile = null;
          this.appropriationFileName = '';
        }
      },
      memberOfDenmark: function (val) {
        if (!val) {
          this.healthInsuranceType = 0;
        }
      },
    },

    methods: {
      searchCprInputHandler: function (value) {
        if (value?.length === 6) {
          this.$refs.searchCprAdd.focus();
        }
      },
      searchCprPasteHandler: function(event) {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (pastedData?.length > 6) {
          if (pastedData.includes('-')) {
            this.searchCprNumber = pastedData.split('-')[0].slice(0, 6);
            this.searchCprNumberAdd = pastedData.split('-')[1].slice(0, 4);
          } else {
            this.searchCprNumber = pastedData.slice(0, 6);
            this.searchCprNumberAdd = pastedData.slice(6, 10);
          }
          this.$refs.searchCprAdd.focus();
        } else if (pastedData?.length === 6) {
          this.searchCprNumber = pastedData;
          this.$refs.searchCprAdd.focus();
        } else {
          this.searchCprNumber = pastedData;
        }
      },
      cprInputHandler: function (value) {
        if (value?.length === 6) {
          this.$refs.cprAdd.focus();
        }
      },
      cprPasteHandler: function(event) {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (pastedData?.length > 6) {
          if (pastedData.includes('-')) {
            this.cprNumber = pastedData.split('-')[0].slice(0, 6);
            this.cprNumberAdd = pastedData.split('-')[1].slice(0, 4);
          } else {
            this.cprNumber = pastedData.slice(0, 6);
            this.cprNumberAdd = pastedData.slice(6, 10);
          }
          this.$refs.cprAdd.focus();
        } else if (pastedData?.length === 6) {
          this.cprNumber = pastedData;
          this.$refs.cprAdd.focus();
        } else {
          this.cprNumber = pastedData;
        }
      },
      changeAppropriationFile: function (data) {
        this.appropriationFileName = data.name;
        let fileType = data.name.split('.').pop().toLowerCase();
        switch (fileType) {
          case 'pdf':
          case 'doc':
          case 'docx':
            this.appropriationChipColor = '';
            break;
          default:
            this.appropriationChipColor = 'error';
            methods.showGlobalSnackbar('error', 'Denne filtype er ikke godkendt i dette felt. Bemærk, her kan du uploade filer af dette format: pdf, doc og docx. Feltet skal bruges til behandlingsgodkendelse og bevilling. Røntgen uploades på side 3 i formularen.');
        }
      },
      clearAppropriationFile: function () {
        this.appropriationFileName = '';
        this.appropriationFile = null;
      },
      journalFileTrigger: function () {
        this.$refs.journalAttach.click();
      },
      journalAddFile: function (data) {
        if (data.target.files.length > 0) {
          Object.keys(data.target.files).forEach(key => {
            let item = data.target.files[key]
            let fileType = item.name.split('.').pop().toLowerCase();
            let newId = crypto.randomUUID();
            switch (fileType) {
              case 'pdf':
              case 'doc':
              case 'docx':
              case 'rtf':
              case 'tiff':
              case 'tif':
              case 'xml':
              case 'xls':
              case 'xlsx':
              case 'csv':
              case 'zip':
                this.journalFiles.push({
                  id: newId,
                  file: item,
                  name: item.name
                });
                break;
              default:
                methods.showGlobalSnackbar('error', 'Her kan du kun uploade .doc og pdf-filer. Hvis du skal uploade røngten så husk at det kan du gøre på side 3. Dette felt er KUN til journalkopi. Tak.');
            }
          });
          data.target.value = '';
        }
      },
      journalRemoveFile: function(index) {
        this.$delete(this.journalFiles, index);
      },
      resetSearchButton: function () {
        this.searchButtonColor !== 'primary' && (this.searchButtonColor = 'primary');
      },
      validate: function () {
        this.$refs.patientForm.validate();
        if (this.formValid) {
          this.sendVisit();
        } else {
          methods.showGlobalSnackbar('error', 'Der er felter som mangler at blive udfyldt, tjek venligst og udfyld disse.');
        }
      },
      setExternalId: function (id) {
        this.visitType = VISIT_TYPES.EDI_KEY;
        this.externalId = id;
      },
      clearForm: function () {
        this.$store.commit('visit/changeVisitId', null);
        this.$store.commit('visit/changeEditMode', false);
        this.visitType = VISIT_TYPES.SIMPLE_KEY;
        this.externalId = '';
        this.firstName = '';
        this.lastName = '';
        this.profession = '';
        this.address = '';
        this.zip = '';
        this.city = '';
        this.mobilePhone = '';
        this.phoneNumber = '';
        this.patientEmail = '';
        this.confirmEmail = '';
        this.referenceType = 0;
        this.callType = 0;
        this.paymentType = 0;
        this.insuranceCompany = '';
        this.policeClaim = '';
        this.regionNumber = '';
        this.regionAddress = '';
        this.regionInstitution = '';
        this.regionZip = '';
        this.regionCity = '';
        this.appropriationType = 0;
        this.appropriationFile = null;
        this.appropriationFileName = '';
        this.diagnosis = '';
        this.previousTreatment = '';
        this.desiredTreatment = '';
        this.comment = '';
        this.memberOfDenmark = true;
        this.healthInsuranceType = 0;
        this.journalFiles = [];
      },
      setEditableValues: function (data) {
        data['externalId'] && this.setExternalId(data['externalId']);
        this.firstName = methods.checkNull(data.firstName);
        this.lastName = methods.checkNull(data.lastName);
        this.address = methods.checkNull(data.address);
        this.zip = methods.checkNull(data.zip);
        this.city = methods.checkNull(data.city);
        this.desiredTreatment = methods.checkNull(data.desiredTreatment);

        this.profession = methods.checkNull(data.profession);
        this.mobilePhone = methods.checkNull(data.mobilePhone);
        this.phoneNumber = methods.checkNull(data.phone);
        this.patientEmail = methods.checkNull(data.email);
        this.confirmEmail = methods.checkNull(data.email);
        this.referenceType = data.referenceType;
        this.callType = data.callType;
        this.paymentType = data.paymentType;
        this.insuranceCompany = methods.checkNull(data.insuranceCompany);
        this.policeClaim = methods.checkNull(data.policeClaim);
        methods.checkNull(data.appropriationType) ? this.appropriationType = data.appropriationType : this.appropriationType = 0;
        this.appropriationFileName = methods.checkNull(data.appropriationFileName);
        this.regionNumber = methods.checkNull(data.regionNumber);
        this.regionAddress = methods.checkNull(data.regionAddress);
        this.regionInstitution = methods.checkNull(data.regionInstitution);
        this.regionZip = methods.checkNull(data.regionZip);
        this.regionCity = methods.checkNull(data.regionCity);
        this.diagnosis = methods.checkNull(data.diagnose);
        this.previousTreatment = methods.checkNull(data.previousTreatment);
        this.comment = methods.checkNull(data.comment);
        this.memberOfDenmark = data.isHealthInsurance;
        this.healthInsuranceType = data.healthInsuranceType;
        data.journalFileNames.forEach(journalName => {
          let newId = crypto.randomUUID();
          this.journalFiles.push({
            id: newId,
            name: journalName
          });
        });
      },
      getExternalPatientInfo: async function () {
        this.clearForm();
        this.$store.commit('visit/changeVisitId', null);
        this.$store.commit('visit/changeEditMode', false);
        this.draftVisits = [];
        try {
          this.searchLoading = true;
          let {data} = await api.getExternalPatientInfo(`${this.searchCprNumber}${this.searchCprNumberAdd}`);
          this.searchButtonColor = 'primary';
          this.searchLoading = false;
          this.cprNumber = this.searchCprNumber;
          this.cprNumberAdd = this.searchCprNumberAdd;
          data['externalId'] && this.setExternalId(data['externalId']);
          this.firstName = data.firstName;
          this.lastName = data.lastName;
          this.address = data.address;
          this.zip = data.zipCode;
          this.city = data.city;
          this.getDrafts();
        } catch (e) {
          this.searchLoading = false;
          this.searchButtonColor = 'error';
        }
      },
      getDrafts: async function () {
        this.visitButtonColor !== 'primary' && (this.visitButtonColor = 'primary');
        if (this.cprNumber.length === 6 && this.cprNumberAdd.length === 4) {
          try {
            this.draftsLoading = true;
            let drafts = await api.getDrafts(`${this.cprNumber}-${this.cprNumberAdd}`);
            this.draftsLoading = false;
            drafts.data.forEach((visitItem) => {
              visitItem.formattedDate = `${methods.getDateFromUtc(visitItem.date)} ${methods.getTimeFromUtc(visitItem.date)}`;
              visitItem.loading = false;
            });
            this.draftVisits = drafts.data;
          } catch (e) {
            this.draftVisits = [];
            this.draftsLoading = false;
          }
        } else {
          this.draftVisits = [];
        }
      },
      setDraft: async function (visitItem) {
        this.clearForm();
        try {
          visitItem.loading = true;
          let {data} = await api.getVisitById(visitItem.id);
          if (this.visitId !== visitItem.id) {
            this.$store.commit('visit/changeVisitId', visitItem.id);
            this.$store.commit('visit/changeEditMode', true);
          }
          visitItem.loading = false;
          this.setEditableValues(data);
        } catch (e) {
          visitItem.loading = false;
        }
      },
      getInternalPatientInfo: async function () {
        this.clearForm();
        this.$store.commit('visit/changeVisitId', null);
        this.$store.commit('visit/changeEditMode', false);
        try {
          this.visitLoading = true;
          let {data} = await api.getInternalPatientInfo(`${this.cprNumber}-${this.cprNumberAdd}`);
          this.visitButtonColor = 'primary';
          this.visitLoading = false;
          data['externalId'] && this.setExternalId(data['externalId']);
          this.firstName = methods.checkNull(data.firstName);
          this.lastName = methods.checkNull(data.lastName);
          this.profession = methods.checkNull(data.profession);
          this.address = methods.checkNull(data.address);
          this.zip = methods.checkNull(data.zip);
          this.city = methods.checkNull(data.city);
          this.mobilePhone = methods.checkNull(data.mobilePhone);
          this.phoneNumber = methods.checkNull(data.phone);
          this.patientEmail = methods.checkNull(data.email);
          this.confirmEmail = methods.checkNull(data.email);
        } catch (e) {
          this.visitLoading = false;
          this.visitButtonColor = 'error';
        }
      },
      clearValidateEmail: function() {
        this.patientEmailRules = [];
      },
      validateEmail: function() {
        this.patientEmailRules = [this.rules.email];
      },
      emailChange: function(data) {
        this.patientEmail = data.target.value.trim();
      },
      confirmEmailChange: function(data) {
        this.confirmEmail = data.target.value.trim();
      },
      sendVisit: async function () {
        let newData = new FormData();
        // require
        this.visitType === VISIT_TYPES.EDI_KEY && newData.append('ExternalId', this.externalId);
        newData.append('Cpr', `${this.cprNumber}-${this.cprNumberAdd}`);
        newData.append('FirstName', this.firstName);
        newData.append('LastName', this.lastName);
        newData.append('Address', this.address);
        newData.append('Zip', this.zip);
        newData.append('City', this.city);
        newData.append('DesiredTreatment', this.desiredTreatment);
        // optional
        methods.checkLength(this.profession) && newData.append('Profession', this.profession);
        methods.checkLength(this.phoneNumber) && newData.append('Phone', this.phoneNumber);
        methods.checkLength(this.mobilePhone) && newData.append('MobilePhone', this.mobilePhone);
        methods.checkLength(this.patientEmail) && newData.append('Email', this.patientEmail);
        newData.append('ReferenceType', this.referenceType);
        newData.append('CallType', this.callType);
        newData.append('PaymentType', this.paymentType);

        methods.checkLength(this.insuranceCompany) && newData.append('InsuranceCompany', this.insuranceCompany);
        methods.checkLength(this.policeClaim) && newData.append('PoliceClaim', this.policeClaim);

        methods.checkLength(this.regionNumber) && newData.append('RegionNumber', this.regionNumber);
        methods.checkLength(this.regionAddress) && newData.append('RegionAddress', this.regionAddress);
        methods.checkLength(this.regionInstitution) && newData.append('RegionInstitution', this.regionInstitution);
        methods.checkLength(this.regionZip) && newData.append('RegionZip', this.regionZip);
        methods.checkLength(this.regionCity) && newData.append('RegionCity', this.regionCity);

        newData.append('AppropriationType', this.appropriationType);
        methods.checkLength(this.appropriationFileName) && newData.append('AppropriationFileName', this.appropriationFileName);
        this.appropriationFile && newData.append('AppropriationData', this.appropriationFile);

        methods.checkLength(this.diagnosis) && newData.append('Diagnose', this.diagnosis);
        methods.checkLength(this.previousTreatment) && newData.append('PreviousTreatment', this.previousTreatment);
        methods.checkLength(this.comment) && newData.append('Comment', this.comment);

        newData.append('IsHealthInsurance', this.memberOfDenmark);
        this.memberOfDenmark && newData.append('HealthInsuranceType', this.healthInsuranceType);
        if (this.journalFiles.length > 0) {
          this.journalFiles.forEach((item) => {
            if (item.file) {
              newData.append(`Journals`, item.file)
            } else {
              newData.append(`AttachedJournalsToKeep`, item.name)
            }
          })
        }

        try {
          this.submitLoading = true;
          if (this.visitId) {
            newData.append('VisitId', this.visitId);
            await api.updatePatientInfo(newData);
          } else {
            let {data} = await api.sendPatientInfo(newData);
            this.$store.commit('visit/changeVisitId', data.visitId);
          }
          this.journalFiles.forEach((record) => {
            record.file = null
          });
          this.submitLoading = false;
          this.availableStep < 2 && this.$store.commit('visit/changeAvailableStep', this.availableStep + 1);
          this.$store.commit('visit/changeActiveStep', 2);
          this.$parent.$refs.anchor.$el.scrollIntoView();
        } catch (e) {
          this.submitLoading = false;
          if (e.response.status === 403) {
            methods.showGlobalSnackbar('error', 'Der eksisterer allerede en patient med denne mail - blot med andet cpr. nummer. Patientens mail skal være unik.');
          } else {
            methods.showGlobalSnackbar('error', 'Server error. Noget gik galt. Prøv igen senere.');
          }
        }
      },
    },
  };
</script>
