export default {
  required: value => value && !!value.trim() || 'Required',
  requiredId: value => (!!value || value === 0) || 'Required',
  file: value => !!value || 'File is required',
  minLength_4: value => (value && value.length >= 4) || 'Min 4 karakterer',
  minLength_6: value => (value && value.length >= 6) || 'Min 6 karakterer',
  minLength_7: value => (value && value.length >= 7) || 'Min 7 karakterer',
  minLength_8: value => (value && value.length >= 8) || 'Min 8 karakterer',
  upperCase: value => {
    const pattern = /[A-Z ÆØÅ]+/;
    return !value || pattern.test(value) || 'Du skal bruge et bogstav skrevet med stort (eks: A)';
  },
  lowerCase: value => {
    const pattern = /[a-z æøå]+/;
    return !value || pattern.test(value) || 'Du skal bruge et bogstav skrevet med småt (eks: a)';
  },
  digit: value => {
    const pattern = /[0-9]+/;
    return !value || pattern.test(value) || 'Der skal være min et tal';
  },
  excludeCharacters: value => {
    const pattern = /^[A-Za-z0-9 æøåÆØÅ]+$/;
    return !value || pattern.test(value) || 'Ingen specialtegn tak';
  },
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !value || pattern.test(value) || 'Invalid e-mail';
  },
};
