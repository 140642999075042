<template>
  <div class="app-wrapper">
    <div class="bg-dark-blue white--text">
      <v-container fluid class="management-tabs__container py-1">
        <v-row justify="space-between" align="center" class="my-0 py-0">
          <v-col cols="auto" class="py-0 pl-8">
            <a href="/" style="display: block; width: 100px">
              <img style="width: 100%; height: auto; display: block" src="../../images/logo-simple.png" alt="KIRURGIKLINIK"/>
            </a>
          </v-col>
          <v-col cols="auto" class="py-0">
            <b>{{`${userData.firstName} ${userData.lastName}`}}</b>
            <v-tooltip bottom :allow-overflow="true" color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-3" color="white" :loading="logoutLoading" @click="logout" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>
              </template>
              <span>Log Out</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <template v-if="access">
      <div id="tabs" class="management-tabs">
        <v-container class="py-0 management-tabs__container">
          <v-tabs v-if="isAdmin" background-color="primary" dark show-arrows hide-slider>
            <v-tab class="px-4" style="min-width: 0" to="/management/overview">Oversigt</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/henvisning-tandlaeger">Henvis</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/call-up-letters">Indkald</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/sms">SMS</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/statistics">Statistik</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/draft-visits">Drafts</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/ending-letter">Udskrivningsbrev</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/change-password">Din profil</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/users">Henvisere</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/clinics">Klinik</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/logs">Logs</v-tab>
            <v-tab class="px-4" style="min-width: 0" to="/management/settings"><v-icon>mdi-cog</v-icon></v-tab>
          </v-tabs>
          <v-tabs v-if="role && !isAdmin" background-color="primary" dark show-arrows hide-slider>
            <v-tab class="px-5" style="min-width: 0" to="/management/overview">Oversigt over henvisninger</v-tab>
            <v-tab class="px-5" style="min-width: 0" to="/management/henvisning-tandlaeger">Henvis</v-tab>
            <v-tab class="px-5" style="min-width: 0" to="/management/draft-visits">Drafts</v-tab>
            <v-tab class="px-5" style="min-width: 0" to="/management/ending-letter">Udskrivningsbrev</v-tab>
            <v-tab class="px-5" style="min-width: 0" to="/management/change-password">Din profil</v-tab>
            <template v-if="isSecretary">
              <v-tab class="px-5" style="min-width: 0" to="/management/users">Henvisere</v-tab>
              <v-tab class="px-5" style="min-width: 0" to="/management/clinics">Klinik</v-tab>
            </template>
          </v-tabs>
        </v-container>
      </div>
      <router-view/>
    </template>
    <v-container v-else>
      <v-alert prominent type="error">
        <v-row align="center" class="my-0 py-5">
          <v-col class="grow">Access Denied {{ accessDeniedBy }}</v-col>
        </v-row>
      </v-alert>
    </v-container>
    <v-dialog :value="showTermsDialog" width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="termsDialog = false">
      <v-card>
        <v-card-text class="pt-10" v-html="conditionsText"/>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text :max-width="200" style="overflow: hidden" @click="termsDialog = false; acceptConditions()">{{ conditionsButtonText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="showInfoPopupDialog" width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closeInfoPopup">
      <v-card>
        <v-card-text class="pt-10" v-html="infoPopupText"/>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :max-width="200" style="overflow: hidden" @click="closeInfoPopup">Luk X</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :value="showOldBrowserDialog" width="500" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closeOldBrowserDialog">
      <v-card>
        <v-card-text class="pt-10">
          <p>Du har en forældet browser.</p>
          <p>Det kan betyde, at der er vigtige funktioner, som du ikke har adgang til.</p>
          <p>Vi anbefaler, at du opdaterer til en mere sikker og nyere browser. Klik på et af nedenstående links for at installere en ny browser, det er gratis og tager kun 2 minutter.</p>
          <div><a target="_blank" href="https://www.mozilla.org">Firefox - for download klik her ></a></div>
          <div><a target="_blank" href="https://www.google.com/chrome">Chrome - for download klik her ></a></div>
          <div class="mb-4"><a target="_blank" href="https://www.apple.com/safari">Apple Safari - for download klik her ></a></div>
          <p>Du kan også ringe til vores support på 44 12 70 96 eller skrive til supporten her <a href="mailto:support@kirurgiklinik.dk">support@kirurgiklinik.dk</a> så vil de hjælpe dig.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" depressed @click="closeOldBrowserDialog">Luk X</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="showOverlay" :opacity="1" color="#233241">
      <v-progress-linear indeterminate style="width: 100vw" color="white" :height="1" :rounded="true"/>
    </v-overlay>
  </div>
</template>

<script>
  import api from '../../api/api';
  import methods from '../../utils/methods';

  export default {
    name: 'Tabs',

    data: function () {
      return {
        ipAccess: null,
        termsDialog: false,
        oldBrowserDialog: false,
        conditionsText: '',
        conditionsButtonText: '',
        logoutLoading: false,
        infoPoup: false,
        infoPopupText: ''
      };
    },

    mounted: async function () {
      let ua = window.navigator.userAgent;
      let msie = ua.indexOf('MSIE '); // IE 10 or older
      let trident = ua.indexOf('Trident/'); //IE 11
      if (msie > 0 || trident > 0 && !methods.getCookie('newSiteOldBrowserPopup')) {
        this.oldBrowserDialog = true;
      }

      await this.$store.dispatch('getAccountInfo');
      setInterval(() => {
        this.$store.dispatch('getAccountInfo');
      }, 10000);
      if (methods.isAdminHost()) {
        try {
          await api.getAccountAccess();
          this.ipAccess = true;
        } catch (e) {
          if (e.response.status === 403) {
            this.ipAccess = false;
          }
        }
      }
    },

    computed: {
      role: function () {
        return this.$store.state.role;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      isDentist: function () {
        return this.$store.getters.isDentist;
      },
      userData: function () {
        return this.$store.state.userData;
      },
      accessDeniedBy: function () {
        if (this.roleAccess === false) return 'by Role';
        if (this.ipAccess === false) return 'by Ip';
      },
      access: function () {
        if (methods.isAdminHost()) {
          return this.roleAccess && this.ipAccess;
        } else {
          return this.roleAccess;
        }
      },
      roleAccess: function () {
        if (methods.isLocalhost()) {
          return !!this.role;
        }
        if (methods.isAdminHost()) {
          return this.isAdmin;
        } else {
          if (this.isAdmin) {
            return false;
          }
          if (this.role && !this.isAdmin) {
            return true;
          }
        }
      },
      showOverlay: function () {
        if (methods.isAdminHost()) {
          if (this.role === null && this.ipAccess === null) {
            return true;
          }
          if (this.role !== null && this.ipAccess !== null) {
            return false;
          }
        } else {
          return this.role === null;
        }
      },
      showTermsDialog: function() {
        return !this.showOverlay && this.termsDialog
      },
      showInfoPopupDialog: function() {
        return !this.showOverlay && !this.termsDialog && sessionStorage.getItem('infoPopup') !== 'false' && this.infoPoup
      },
      showOldBrowserDialog: function() {
        return !this.showOverlay && !this.termsDialog && !this.infoPoup && this.oldBrowserDialog
      }
    },

    watch: {
      role: async function (val) {
        try {
          await this.$store.dispatch('getTreatmentTypes');
          if (this.isAdmin) {
            await this.$store.dispatch('getSurgeons');
          }
          if (this.isAdmin || this.isSecretary) {
            await this.$store.dispatch('getDoctors');
          }
          if (!methods.isAdminHost()) {
            if (this.isDentist || this.isSecretary) {
              let conditions = await api.getConditions();
              if (!conditions.data.isAccepted) {
                this.conditionsText = conditions.data.conditionsText;
                this.conditionsButtonText = conditions.data.conditionsButtonText;
                this.termsDialog = true;
              }
              if (this.isSecretary) {
                let infoPopup = await api.getInfoPopup();
                if (infoPopup.data && infoPopup.data.length > 0) {
                  this.infoPopupText = infoPopup.data;
                  this.infoPoup = true;
                }
              }
            }
          }
        } catch (e) {}
        if (val) {
          this.isUrlHasLetter();
        }
      },
    },

    methods: {
      acceptConditions: function () {
        try {
          api.acceptConditions();
        } catch (e) {}
      },
      closeOldBrowserDialog: function() {
        this.oldBrowserDialog = false;
        methods.setCookie('newSiteOldBrowserPopup', '0', 1);
      },
      closeInfoPopup : function() {
        this.infoPoup = false;
        sessionStorage.setItem('infoPopup', 'false');
      },
      isUrlHasLetter: function () {
        let visitId = methods.getUrlParameter('vid');
        let doctorId = methods.getUrlParameter('mid');
        let terminatePdf = methods.getUrlParameter('trmPdf');
        let terminateZip = methods.getUrlParameter('trmZip');
        let letterNumber = methods.getUrlParameter('num');
        if (!letterNumber) {
          letterNumber = 1;
        }
        if (visitId && doctorId) {
          if (terminatePdf) {
            methods.downloadURL(api.diagnosisFileUrl(visitId, letterNumber));
          } else if (terminateZip) {
            methods.downloadURL(api.rentgenFileUrl(visitId, letterNumber));
          }
        }
      },
      logout: async function() {
        try {
          this.logoutLoading = true;
          await api.signOut();
          methods.backToLogin();
        } catch (e) {
          this.logoutLoading = false;
        }
      }
    },
  };
</script>
