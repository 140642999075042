<template>
  <v-form ref="updateForm" v-model="formValid" class="profile-section py-5 px-3">
    <h2 class="mb-2">SKIFT BRUGERDATA</h2>
    <p>Her kan du ændre dine brugerdata.</p>
    <v-row class="my-0">
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="firstName" label="Fornavn *" :rules="[rules.required]" @input="resetButtons" filled/>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="lastName" label="Efternavn *" :rules="[rules.required]" @input="resetButtons" filled/>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="userName" label="Brugernavn *" :rules="[rules.required]" @input="resetButtons" filled/>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="email" label="E-mail *" :rules="[rules.required, rules.email]" @input="resetButtons" filled/>
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col cols="12" md="6" class="py-0">
        <v-text-field v-model="phone" label="Phone" @input="resetButtons" filled/>
      </v-col>
    </v-row>
    <v-row justify="end" class="my-0 py-0">
      <v-col cols="auto" class="py-0">
        <v-btn depressed :color="updateUserButtonColor" :loading="updateLoading" :disabled="updateLoading" @click="updateUser">Opdater</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import validationRules from '@/utils/validationRules';
  import methods from '@/utils/methods';
  import api from '@/api/api';

  export default {
    name: 'ProfileData',

    data: function () {
      return {
        rules: {...validationRules},
        formValid: false,
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        phone: '',
        updateLoading: false,
        updateUserButtonColor: 'primary'
      };
    },

    mounted: function() {
      this.setEditableValues();
    },

    computed: {
      userData: function () {
        return this.$store.state.userData;
      },
    },

    watch: {
      userData: function() {
        this.setEditableValues();
      }
    },

    methods: {
      resetButtons: function () {
        this.updateUserButtonColor !== 'primary' && (this.updateUserButtonColor = 'primary');
      },
      setEditableValues: function() {
        this.userData.firstName && (this.firstName = this.userData.firstName);
        this.userData.lastName && (this.lastName = this.userData.lastName);
        this.userData.userName && (this.userName = this.userData.userName);
        this.email = this.userData.email;
        this.userData.phone && (this.phone = this.userData.phone);
      },
      updateUser: async function() {
        this.$refs.updateForm.validate();
        if (!this.formValid) return;
        let queryParams = {};
        queryParams.firstName = this.firstName;
        queryParams.lastName = this.lastName;
        queryParams.userName = this.userName;
        queryParams.email = this.email;
        methods.checkLength(this.phone) && (queryParams.phone = this.phone);
        try {
          this.updateLoading = true;
          await api.setAccountData(queryParams);
          let userData = await api.getAccountInfo();
          this.updateLoading = false;
          this.updateUserButtonColor = 'success';
          this.$store.commit('changeUserData', userData.data);
        } catch (e) {
          this.updateLoading = false;
          if (e.response.status === 409) {
            methods.showGlobalSnackbar('error', 'Brugernavn allerede taget.');
          }
          this.updateUserButtonColor = 'error';
        }
      }
    }
  };
</script>
