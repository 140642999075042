<template>
  <div class="mb-10">
    <div class="grey-section py-5 px-3">
      <h2 class="mb-2">BEHANDLINGSTYPE</h2>
      <div class="mb-3">
        <template v-for="treatmentItem in treatmentTypes">
          <v-chip class="ma-1" color="grey lighten-2" label :disabled="!!deleteItem">
            <span class="text-truncate" style="max-width: 220px">{{ treatmentItem.name }}</span>
            <v-icon size="20" class="ml-3" @click="openEditDialog(treatmentItem)">mdi-square-edit-outline</v-icon>
            <v-progress-circular v-if="deleteItem && deleteItem.id === treatmentItem.id" :size="20" :width="2" class="ml-3" indeterminate/>
            <v-icon v-else size="22" class="ml-4" @click="openConfirmDialog(treatmentItem)">mdi-delete</v-icon>
          </v-chip>
        </template>
      </div>
      <v-row class="align-center">
        <v-col cols="auto">
          <v-text-field v-model="newType" label="Ny behandlingstype" outlined hide-details dense :disabled="addLoading"/>
        </v-col>
        <v-col>
          <v-btn depressed color="primary" @click="addNewType" :loading="addLoading" :disabled="addLoading || !newType">tilføj</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="editDialog" persistent max-width="500" overlay-color="#fff" :overlay-opacity="0.7">
      <v-form ref="form" v-model="formValid">
        <v-card>
          <v-card-title class="headline mb-4">Redigere behandlingstype</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="editTypeName" label="Behandlingstype" outlined hide-details dense :disabled="editLoading" :rules="[rules.required]"/>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="editTypeExternalId" label="Eksternt Id" persistent-hint hint="Eksempel: 10,11,12" outlined dense :disabled="editLoading"/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12">
                <v-text-field v-model="editPatientRecommendationLink" label="Link til PDF" outlined hide-details dense :disabled="editLoading"/>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn depressed color="primary" @click="editTreatment" :loading="editLoading" :disabled="editLoading">GEM</v-btn>
            <v-btn color="black darken-1" text @click="editDialog = false" :disabled="editLoading">Annuller</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="cofirmDialog" persistent max-width="600" overlay-color="#fff" :overlay-opacity="0.7">
      <v-card>
        <v-card-title class="headline mb-4">Er du sikker på, at du vil slette behandlingstype ?</v-card-title>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="deleteType">slette</v-btn>
          <v-btn color="black darken-1" text @click="closeConfirmDialog">Annuller</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import api from '@/api/api';
  import validationRules from '@/utils/validationRules';
  import methods from '@/utils/methods';

  export default {
    name: 'TreatmentTypes',

    data: function () {
      return {
        rules: {...validationRules},
        formValid: false,
        editDialog: false,
        cofirmDialog: false,
        deleteItem: null,
        newType: '',
        editItem: null,
        editTypeName: '',
        editTypeExternalId: null,
        editPatientRecommendationLink: '',
        addLoading: false,
        editLoading: false,
      };
    },

    computed: {
      treatmentTypes: function () {
        let localData = this.$store.state.treatmentTypes;
        return localData.filter(type => type.id !== -100);
      },
    },

    methods: {
      openEditDialog: function (treatmentItem) {
        this.editDialog = true;
        this.editItem = treatmentItem;
        this.editTypeName = treatmentItem.name;
        this.editTypeExternalId = treatmentItem.externalAppointmentIds;
        this.editPatientRecommendationLink = treatmentItem.patientRecommendationLink;
      },
      openConfirmDialog: function (treatmentItem) {
        this.cofirmDialog = true;
        this.deleteItem = treatmentItem;
      },
      closeConfirmDialog: function () {
        this.cofirmDialog = false;
        this.deleteItem = null;
      },
      getTreatmentTypes: async function () {
        let newTreatmentData = await api.getTreatmentTypes();
        this.$store.commit('updateTreatmentTypes', newTreatmentData.data);
      },
      deleteType: async function () {
        this.cofirmDialog = false;
        try {
          await api.deleteTretmentType(this.deleteItem.id);
          await this.getTreatmentTypes();
          this.deleteItem = null;
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          this.deleteItem = null;
        }
      },
      addNewType: async function () {
        let queryParams = {
          name: this.newType,
        };
        try {
          this.addLoading = true;
          await api.addNewTreatmentType(queryParams);
          await this.getTreatmentTypes();
          this.newType = '';
          this.addLoading = false;
        } catch (e) {
          this.addLoading = false;
        }
      },
      editTreatment: async function () {
        this.$refs.form.validate();
        if (!this.formValid) return;
        let queryParams = {
          name: this.editTypeName,
          id: this.editItem.id
        };
        this.editTypeExternalId ? queryParams.externalAppointmentIds = this.editTypeExternalId : queryParams.externalAppointmentIds = null
        this.editPatientRecommendationLink ? queryParams.patientRecommendationLink = this.editPatientRecommendationLink : queryParams.patientRecommendationLink = null;
        try {
          this.editLoading = true;
          await api.editTretmentType(queryParams);
          await this.getTreatmentTypes();
          this.editDialog = false;
          this.editLoading = false;
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          this.editLoading = false;
        }
      },
    },
  };
</script>
