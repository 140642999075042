import {CLINICS_STATUS_TYPES} from '@/constants/clinicsTypes';

export default {
    namespaced: true,
    state: {
        tableLoading: false,
        showDataTable: false,
        filterParams: {},
        statusItems: [
            {
                key: CLINICS_STATUS_TYPES.ACTIVE_KEY,
                value: CLINICS_STATUS_TYPES.ACTIVE_NAME
            },
            {
                key: CLINICS_STATUS_TYPES.INACTIVE_KEY,
                value: CLINICS_STATUS_TYPES.INACTIVE_NAME
            },
        ]
    },
    mutations: {
        updateTableLoading(state, payload) {
            state.tableLoading = payload;
        },
        updateFilterParams(state, payload) {
            state.filterParams = payload;
        },
        updateShowDataTable(state, payload) {
            state.showDataTable = payload;
        },
    },
};
