<template>
  <div>
    <div class="data-section">
      <v-data-table class="data-table" :headers="tableHeaders" :items="visits" :expanded.sync="expanded" show-expand :loading-text="loadingText" loader-height="2" :loading="tableLoading" item-key="visitId" hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalVisits" @update:options="serverSort">
        <template v-slot:item.operationDate="{ item }">
          <DateTimeCellIso :date="item.operationDate"/>
        </template>
        <template v-slot:item.cancellationDate="{ item }">
          <DateTimeCell :date="item.cancellationDate"/>
        </template>
        <template v-slot:item.hasCancellationMessage="{ item }">
          <v-icon v-if="item.hasCancellationMessage" size="22" class="deep-orange--text text--accent-4">mdi-calendar-remove</v-icon>
        </template>
        <template v-slot:item.visitStatusText="{ item }">
          <v-tooltip bottom :allow-overflow="true">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 100px;">
                <span class="grey--text text--darken-2">{{ item.visitStatusText }}</span>
              </div>
            </template>
            <span>{{ item.visitStatusText }}</span>
          </v-tooltip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <SmsExpanded :messages="item.messages" :visitId="item.visitId" @updateVisit="updateVisit($event)"/>
          </td>
        </template>
      </v-data-table>
      <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :disabled="tableLoading" :total-visible="7" class="py-4" @input="changeCurrentPage"/>
      <v-divider/>
      <CsvExport @exportFinished="showLogs && getLogs()">
        <template v-slot:title>
          Upload af patienttider / uploades dagligt - (bruges til at udsende sms reminders)
        </template>
      </CsvExport>
    </div>
    <v-container fluid class="d-flex justify-end">
      <v-btn color="primary" depressed outlined :loading="logLoading" :disabled="logLoading" @click="toggleLog">
        Vis log
        <v-icon right size="22" class="ml-2">{{ !showLogs ? 'icon-chevron-down' : 'icon-chevron-up' }}</v-icon>
      </v-btn>
    </v-container>
    <v-simple-table v-if="logs.length" style="border: 1px solid #ccc">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="width: 20%">
            Oprette dato
          </th>
          <th class="text-left" style="width: 20%">
            Import dato
          </th>
          <th class="text-left" style="width: 20%">
            Henvisere
          </th>
          <th class="text-left" style="width: 20%">
            Filnavn
          </th>
          <th class="text-left" style="width: 20%">
            Status
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="item in logs"
          :key="item.name"
        >
          <td>{{ `${getDateFromUtc(item['createDate'])} ${getTimeFromUtc(item['createDate'])}` }}</td>
          <td>{{ `${getDateFromUtc(item['importDate'])} ${getTimeFromUtc(item['importDate'])}` }}</td>
          <td>{{ item['user'] }}</td>
          <td>
            <div class="text-truncate" style="max-width: 220px">
              {{ item['fileName'] }}
            </div>
          </td>
          <td>
            <LogIcon :status="item.status" :statusText="item.statusText"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  import api from '@/api/api';
  import SmsExpanded from '@/components/sms/SmsExpanded';
  import DateTimeCellIso from '@/components/common/DateTimeCellIso';
  import DateTimeCell from '@/components/common/DateTimeCell';
  import CsvExport from '@/components/common/CsvExport';
  import methods from '@/utils/methods';
  import LogIcon from '@/components/sms/LogIcon';

  export default {
    name: 'SmsData',
    components: {LogIcon, CsvExport, DateTimeCell, DateTimeCellIso, SmsExpanded},

    data: function () {
      return {
        expanded: [],
        visits: [],
        tableHeaders: [
          {text: 'Nr.', value: 'visitId', width: '90', sortable: false},
          {text: 'CPR-nummer', value: 'patientCpr', width: '11%', sortable: false},
          {text: 'Patient', value: 'patientFullName', width: '17%'},
          {text: 'Aftaletid', value: 'operationDate', width: '13%'},
          {text: 'Afbud tid', value: 'cancellationDate', width: '13%'},
          {text: 'Antal beskeder', value: 'messagesCount', width: '12%', sortable: false, align: 'center'},
          {text: 'Afbud', value: 'hasCancellationMessage', width: '90'},
          {text: 'Henvis. status', value: 'visitStatusText', width: '12%'},
          {value: 'data-table-expand', width: '60px', sortable: false},
        ],
        loadingText: 'Loading... Please wait',
        totalVisits: 100,
        totalPages: 0,
        queryParams: {
          CurrentPage: 1,
          PageSize: 20,
        },
        logLoading: false,
        showLogs: false,
        logs: [],
      };
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.sms.tableLoading;
      },
      filterParams: function () {
        return this.$store.state.sms.filterParams;
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getVisits();
      },
    },

    methods: {
      getDateFromUtc: function (date) {
        return methods.getDateFromUtc(date);
      },
      getTimeFromUtc: function (date) {
        return methods.getTimeFromUtc(date);
      },
      sortOrderValue: function (val) {
        switch (val.sortBy[0]) {
          case 'userName':
            return 0;
          case 'createDate':
            return 1;
        }
      },
      serverSort: function (val) {
        this.queryParams.OrderBy = this.sortOrderValue(val);
        this.queryParams.OrderByAscending = val.sortDesc[0] !== undefined ? !val.sortDesc[0] : null;
        this.getVisits();
      },
      changeCurrentPage: function (value) {
        this.queryParams.CurrentPage = value;
        this.getVisits();
      },
      getVisits: async function () {
        this.visits = [];
        this.expanded = [];
        try {
          this.$store.commit('sms/updateTableLoading', true);
          let visitsData = await api.getSmsAppointments({...this.queryParams, ...this.filterParams});
          this.visits = visitsData.data.entities;
          this.totalPages = visitsData.data.totalPages;
          this.totalVisits = visitsData.data.totalEntities;
          this.$store.commit('sms/updateTableLoading', false);
        } catch (e) {
          this.visits = [];
          this.expanded = [];
          this.$store.commit('sms/updateTableLoading', false);
        }
      },
      updateVisit: function (newVisitItem) {
        let itemIndex = this.visits.findIndex(item => item.visitId === newVisitItem.visitId);
        this.$set(this.visits, itemIndex, newVisitItem);
      },
      getLogs: async function() {
        this.logLoading = true;
        let newLogs = await api.getAppointmentCsvLogs();
        this.logLoading = false;
        this.logs = newLogs.data;
      },
      toggleLog: function () {
        this.showLogs = !this.showLogs;
        if (this.showLogs) {
          this.getLogs()
        } else {
          this.logs = [];
        }
      },
    },
  };
</script>
