<template>
  <div class="ending-letter-data__expand-area">
    <div class="ending-letter-data__details-topic">
      <h2 class="text-h6 font-weight-bold mb-2">Udskrivningsbrev</h2>
      <p class="text-body-2 mb-0">Her downloades udskrivningsbrev og evt. Røntgen/Fotos</p>
    </div>
    <div v-for="(letterItem) in visitItem['terminationLetters']" :key="letterItem.sendDate" class="ending-letter-data__letter">
      <EndingLettersLetter :visitItem="visitItem" :letterItem="letterItem" @clearLetter="clearLetter($event)" @updateVisitItem="updateVisitItem($event)"/>
    </div>
  </div>
</template>

<script>
  import EndingLettersLetter from '@/components/endingLetter/EndingLettersLetter';

  export default {
    name: 'EndingLettersExpanded',

    components: {EndingLettersLetter},

    props: {
      visitItem: {
        type: Object,
      },
    },

    methods: {
      updateVisitItem: function (data) {
        this.$emit('updateVisitItem', data);
      },
      clearLetter: function(letterNumber) {
        this.$emit('clearLetter', letterNumber);
      }
    },
  };
</script>
