<template>
  <v-tooltip bottom :allow-overflow="true">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click="downloadRaiographs(visitItem.id)" size="22" :class="iconColor">icon-xray</v-icon>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'OverviewRadiographsIcon',

    props: {
      visitItem: {
        type: Object,
      },
    },

    computed: {
      tooltipText: function () {
        switch (this.visitItem.radiographExportStatus) {
          case 2:
            return 'Fejl i export, klik for at planlægge et nyt forsøg';
          case 3:
            return 'Arkiveret korrekt';
          default:
            return 'Radiografier er planlagt til at blive arkiveret';
        }
      },
      iconColor: function () {
        switch (this.visitItem.radiographExportStatus) {
          case 2:
            return 'deep-orange--text text--accent-4';
          case 3:
            return 'teal--text';
          default:
            return 'grey--text text--darken-2';
        }
      },
    },

    methods: {
      downloadRaiographs: function (visitId) {
        if (this.visitItem.radiographExportStatus === 2) {
          this.$emit('changeExportStatus');
        }
        methods.downloadURL(api.radiographsFileUrl(visitId));
      },
    },
  };
</script>