<template>
  <div class="download-pdf py-5">
    <v-container>
      <v-row justify="center">
        <v-col cols="5">
          <v-card outlined>
            <v-card-text>
              <a href="/" class="download-pdf__logo-holder">
                <img src="../images/kk-logo.svg" alt="KIRURGIKLINIK"/>
              </a>
              <v-alert type="info" color="green" class="mb-5">Hvis download ikke starter efter 10 sekunder, skal du klikke på download-knappen</v-alert>
              <v-btn block depressed x-large color="primary" @click.stop="downloadPdf" :disabled="!loginStatus">
                <v-icon left size="22">icon-download</v-icon>
                pdf
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'DownloadPDF',

    data: function () {
      return {
        loginStatus: false,
      };
    },

    mounted: async function () {
      // Google Analytics
      ga('set', 'page', '/pdf/visit');
      ga('send', 'pageview');
      // End Google Analytics
      await api.getAccountInfo();
      this.loginStatus = true;
      this.downloadPdf();
    },

    methods: {
      downloadPdf: function () {
        if (methods.getUrlParameter('id')) {
          methods.downloadURL(api.visitFileUrl(methods.getUrlParameter('id')));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .download-pdf {
    background: #233241;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__logo-holder {
      display: block;
      width: 150px;
      margin: 15px auto;

      img{
        width: 100%;
        height: auto;
      }
    }
  }
</style>
