<template>
  <div class="mb-13">
    <v-row class="my-0">
      <v-col cols="12" lg="6" class="py-0 pt-2 mb-5 mb-lg-0 pr-xl-5">
        <HeadingBox icon="mdi-bell" title="Indkaldelse" :loading="callUpLogsLoading">
          <v-alert v-if="!callUpLogsLoading && (visitItem['callUpStatus'] === 1 || visitItem['callUpStatus'] === 2 || visitItem['callUpStatus'] === 4)" dense text border="left" type="info" :color="callupStatusColor">{{ callupStatusText }}</v-alert>
          <template v-if="callUpLogs.length > 0">
            <div v-for="logItem in callUpLogs" class="mb-2 d-flex flex-wrap align-center">
              <v-icon v-if="logItem['callUpStatus'] === 2 || logItem['callUpStatus'] === 4" size="20" class="mr-4 red--text">mdi-alert-circle</v-icon>
              <span class="text-body-1 mr-4 text-no-wrap">{{ logItem['formattedDate'] }}</span>
              <v-icon v-if="logItem['incallType'] === 0" size="20" class="mr-4">mdi-at</v-icon>
              <v-icon v-if="logItem['incallType'] === 1" size="20" class="mr-4">mdi-email</v-icon>
              <v-icon v-if="logItem['incallType'] === 2" size="20" class="mr-4">mdi-message-text</v-icon>
              <v-btn v-if="logItem['pdfFileName']" icon @click.prevent="downloadCallUpFile(logItem['visitLogId'])">
                <v-icon size="25">icon-pdf</v-icon>
              </v-btn>
              <v-btn v-if="logItem['incallContent']" icon @click="openPreviewDialog(logItem['incallContent'])">
                <v-icon size="20" class="grey--text text--darken-2">mdi-eye</v-icon>
              </v-btn>
            </div>
          </template>
          <span v-if="callUpLogs.length === 0" class="d-block text-body-1 grey--text">Ingen data...</span>
        </HeadingBox>
      </v-col>
      <v-col cols="12" lg="6" class="py-0 pl-md-16 pl-lg-0">
        <div class="d-md-flex align-center justify-lg-end">
          <v-radio-group hide-details row v-model="callUpType" class="my-0 pt-0 mr-9 mb-4 mb-md-0">
            <v-radio class="my-2 mr-14" label="SMS" :value="2" color="primary" :disabled='!visitItem.patientMobilePhone'/>
            <v-radio class="my-2 mr-14" label="Email" :value="0" color="primary" :disabled="!patientEmail"/>
            <v-radio class="my-2" label="Brev" :value="1" color="primary"/>
          </v-radio-group>
          <v-btn min-width="150" depressed :color="callUpButtonColor" :loading="callUpSendLoading" :disabled="callUpSendLoading || callUpLogsLoading || visitItem.callUpStatus === 1" @click="sendCallUp">Gensend</v-btn>
        </div>
      </v-col>
      <v-dialog v-model="previewDialog" width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closePreviewDialog">
        <v-card>
          <v-card-text class="py-4">
            <div v-html="previewDialogContent" class="pa-3 rounded" style="white-space: pre-line;background-color: rgba(0, 0, 0, 0.05);font-size: 12px; line-height: 2; font-family: monospace, monospace;"/>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="black darken-1" text @click="previewDialog = false; closePreviewDialog()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';
  import HeadingBox from '../common/HeadingBox';

  export default {
    name: 'OverviewCallUp',

    components: {HeadingBox},

    props: {
      visitItem: {
        type: Object,
      },
      patientEmail: {
        type: String
      },
      patientMobilePhone: {
        type: String
      }
    },

    data: function () {
      return {
        callUpLogsLoading: false,
        callUpLogs: [],
        callUpType: 0,
        callUpButtonColor: 'primary',
        callUpSendLoading: false,
        previewDialog: false,
        previewDialogContent: null
      };
    },

    mounted: async function () {
      this.updateCallUpType();
      this.getCallUpLogs();
    },

    computed: {
      callupStatusText: function () {
        switch (this.visitItem['callUpStatus']) {
          case 2:
            return 'Nogle incalls har fejl.';
          case 4:
            return 'Indkald ikke mulig pga. hemlig adresse.';
          default:
            return 'Incall er planlagt.';
        }
      },
      callupStatusColor: function () {
        switch (this.visitItem['callUpStatus']) {
          case 2:
            return 'deep-orange accent-4';
          case 4:
            return 'deep-orange accent-4';
          default:
            return 'grey darken-2';
        }
      },
    },

    watch: {
      patientEmail: function () {
        this.updateCallUpType();
      },
      patientMobilePhone: function () {
        this.updateCallUpType();
      },
    },

    methods: {
      openPreviewDialog: function (value) {
        this.previewDialogContent = value;
        this.previewDialog = true;
      },
      closePreviewDialog: function () {
        this.previewDialog = false;
        setTimeout(() => {
          this.previewDialogContent = null;
        }, 100);
      },
      downloadCallUpFile: function (visitLogId) {
        methods.downloadURL(api.incallLogsFileUrl(visitLogId));
      },
      setCallUpLogs: function (data) {
        let newData = data;
        newData.data.length > 0 && newData.data.forEach((item) => {
          item.formattedDate = `${methods.getSimpleDate(item['incallDate'])}, ${methods.getSimpleTime(item['incallDate'])}`;
        });
        this.callUpLogs = newData.data;
      },
      updateCallUpType: function() {
        if (this.patientEmail) {
          this.callUpType =  0;
          return;
        }
        if (this.patientMobilePhone) {
          this.callUpType =  2;
          return;
        }
        this.callUpType = 1;
      },
      getCallUpLogs: async function () {
        this.callUpLogsLoading = true;
        let callUpData = await api.getIncallLogs(this.visitItem.id);
        this.setCallUpLogs(callUpData);
        this.callUpLogsLoading = false;
      },
      sendCallUp: async function () {
        try {
          this.callUpSendLoading = true;
          await api.sendCallUp(this.visitItem.id, this.callUpType);
          let newVisit = await api.getVisitInfo(this.visitItem.id);
          this.$emit('updateVisitItem', newVisit.data);
          this.$emit('updateCommonVisitInfo', newVisit.data);
          this.callUpSendLoading = false;
        } catch (e) {
          if (e.response.status === 400) {
            methods.showGlobalSnackbar('error', 'Besøgsopkald via brev er allerede planlagt.');
          }
          this.callUpSendLoading = false;
          this.callUpButtonColor = 'error';
        }
      },
    },
  };
</script>
