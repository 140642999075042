<template>
  <div class="intro-section mb-5">
    <v-container class="py-5">
      <v-row justify="space-between" align="center" class="my-0">
        <v-col class="py-0">
          <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field :value="formattedDateFrom" class="datepicker-input" append-icon="mdi-calendar" @click:append="menuFrom = true" label="Dato" readonly v-on="on"/>
            </template>
            <v-date-picker v-model="dateFrom" no-title @change="menuFrom = false" color="primary lighten-0"/>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="py-0 px-0" align-self="center">
          <v-icon size="15">icon-minus</v-icon>
        </v-col>
        <v-col class="py-0">
          <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field :value="formattedDateTo" class="datepicker-input" append-icon="mdi-calendar" @click:append="menuTo = true" readonly v-on="on"/>
            </template>
            <v-date-picker v-model="dateTo" no-title @change="menuTo = false" color="primary lighten-0"/>
          </v-menu>
        </v-col>
        <v-col cols="12" md="5" class="py-0 ml-md-7">
          <v-row align="center" class="my-0">
            <v-col cols="12" lg="auto" class="py-0 mb-2 mb-lg-0">
              <span class="d-block text-body-1">Vis data:</span>
            </v-col>
            <v-col cols="12" lg="auto" class="py-0">
              <v-radio-group hide-details row v-model="statisticsType" class="my-0 pt-0">
                <v-radio class="my-2 mr-8" label="Behandlinger" :value="0" color="primary"/>
                <v-radio class="my-2 mr-0" label="Henvisere" :value="1" color="primary"/>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="intro-section__bottom-box py-5">
      <v-row justify="end" class="my-0 mx-0 px-5">
        <v-btn depressed class="mb-2 mr-2 mb-md-0" color="primary" outlined :disabled="doctorTableLoading || treatmentTableLoading" @click="clearFilters">
          <v-icon left size="25" class="mr-4">icon-close</v-icon>
          Reset filtre
        </v-btn>
        <v-btn depressed class="ml-2 mb-2 mb-md-0" color="primary" :disabled="doctorTableLoading || treatmentTableLoading" @click="setFilterParams">
          <v-icon left size="22" class="mr-4">icon-search</v-icon>
          Søg
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
  import methods from '../../utils/methods';

  export default {
    name: 'StatisticsFilter',

    data: function () {
      return {
        menuFrom: false,
        dateFrom: null,
        menuTo: false,
        dateTo: null,
        statisticsType: 0,
      };
    },

    mounted: function () {
      this.setFilterParams();
      this.$store.commit('statistics/updateDataType', this.statisticsType);
    },

    computed: {
      formattedDateFrom: function () {
        if (this.dateFrom) {
          return methods.formatDate(this.dateFrom);
        } else {
          return 'Fra';
        }
      },
      formattedDateTo: function () {
        if (this.dateTo) {
          return methods.formatDate(this.dateTo);
        } else {
          return 'Til';
        }
      },
      treatmentTableLoading: function () {
        return this.$store.state.statistics.treatmentTableLoading;
      },
      doctorTableLoading: function () {
        return this.$store.state.statistics.doctorTableLoading;
      },
    },

    watch: {
      statisticsType: function(val) {
        this.$store.commit('statistics/updateDataType', val);
      },
    },

    methods: {
      clearFilters: function () {
        this.dateFrom = null;
        this.dateTo = null;
        this.setFilterParams();
      },
      filterParams: function () {
        let params = {};
        this.dateFrom && (params.DateFrom = new Date(this.dateFrom).toISOString());
        this.dateTo && (params.DateTo = new Date(this.dateTo).toISOString());
        return params;
      },
      setFilterParams: function () {
        this.$store.commit('statistics/updateFilterParams', this.filterParams());
      },
    },
  };
</script>
