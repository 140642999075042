<template>
  <div class="ending-letter-data__letter-area">
    <v-row justify="space-between" class="my-0 mx-0">
      <v-col cols="12" xl="8" class="px-0">
        <v-icon v-if="letterItem['isEdiTermination']" size="21" color="#48aa7b" class="mr-2">icon-edi</v-icon>
        <template v-if='isAdmin'>
          <v-chip v-if="letterItem['status'] === 0" small>Afventer i kø</v-chip>
          <v-chip v-if="letterItem['status'] === 1" color="teal" text-color="white" small>Ubrev sendt med succes</v-chip>
          <v-chip v-if="letterItem['status'] === 2" color="deep-orange accent-4" text-color="white" small>{{ letterItem['errorMessage'] ? letterItem['errorMessage'] : 'Udsendelse' }}</v-chip>
        </template>
      </v-col>
      <SendAsEmailCheckbox v-if="isAdmin && letterItem['isEdiTermination']" :visitItem="visitItem" :number="letterItem['letterNumber']" :sendEdiTerminationAsEmail="letterItem['sendEdiTerminationAsEmail']" @updateVisitItem="updateVisitItem($event)"/>
    </v-row>
    <v-row justify="space-between" class="my-0 mx-0">
      <v-col cols="12" xl="2" class="px-0 py-0 mb-4 mb-xl-0">
        <div class="ending-letter-data__files-topic">
          {{ letterItem['sendDate'] ? getDateFromIso(letterItem['sendDate']) : '--.--' }} <br>
          {{ letterItem['sendDate'] ? getTimeFromIso(letterItem['sendDate']) : '--.--' }}
        </div>
      </v-col>
      <v-col cols="12" xl="3" class="px-0 py-0 mb-4 mb-xl-0">
        <div class="ending-letter-data__category-holder">
          <div class="ending-letter-data__category">Udskrivningsbrev</div>
          <UploadDocumentsButton :visitItem="visitItem" :number="letterItem['letterNumber']" type="doc" @updateVisitItem="updateVisitItem($event)" v-if="isAdmin"/>
        </div>
        <v-chip v-if="letterItem['diagnosFileName']" @click="downloadDiagnosis" small>
          <span class="text-truncate" style="max-width: 220px">{{ letterItem['diagnosFileName'] }}</span>
        </v-chip>
        <span v-else>ingen fil</span>
      </v-col>
      <v-col cols="12" xl="3" class="px-0 py-0 mb-4 mb-xl-0">
        <div class="ending-letter-data__category-holder">
          <div class="ending-letter-data__category">Røntgen / Fotos</div>
          <UploadDocumentsButton :visitItem="visitItem" :number="letterItem['letterNumber']" type="ren" @updateVisitItem="updateVisitItem($event)" v-if="isAdmin"/>
        </div>
        <v-chip v-if="letterItem['rentgenFileName']" @click="downloadRentgen" small>
          <span class="text-truncate" style="max-width: 220px">{{ letterItem['rentgenFileName'] }}</span>
        </v-chip>
        <span v-else>ingen fil</span>
      </v-col>
      <v-col cols="12" xl="4" class="px-0 py-0 text-xl-right">
        <v-btn depressed :loading="resendLoading" :disabled="resendLoading || !letterItem['diagnosFileName']" :color="resendButtonColor" @click="resendLetter" v-if="isAdmin">
          {{ letterItem['diagnosFileName'] ? 'Gensend udskrivningsbrev' : 'Pdf-filen mangler' }}
        </v-btn>
        <div class="ending-letter-data__info-row ma-2">
          Sidst redigeret af: {{ letterItem['diagnosCreateDate'] ? `${getDateFromIso(letterItem['diagnosCreateDate'])} ${getTimeFromIso(letterItem['diagnosCreateDate'])}` : '--.--' }}
        </div>
        <div v-if="letterItem['surgeonEmail']" class="ending-letter-data__info-row ma-2">
          Behandlerid: {{ letterItem['surgeonEmail'] }}
        </div>
        <div v-if="letterItem['attempts']" class="ending-letter-data__info-row ma-2">
          Udsendeslesforsøg: {{letterItem['attempts']}}/2
        </div>
        <v-btn class='mb-2' depressed color="primary" :loading="deleteLoading" :disabled="deleteLoading" :color="deleteButtonColor" @click="dialog = true" v-if="isAdmin">Slet</v-btn>
        <template v-if='isAdmin'>
          <v-btn depressed :color="sendSmsButtonColor" :loading="sendSmsLoading" :disabled="sendSmsLoading || !letterItem.rentgenFileName || !visitItem.patientMobilePhone" @click="sendSms">Send Røntgen til patienten</v-btn>
          <div class='ending-letter-data__info-row ma-2'>
            {{smsSentDate}}
            <span v-if="letterItem['smsRadiographStatus'] === 0"> Status: Afventer i kø</span>
            <span v-if="letterItem['smsRadiographStatus'] === 2"> Status: fejlet, prøv evt. igen</span>
          </div>
        </template>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="500" overlay-color="#fff" :overlay-opacity="0.7">
        <v-card>
          <v-card-title class="headline">Slette Udskrivningsbrev ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deleteLetter">Slet</v-btn>
            <v-btn color="red darken-1" text @click="dialog = false">Afbestille</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="space-between" class="my-0 mx-0">
      <v-col cols="12" class="px-0 py-5">
        <div class="d-flex align-center text-body-2">
          <v-icon size="20" class="mr-1">mdi-at</v-icon>
          <b>{{ letterItem['recipientEmail'] ? letterItem['recipientEmail'] : '--.--' }}</b>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import methods from '@/utils/methods';
  import api from '@/api/api';
  import UploadDocumentsButton from '@/components/endingLetter/UploadDocumentsButton';
  import SendAsEmailCheckbox from '@/components/endingLetter/SendAsEmailCheckbox.vue';

  export default {
    name: 'EndingLettersLetter',

    components: { SendAsEmailCheckbox, UploadDocumentsButton},

    props: {
      visitItem: {
        type: Object,
      },
      letterItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        dialog: false,
        deleteLoading: false,
        deleteButtonColor: 'primary',
        resendLoading: false,
        resendButtonColor: 'primary',
        sendSmsButtonColor: 'primary',
        sendSmsLoading: false,
      };
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      smsSentDate: function () {
        return this.letterItem.smsRadiographStatusDate ? `Sendt: ${methods.getSimpleDate(this.letterItem.smsRadiographStatusDate)}` : '';
      },
    },

    methods: {
      getDateFromIso: function (date) {
        return methods.getDateFromIso(date);
      },
      getTimeFromIso: function (date) {
        return methods.getTimeFromIso(date);
      },
      updateVisitItem: function (data) {
        this.$emit('updateVisitItem', data);
      },
      downloadDiagnosis: function () {
        methods.downloadURL(api.diagnosisFileUrl(this.visitItem.id, this.letterItem['letterNumber']));
      },
      downloadRentgen: function () {
        methods.downloadURL(api.rentgenFileUrl(this.visitItem.id, this.letterItem['letterNumber']));
      },
      resendLetter: async function () {
        try {
          let newVisitId = this.letterItem['visitId'] ? this.letterItem['visitId'] : this.visitItem['id'];
          this.resendLoading = true;
          await api.resendLetterEmail(newVisitId, this.letterItem['letterNumber']);
          let newVisit = await api.getTerminationLetters({VisitNumber: this.visitItem['id']});
          this.resendLoading = false;
          this.resendButtonColor = 'green lighten-2';
          this.$emit('updateVisitItem', newVisit.data.visits[0]);
        } catch (e) {
          this.resendLoading = false;
          this.resendButtonColor = 'error';
        }
      },
      deleteLetter: async function () {
        this.dialog = false;
        try {
          this.deleteLoading = true;
          await api.deleteLetter(this.letterItem['visitId'], this.letterItem['letterNumber']);
          this.deleteLoading = false;
          this.$emit('clearLetter', this.letterItem['letterNumber']);
        } catch (e) {
          this.deleteLoading = false;
          this.deleteButtonColor = 'error';
        }
      },
      sendSms: async function() {
        try {
          this.sendSmsLoading = true;
          this.sendSmsButtonColor = 'primary';
          await api.sendLetterSmsWithLink(this.visitItem.id, this.letterItem.letterNumber);
          let newVisit = await api.getTerminationLetters({VisitNumber: this.visitItem['id']});
          this.sendSmsButtonColor = 'success';
          this.sendSmsLoading = false;
          this.$emit('updateVisitItem', newVisit.data.visits[0]);
        } catch (e) {
          this.sendSmsButtonColor = 'error';
          this.sendSmsLoading = false;
        }
      }
    },
  };
</script>
