<template>
  <div class="intro-section mb-5">
    <v-container class="py-5">
      <v-row justify="space-between" align="center" class="my-0">
        <v-col cols="12" md="4" class="py-0">
          <v-select :items="statusItems" item-text="value" item-value="key" label="Status" v-model="status"/>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-autocomplete :items="activeClinics" item-text="name" item-value="id" label="Klinik" v-model="clinicId" :loading="activeClinicsLoading"/>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field v-model="search" label="Søg" @keyup.enter="setFilterParams"/>
        </v-col>
      </v-row>
      <v-row class="my-0 py-0 mb-6">
        <v-col cols="auto" class="py-0">
          <v-checkbox :class="['my-0', 'py-0']" v-model="onlyEmptyDentistId" hide-details>
            <template v-slot:label>
              Kun tomt henviser ID
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <div class="intro-section__bottom-box py-5">
      <v-row justify="end" class="my-0 mx-0 px-5">
        <v-btn depressed class="mb-2 mr-2 mb-md-0" color="primary" :disabled="tableLoading" outlined @click="clearFilters">
          <v-icon left size="25" class="mr-4">icon-close</v-icon>
          Reset filtre
        </v-btn>
        <v-btn depressed class="ml-2 mb-2 mb-md-0" color="primary" :disabled="tableLoading" @click="setFilterParams">
          <v-icon left size="22" class="mr-4">icon-search</v-icon>
          Søg
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
  import api from '../../api/api';

  export default {
    name: 'UsersFilter',

    data: function () {
      return {
        clinicId: null,
        status: 1,
        search: '',
        onlyEmptyDentistId: false
      };
    },

    mounted: async function() {
      this.setFilterParams();
      this.$store.commit('users/updateShowDataTable', true);
      try {
        this.$store.commit('users/setActiveClinicsLoading', true);
        let activeClinics = await api.getActiveClinics();
        this.$store.commit('users/setActiveClinics', activeClinics.data);
        this.$store.commit('users/setActiveClinicsLoading', false);
      } catch (e) {
        this.$store.commit('users/setActiveClinicsLoading', true);
      }
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.users.tableLoading;
      },
      activeClinics: function () {
        let defaultItem = [
          {
            id: null,
            name: 'Alle',
          },
        ];
        if (this.$store.state.users.activeClinics.length > 0) {
          return [...defaultItem, ...this.$store.state.users.activeClinics];
        } else {
          return defaultItem;
        }
      },
      activeClinicsLoading: function() {
        return this.$store.state.users.activeClinicsLoading;
      },
      statusItems: function() {
        let defaultItem = [
          {
            key: null,
            value: 'Alle'
          }
        ];
        return [...defaultItem, ...this.$store.state.users.statusItems];
      }
    },

    methods: {
      clearFilters: function () {
        this.clinicId = null;
        this.status = 1;
        this.search = '';
        this.onlyEmptyDentistId = false;
        this.setFilterParams();
      },
      filterParams: function () {
        let params = {};
        this.status !== null && (params.UserStatus = this.status);
        this.clinicId && (params.ClinicId = this.clinicId);
        this.search && (params.Search = this.search);
        params.onlyEmptyDentistId = this.onlyEmptyDentistId;
        return params;
      },
      setFilterParams: function () {
        if (!this.tableLoading) {
          this.$store.commit('users/updateFilterParams', this.filterParams());
        }
      },
    },
  };
</script>
