<template>
  <v-data-table class="data-table treatments-table" :headers="treatmentHeaders" :items="treatments" :loading-text="loadingText" loader-height="2" :loading="tableLoading" :server-items-length="treatments.length" hide-default-footer :mobile-breakpoint="0">
    <template v-slot:item.doctorFullName="{ item }">
      <span>{{ item.doctorFullName ? item.doctorFullName.trim() : '--' }}</span>
    </template>
    <template v-slot:item.doctorClinic="{ item }">
      <span>{{ item.doctorClinic ? item.doctorClinic.trim() : '--' }}</span>
    </template>
    <template v-slot:item.lastOperationDate="{ item }">
      <DateCell :date="item.lastOperationDate"/>
    </template>
  </v-data-table>
</template>

<script>
  import api from '../../api/api';
  import DateCell from '../../components/common/DateCell';

  export default {
    name: 'StatisticsTreatmentsData',

    components: {DateCell},

    data: function () {
      return {
        treatmentHeaders: [
          {text: 'Placering', value: 'place', sortable: false, width: '5%'},
          {text: 'Behandlingstype', value: 'treatmentType', sortable: false, width: '16%'},
          {text: 'Antal', value: 'operationsCount', sortable: false, width: '9%'},
          {text: 'Top henviser', value: 'doctorFullName', sortable: false, width: '25%'},
          {text: 'Klinik', value: 'doctorClinic', sortable: false, width: '20%'},
          {text: 'Sidste henvisningsdato', value: 'lastOperationDate', sortable: false},
        ],
        loadingText: 'Loading... Please wait',
        treatments: [],
      };
    },

    mounted: function () {
      this.getOperationsStat();
    },

    computed: {
      filterParams: function () {
        return this.$store.state.statistics.filterParams;
      },
      tableLoading: function () {
        return this.$store.state.statistics.treatmentTableLoading;
      },
    },

    watch: {
      filterParams: function () {
        this.getOperationsStat();
      },
    },

    methods: {
      getOperationsStat: async function () {
        try {
          this.treatments = [];
          this.$store.commit('statistics/updateTreatmentTableLoading', true);
          let {data} = await api.getOperationStatistic(this.filterParams);
          data.forEach((item, index) => {
            item.place = index + 1;
          });
          this.treatments = data;
          this.$store.commit('statistics/updateTreatmentTableLoading', false);
        } catch (e) {
          this.treatments = [];
          this.$store.commit('statistics/updateTreatmentTableLoading', false);
        }
      },
    },
  };
</script>