<template>
  <div>
    <v-data-table class="data-table" :headers="doctorHeaders" :items="doctors" :page.sync="page" @page-count="totalPages = $event" :items-per-page="itemsPerPage" :loading-text="loadingText" loader-height="2" :loading="tableLoading" :expanded.sync="expanded" item-key="place" :mobile-breakpoint="1160" show-expand hide-default-footer>
      <template v-slot:item.doctorFullName="{ item }">
        <span>{{ item.doctorFullName ? item.doctorFullName.trim() : '--' }}</span>
      </template>
      <template v-slot:item.doctorClinic="{ item }">
        <span>{{ item.doctorClinic ? item.doctorClinic.trim() : '--' }}</span>
      </template>
      <template v-slot:item.lastOperationDate="{ item }">
        <DateCell :date="item.lastOperationDate"/>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container class="px-0 py-8" fluid>
            <v-row class="my-0">
              <v-col cols="12" class="py-0">
                <h2 class="text-h6 font-weight-bold mb-4">Behandlingstyper:</h2>
              </v-col>
              <v-col cols="12" class="py-0">
                <template v-for="element in item.treatments">
                  <v-chip class="mr-2 mb-2" label outlined>
                    <span class="mr-3">{{ element.value }}</span> {{ element.name }}
                  </v-chip>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
    <v-pagination color="#e0e6ec" v-model="page" :length="totalPages" :total-visible="7" :disabled="tableLoading" class="py-4"/>
    <v-divider/>
  </div>
</template>

<script>
  import api from '../../api/api';
  import DateCell from '../../components/common/DateCell';

  export default {
    name: 'StatisticsDoctorsData',

    components: {DateCell},

    data: function () {
      return {
        page: 1,
        totalPages: 0,
        itemsPerPage: 20,
        doctorHeaders: [
          {text: 'Placering', value: 'place', sortable: false, width: '5%'},
          {text: 'Henviser', value: 'doctorFullName', sortable: false, width: '20%'},
          {text: 'Klinik', value: 'doctorClinic', sortable: false, width: '20%'},
          {text: 'Antal henvisninger', value: 'operationsCount', sortable: false, width: '20%'},
          {text: 'Sidste henvisningsdato', value: 'lastOperationDate', sortable: false},
          {value: 'data-table-expand', sortable: false, width: '44px'},
        ],
        loadingText: 'Loading... Please wait',
        doctors: [],
        expanded: [],
      };
    },

    mounted: function () {
      this.getDoctorsStat();
    },

    computed: {
      filterParams: function () {
        return this.$store.state.statistics.filterParams;
      },
      tableLoading: function () {
        return this.$store.state.statistics.doctorTableLoading;
      },
    },

    watch: {
      filterParams: function () {
        this.getDoctorsStat();
      },
    },

    methods: {
      getDoctorsStat: async function () {
        try {
          this.doctors = [];
          this.$store.commit('statistics/updateDoctorTableLoading', true);
          let {data} = await api.getDoctorsStatistic(this.filterParams);
          let keys = Object.keys(data);
          let newTreatmentTypes = [];
          keys.forEach((item) => {
            if (item.indexOf('type') === 0) {
              newTreatmentTypes.push({
                key: parseInt(item.slice(4, item.indexOf('Text'))),
                name: data[item],
              });
            }
          });
          data.models.forEach((doctorItem, index) => {
            doctorItem.place = index + 1;
            doctorItem.treatments = [];
            newTreatmentTypes.forEach((treatmentItem) => {
              doctorItem.treatments.push({
                name: treatmentItem.name,
                value: doctorItem[`type${treatmentItem.key}`] ? doctorItem[`type${treatmentItem.key}`] : '0',
              });
            });
          });
          this.doctors = data.models;
          this.$store.commit('statistics/updateDoctorTableLoading', false);
        } catch (e) {
          this.doctors = [];
          this.$store.commit('statistics/updateDoctorTableLoading', false);
        }
      },
    },
  };
</script>
