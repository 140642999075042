<template>
  <div class="data-section">
    <v-data-table class="data-table" :headers="importsHeaders" :items="imports" :loading-text="loadingText" loader-height="2" :loading="tableLoading" :items-per-page="queryParams.PageSize" hide-default-footer :mobile-breakpoint="1160" @update:options="getImports">
      <template v-slot:item.createDate="{ item }">
        <DateTimeCell :date="item.createDate" />
      </template>
      <template v-slot:item.appointmentDate="{ item }">
        <DateTimeCellSimple :date="item.appointmentDate" />
      </template>
      <template v-slot:item.status="{ item }">
        <ImportsIcon :status="item.status" :statusDescription="item.statusDescription"/>
      </template>
    </v-data-table>
    <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :total-visible="7" :disabled="tableLoading" class="py-4" @input="changeCurrentPage"/>
  </div>
</template>

<script>
  import api from '@/api/api';
  import ImportsIcon from '@/components/settings/ImportsIcon';
  import DateTimeCell from '@/components/common/DateTimeCell';
  import DateCell from '@/components/common/DateCell';
  import DateTimeCellSimple from '@/components/common/DateTimeCellSimple';

  export default {
    name: 'ImportsData',
    components: {DateTimeCellSimple, DateCell, DateTimeCell, ImportsIcon},
    data: function () {
      return {
        totalPages: 0,
        importsHeaders: [
          {text: 'Importtid', value: 'createDate', sortable: false},
          {text: 'CPR-nummer', value: 'cpr', sortable: false},
          {text: 'Nr.', value: 'visitId', sortable: false},
          {text: 'Behandlings dato & tid', value: 'appointmentDate', sortable: false},
          {text: 'Status', value: 'status', sortable: false},
          {text: 'Ekstern kode', value: 'externalAppointmentId', sortable: false},
        ],
        loadingText: 'Loading... Please wait',
        imports: [],
        queryParams: {
          CurrentPage: 1,
          PageSize: 20,
        },
      };
    },

    computed: {
      filterParams: function () {
        return this.$store.state.imports.filterParams;
      },
      tableLoading: function () {
        return this.$store.state.imports.tableLoading;
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getImports();
      },
    },

    methods: {
      changeCurrentPage: function (value) {
        this.queryParams.CurrentPage = value;
        this.getImports();
      },
      updateImports: async function() {
        try {
          this.queryParams.CurrentPage = 1;
          let {data} = await api.getAppointmentLogs({...this.queryParams, ...this.filterParams});
          this.totalPages = Math.ceil(
            data.totalEntities / this.queryParams.PageSize,
          );
          this.imports = data.entities;
        } catch (e) {}
      },
      getImports: async function () {
        try {
          this.imports = [];
          this.$store.commit('imports/updateTableLoading', true);
          let {data} = await api.getAppointmentLogs({...this.queryParams, ...this.filterParams});
          this.totalPages = Math.ceil(
              data.totalEntities / this.queryParams.PageSize,
          );
          this.imports = data.entities;
          this.$store.commit('imports/updateTableLoading', false);
        } catch (e) {
          this.imports = [];
          this.$store.commit('imports/updateTableLoading', false);
        }
      },
    },
  };
</script>
