<template>
  <div v-if="userData">
    <v-container class="px-xl-7" fluid>
      <v-row class="my-0 align-start">
        <v-col cols="12" lg="8" class="pr-lg-7">
          <v-form ref="editForm" v-model="editFormValid">
            <v-row class="my-0 mb-6">
              <v-col v-if="userData.isAdmin" cols="12" md="6" class="py-0">
                <div class="d-flex justify-start">
                  <v-checkbox :class="['my-0', 'py-0']" v-model="isSurgeon" @change="resetButtons" label="Surgeon" hide-details />
                </div>
              </v-col>
              <v-col v-if="!userData.isAdmin" cols="12" md="6" class="py-0">
                <div class="d-flex justify-start">
                  <v-checkbox :class="['my-0', 'py-0']" v-model="isSecretary" @change="resetButtons" label="Tandlæge sekretær" hide-details />
                </div>
              </v-col>
              <v-col v-if="!userData.isAdmin && isAdmin" cols="12" md="6" class="py-0">
                <div class="d-flex justify-start">
                  <v-checkbox :class="['my-0', 'py-0']" v-model="sendEndingLettersViaEDI" @change="resetButtons" label="Send Udskrivningsbrev via EDI" hide-details />
                </div>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="firstName" label="Fornavn *" :rules="[rules.required]" @input="resetButtons" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="lastName" label="Efternavn *" :rules="[rules.required]" @input="resetButtons" filled/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="userName" label="Brugernavn *" :rules="[rules.required]" @input="resetButtons" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select :items="statusItems" item-text="value" item-value="key" label="Status" v-model="status" @change="resetButtons" filled/>
              </v-col>
            </v-row>
            <v-row v-if="userData.isAdmin" class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="email" label="E-mail *" :rules="[rules.required, rules.email]" @input="resetButtons" filled/>
              </v-col>
            </v-row>
            <v-row v-if="!userData.isAdmin" class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="hemviserId" label="Henviser id" @input="resetButtons" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model.number="ediDentistId" :disabled="!isAdmin" label="EDI Henviser id" @input="resetButtons" filled/>
              </v-col>
            </v-row>
            <v-row v-if="!userData.isAdmin" class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <div class="d-flex justify-start">
                  <v-select :items="activeClinics" item-text="name" item-value="id" label="Klinik" v-model="clinicId" :loading="activeClinicsLoading" disabled filled/>
                </div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="email" label="E-mail *" :rules="[rules.required, rules.email]" @input="resetButtons" filled/>
              </v-col>
            </v-row>
            <v-row v-if="!userData.isAdmin" class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="phone" label="Telefon" @input="resetButtons" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="mobilePhone" label="Mobil" @input="resetButtons" filled/>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="my-0">
            <v-col cols="auto">
              <v-btn depressed class="mr-4" :color="updateUserButtonColor" :loading="updateLoading" :disabled="updateLoading" @click="updateUser">Opdater bruger</v-btn>
              <v-btn v-if="!isChangePassword" depressed color="primary" outlined @click="togglePassword">Ændre password</v-btn>
            </v-col>
          </v-row>
          <v-form v-if="isChangePassword" ref="passwordForm" v-model="passwordFormValid">
            <v-divider class="my-6"/>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="password" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.minLength_7, rules.digit, rules.lowerCase, rules.upperCase]" :type="passwordShow ? 'text' : 'password'" label="Indtast password" counter filled @input="resetButtons" @click:append="passwordShow = !passwordShow"/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="passwordConfirm" :append-icon="passwordConfirmShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRepeatRule]" :type="passwordConfirmShow ? 'text' : 'password'" label="Gentag password" filled @input="resetButtons" @click:append="passwordConfirmShow = !passwordConfirmShow"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto" class="py-0">
                <v-btn class="mb-7 mr-4" depressed :color="passwordButtonColor" @click="updatePassword">Ændre password</v-btn>
                <v-btn class="mb-7" color="black darken-1" text @click="togglePassword">Annuller</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" lg="4">
          <div class="pa-5" style="background: #0000000f; border-radius: 5px; border: 1px solid #ccc">
            <div class="mb-10">
              <div :class="[captionClasses]">ID</div>
              <div class="text-body-1">{{userId}}</div>
            </div>
            <div class="mb-10">
              <div :class="[captionClasses]">Udarbejdet af</div>
              <div v-if="userData.creatorUserName" class="text-body-1">{{ userData.creatorUserName }}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
            <div class="mb-10">
              <div :class="[captionClasses]">Opdater</div>
              <div class="text-body-1">{{`${getDateFromUtc(userData.createDate)} ${getTimeFromUtc(userData.createDate)}`}}</div>
            </div>
            <div class="mb-10">
              <div :class="[captionClasses]">Dato for password reset</div>
              <div v-if="userData.lastPasswordResetDate" class="text-body-1">{{`${getDateFromUtc(userData.lastPasswordResetDate)} ${getTimeFromUtc(userData.lastPasswordResetDate)}`}}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
            <div v-if="!userData.isAdmin" class="mb-10">
              <div :class="[captionClasses]">Accepterede betingelser</div>
              <div v-if="userData.acceptedConditionsAgreementDate" class="text-body-1">{{`${getDateFromUtc(userData.acceptedConditionsAgreementDate)} ${getTimeFromUtc(userData.acceptedConditionsAgreementDate)}`}}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
            <div class="mb-10">
              <div :class="[captionClasses]">Sidst aktiv</div>
              <div v-if="userData.lastActiveDate" class="text-body-1">{{`${getDateFromUtc(userData.lastActiveDate)} ${getTimeFromUtc(userData.lastActiveDate)}`}}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div v-else>
    <v-progress-linear indeterminate color="blue-grey" :height="1"/>
  </div>
</template>

<script>
  import api from '../../api/api';
  import validationRules from '../../utils/validationRules';
  import methods from '../../utils/methods';
  import {COMMON_TYPES} from '@/constants/commonTypes'

  export default {
    name: 'UsersExpanded',

    props: {
      userId: {
        type: Number,
      }
    },

    data: function () {
      return {
        userData: null,
        captionClasses: [...COMMON_TYPES.CAPTION_CLASSES],
        rules: {...validationRules},
        editFormValid: false,
        passwordFormValid: false,
        isSecretary: false,
        isSurgeon: false,
        sendEndingLettersViaEDI: false,
        firstName: '',
        lastName: '',
        userName: '',
        status: null,
        email: '',
        clinicId: null,
        hemviserId: '',
        ediDentistId: '',
        phone: '',
        mobilePhone: '',
        isChangePassword: false,
        password: '',
        passwordShow: false,
        passwordConfirm: '',
        passwordConfirmShow: false,
        passwordLoading: false,
        passwordButtonColor: 'primary',
        updateLoading: false,
        updateUserButtonColor: 'primary'
      };
    },

    mounted: async function() {
      try {
        let newUserData = await api.getSingleUser(this.userId);
        this.userData = newUserData.data;
        this.setEditableValues();
      } catch (e) {}
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      passwordRepeatRule: function () {
        return this.password === this.passwordConfirm || 'Passwords matcher ikke';
      },
      activeClinics: function () {
        return this.$store.state.users.activeClinics;
      },
      activeClinicsLoading: function() {
        return this.$store.state.users.activeClinicsLoading;
      },
      statusItems: function() {
        return this.$store.state.users.statusItems;
      }
    },

    methods: {
      getDateFromUtc: function (date) {
        return methods.getDateFromUtc(date);
      },
      getTimeFromUtc: function (date) {
        return methods.getTimeFromUtc(date);
      },
      togglePassword: function() {
        this.isChangePassword = !this.isChangePassword;
        this.password = '';
        this.passwordConfirm = '';
        this.passwordButtonColor = 'primary';
      },
      resetButtons: function () {
        this.updateUserButtonColor !== 'primary' && (this.updateUserButtonColor = 'primary');
      },
      updatePassword: async function() {
        this.$refs.passwordForm.validate();
        if (!this.passwordFormValid) return;
        let queryParams = {};
        queryParams.newPassword = this.password;
        queryParams.userIdToResetPassword = this.userId;
        try {
          this.passwordLoading = true;
          await api.changePassword(queryParams);
          this.passwordLoading = false;
          methods.showGlobalSnackbar('success', 'Adgangskoden er blevet ændret');
          this.isChangePassword = false;
        } catch (e) {
          this.passwordButtonColor = 'error';
          this.passwordLoading = false;
        }
      },
      setEditableValues: function() {
        if (this.userData.isAdmin) {
          this.isSurgeon = this.userData.isSurgeon;
          this.userData.firstName && (this.firstName = this.userData.firstName);
          this.userData.lastName && (this.lastName = this.userData.lastName);
          this.userData.userName && (this.userName = this.userData.userName);
          this.status = this.userData.status;
          this.email = this.userData.email;
        } else {
          this.isSecretary = this.userData.isSecretary;
          this.sendEndingLettersViaEDI = this.userData.sendEndingLettersViaEDI;
          this.userData.firstName && (this.firstName = this.userData.firstName);
          this.userData.lastName && (this.lastName = this.userData.lastName);
          this.userData.userName && (this.userName = this.userData.userName);
          this.status = this.userData.status;
          this.userData.dentistExternalId && (this.hemviserId = this.userData.dentistExternalId);
          this.userData.ediDentistId && (this.ediDentistId = this.userData.ediDentistId);
          this.userData.clinicId && (this.clinicId = this.userData.clinicId);
          this.email = this.userData.email;
          this.userData.mobilePhone && (this.mobilePhone = this.userData.mobilePhone);
          this.userData.phone && (this.phone = this.userData.phone);
        }
      },
      updateUser: async function() {
        this.$refs.editForm.validate();
        if (!this.editFormValid) return;
        let queryParams = {};
        let newUserData = {};
        if (this.userData.isAdmin) {
          queryParams.userId = this.userId;
          queryParams.firstName = this.firstName;
          queryParams.lastName = this.lastName;
          queryParams.userName = this.userName;
          queryParams.email = this.email;
          queryParams.status = this.status;
          queryParams.isSurgeon = this.isSurgeon;
        } else {
          queryParams.userId = this.userId;
          queryParams.sendEndingLettersViaEDI = this.sendEndingLettersViaEDI;
          queryParams.firstName = this.firstName;
          queryParams.lastName = this.lastName;
          queryParams.userName = this.userName;
          queryParams.email = this.email;
          queryParams.status = this.status;
          queryParams.isSecretary = this.isSecretary;
          methods.checkLength(this.phone) && (queryParams.phone = this.phone);
          methods.checkLength(this.mobilePhone) && (queryParams.mobilePhone = this.mobilePhone);
          methods.checkLength(this.hemviserId) && (queryParams.dentistExternalId = this.hemviserId);
          this.ediDentistId && (queryParams.ediDentistId = this.ediDentistId);
        }
        try {
          this.updateLoading = true;
          if (this.userData.isAdmin) {
            await api.updateAdmin(queryParams);
          } else {
            await api.updateUser(queryParams);
          }
          newUserData = await api.getSingleUser(this.userId);
          this.$store.dispatch('getDoctors');
          this.userData = newUserData.data;
          this.setEditableValues();
          this.updateLoading = false;
          this.updateUserButtonColor = 'success';
          this.$emit('updateCommonUserData', newUserData.data);
        } catch (e) {
          this.updateLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          this.updateUserButtonColor = 'error';
        }
      }
    }
  };
</script>
