<template>
  <v-btn v-if="visitItem.canRecall" small depressed min-width="0" :color="buttonColor" :loading="loading" :disabled="loading" @click="reCall">Gensend</v-btn>
</template>

<script>
  import api from '../../api/api';

  export default {
    name: 'CallUpRecall',

    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        loading: false,
        buttonColor: 'primary',
      };
    },

    methods: {
      reCall: async function () {
        let queryData = {};
        queryData.recall = true;
        this.visitItem.id && (queryData.visitId = this.visitItem.id);
        this.visitItem.patientAddress && (queryData.patientAddress = this.visitItem.patientAddress);
        this.visitItem.patientZip && (queryData.patientZip = this.visitItem.patientZip);
        this.visitItem.patientCity && (queryData.patientCity = this.visitItem.patientCity);
        this.visitItem.patientEmail && (queryData.patientEmail = this.visitItem.patientEmail);
        this.visitItem.patientPhone && (queryData.patientPhone = this.visitItem.patientPhone);
        this.visitItem.callUpComment && (queryData.incallComment = this.visitItem.callUpComment);
        this.visitItem.patientMobilePhone && (queryData.patientMobilePhone = this.visitItem.patientMobilePhone);
        queryData.incallType = this.visitItem.callUpType;
        try {
          this.loading = true;
          await api.sendIncall(queryData);
          let newVisit = await api.getIncalls({VisitNumber: this.visitItem.id});
          this.$emit('updateCommonInfo', newVisit.data.paginationResult.entities[0]);
          this.loading = false;
          this.buttonColor = 'success';
        } catch (e) {
          this.loading = false;
          this.buttonColor = 'error';
        }
      },
    },
  };
</script>
