<template>
  <v-alert v-model="callupDialog" color="blue-grey lighten-5" class="mb-5" prominent dense>
    <div class="d-flex justify-space-between">
      <div>
        <!--<div class="d-flex align-center mb-3">-->
        <!--  <v-icon size="23" color="deep-orange accent-4" class="mr-2">mdi-timer-off</v-icon>-->
        <!--  <span>{{ failedCount }} Indkald efter 5 forsøg mislykkedes.</span>-->
        <!--</div>-->
        <div class="d-flex align-center mb-3">
          <span class="color-bilbao status-label mr-2"/> <span>Indkaldt een gang afventer opkald: {{ calledCount }} kunder som er over {{ statusActiveDays }} dage gamle.</span>
        </div>
        <div class="d-flex align-center mb-3">
          <span class="color-lavender status-label mr-2"/> <span>Indkaldt 2. gang - afventer : {{ called2TimesCount }} kunder som er over {{ statusActiveDays }} dage gamle.</span>
        </div>
        <span class="body-1">Sæt i gang - kære Kirurgiklinik Team:-)</span>
      </div>
      <div>
        <v-btn fab icon dark x-small color="grey" @click="hideCallupStats">
          <v-icon size="25" dark>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-alert>
</template>

<script>
  import api from '../../api/api';

  export default {
    name: 'OverviewCallupStats',

    data: function () {
      return {
        callupDialog: false,
        calledCount: 0,
        called2TimesCount: 0,
        failedCount: 0,
        statusActiveDays: 0,
      };
    },

    mounted: async function () {
      if (localStorage.getItem('callupPopup') === 'true') {
        try {
          let callupStats = await api.getCallupStats();
          if (callupStats.data.isShowAlert) {
            this.calledCount = callupStats.data.calledCount;
            this.called2TimesCount = callupStats.data.called2TimesCount;
            this.failedCount = callupStats.data.failedCount;
            this.statusActiveDays = callupStats.data.statusActiveDays;
            this.callupDialog = true;
          }
        } catch (e) {}
      }
    },

    methods: {
      hideCallupStats: function () {
        this.callupDialog = false;
        localStorage.setItem('callupPopup', 'false');
      },
    },
  };
</script>
