export default {
  namespaced: true,
  state: {
    editMode: false,
    visitId: null,
    activeStep: 1,
    availableStep: 1,
    creatorInfo: null,
    modifierInfo: null,
  },
  mutations: {
    changeActiveStep(state, payload) {
      state.activeStep = payload;
    },
    changeAvailableStep(state, payload) {
      state.availableStep = payload;
    },
    changeVisitId(state, payload) {
      state.visitId = payload;
    },
    changeEditMode(state, payload) {
      state.editMode = payload;
    },
    changeCreatorInfo(state, payload) {
      state.creatorInfo = payload;
    },
    changeModifierInfo(state, payload) {
      state.modifierInfo = payload;
    },
  },
};
