<template>
  <div class="intro-section mb-5">
    <v-container fluid class="py-0">
      <v-row class="my-0">
        <v-col cols="12" md="6" class="pt-5 pb-0" style="background-color: #f0f3f6;">
          <div class="text-center mb-2 font-weight-bold">E-MAIL</div>
          <v-row class="my-0" align="center">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="emailVisitNumber" label="Visitations nr." @keyup.enter="setEmailFilterParams"/>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select v-model="emailType" :items="emailTypeList" :disabled="emailTypeLoading" :loading="emailTypeLoading" item-text="item2" item-value="item1" label="E-mail type"/>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col class="pt-5">
              <v-btn depressed class="mr-4 mb-2 mb-md-0" color="primary" :disabled="emailTableLoading || smsTableLoading || emailTypeLoading || smsTypeLoading" @click="setEmailFilterParams">
                <v-icon left size="22" class="mr-4">icon-search</v-icon>
                Søg
              </v-btn>
              <v-btn depressed class="mb-2 mb-md-0" color="primary" outlined :disabled="emailTableLoading || smsTableLoading || emailTypeLoading || smsTypeLoading" @click="clearEmailFilters">
                <v-icon left size="25" class="mr-4">icon-close</v-icon>
                Reset filtre
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="pt-5 pb-0" style="background-color: #e6ebef;">
          <div class="text-center mb-2 font-weight-bold">SMS</div>
          <v-row class="my-0" align="center">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="smsVisitNumber" label="Visitations nr." @keyup.enter="setSmsFilterParams"/>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select v-model="smsType" :items="smsTypeList" :disabled="smsTypeLoading" :loading="smsTypeLoading" item-text="item2" item-value="item1" label="Sms type"/>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col class="py-5 d-flex">
              <v-btn depressed class="ml-auto mr-4 mb-2 mb-md-0" color="primary" outlined :disabled="smsTableLoading || emailTableLoading || emailTypeLoading || smsTypeLoading" @click="clearSmsFilters">
                <v-icon left size="25" class="mr-4">icon-close</v-icon>
                Reset filtre
              </v-btn>
              <v-btn depressed class="mb-2 mb-md-0" color="primary" :disabled="smsTableLoading || emailTableLoading || emailTypeLoading || smsTypeLoading" @click="setSmsFilterParams">
                <v-icon left size="22" class="mr-4">icon-search</v-icon>
                Søg
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { LOG_TYPES } from '@/constants/logTypes';
  import api from '@/api/api';

  export default {
    name: 'LogsFilter',

    data: function () {
      return {
        LOG_TYPES,
        emailTypeLoading: false,
        smsTypeLoading: false,
        emailVisitNumber: '',
        emailType: null,
        smsVisitNumber: '',
        smsType: null,
      };
    },

    mounted: async function () {
      await this.getEmailLogs();
      await this.getSMSLogs();
      this.setEmailFilterParams();
    },

    computed: {
      emailTableLoading: function () {
        return this.$store.state.logs.emailTableLoading;
      },
      smsTableLoading: function () {
        return this.$store.state.logs.smsTableLoading;
      },
      logType: function () {
        return this.$store.state.logs.logType;
      },
      emailTypeList: function () {
        let defaultItem = [
          {
            item1: null,
            item2: 'Alle',
          },
        ];
        return [...defaultItem, ...this.$store.state.logs.emailTypeList];
      },
      smsTypeList: function () {
        let defaultItem = [
          {
            item1: null,
            item2: 'Alle',
          },
        ];
        return [...defaultItem, ...this.$store.state.logs.smsTypeList];
      },
    },

    methods: {
      clearEmailFilters: function () {
        this.emailVisitNumber = '';
        this.emailType = null;
        this.setEmailFilterParams();
      },
      clearSmsFilters: function () {
        this.smsVisitNumber = '';
        this.smsType = null;
        this.setSmsFilterParams();
      },
      emailFilterParams: function () {
        let params = {};
        this.emailVisitNumber && (params.visitNumber = this.emailVisitNumber);
        this.emailType?.toString() && (params.emailType = this.emailType);
        return params;
      },
      smsFilterParams: function () {
        let params = {};
        this.smsVisitNumber && (params.visitNumber = this.smsVisitNumber);
        this.smsType?.toString() && (params.smsType = this.smsType);
        return params;
      },
      setEmailFilterParams: function () {
        if (!this.emailTableLoading) {
          this.$store.commit('logs/updateEmailFilterParams', this.emailFilterParams());
          this.$store.commit('logs/updateLogType', LOG_TYPES.EMAIL);
        }
      },
      setSmsFilterParams: function () {
        if (!this.smsTableLoading) {
          this.$store.commit('logs/updateSmsFilterParams', this.smsFilterParams());
          this.$store.commit('logs/updateLogType', LOG_TYPES.SMS);
        }
      },
      getEmailLogs: async function () {
        try {
          this.emailTypeLoading = true;
          let logsData = await api.getEmailLogs({ CurrentPage: 1, PageSize: 1 });
          this.$store.commit('logs/updateEmailTypeList', logsData.data['emailTypes']);
        } finally {
          this.emailTypeLoading = false;
        }
      },
      getSMSLogs: async function () {
        try {
          this.smsTypeLoading = true;
          let logsData = await api.getSmsLogs({ CurrentPage: 1, PageSize: 1 });
          this.$store.commit('logs/updateSmsTypeList', logsData.data['smsTypes']);
        } finally {
          this.smsTypeLoading = false;
        }
      },
    },
  };
</script>
