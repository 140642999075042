<template>
  <div class="intro-section mb-5">
    <v-container fluid class="mb-2">
      <h2>AFTALETIDSIMPORT</h2>
    </v-container>
    <v-container class="py-5">
      <v-row justify="space-between" class="my-0 py-0 mb-2">
        <v-col cols="12" md="2" class="py-0">
          <v-text-field label="Visitations nr." v-model="visitNumber" @keyup.enter="setFilterParams"/>
        </v-col>
        <v-col cols="7" md="2" class="py-0 pr-0">
          <v-text-field label="CPR-Nummer" v-model="cprNumber" maxlength="6" @keyup.enter="setFilterParams"/>
        </v-col>
        <v-col cols="5" md="2" class="py-0">
          <v-text-field v-model="cprNumberAdd" maxlength="4" @keyup.enter="setFilterParams"/>
        </v-col>
        <v-col class="py-0">
          <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field :value="formattedDateFrom" class="datepicker-input" append-icon="mdi-calendar" @click:append="menuFrom = true" label="Dato" readonly v-on="on"/>
            </template>
            <v-date-picker v-model="dateFrom" no-title @change="menuFrom = false" color="primary lighten-0"/>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="py-0 px-0" align-self="center">
          <v-icon size="15">icon-minus</v-icon>
        </v-col>
        <v-col class="py-0">
          <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field :value="formattedDateTo" class="datepicker-input" append-icon="mdi-calendar" @click:append="menuTo = true" readonly v-on="on"/>
            </template>
            <v-date-picker v-model="dateTo" no-title @change="menuTo = false" color="primary lighten-0"/>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <div class="intro-section__bottom-box py-5">
      <v-row justify="end" class="my-0 mx-0 px-5">
        <v-btn depressed class="mb-2 mr-2 mb-md-0" color="primary" :disabled="tableLoading" outlined @click="clearFilters">
          <v-icon left size="25" class="mr-4">icon-close</v-icon>
          Reset filtre
        </v-btn>
        <v-btn depressed class="ml-2 mb-2 mb-md-0" color="primary" :disabled="tableLoading" @click="setFilterParams">
          <v-icon left size="22" class="mr-4">icon-search</v-icon>
          Søg
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
  import methods from '@/utils/methods';

  export default {
    name: 'ImportFilters',

    data: function () {
      return {
        visitNumber: '',
        cprNumber: '',
        cprNumberAdd: '',
        menuFrom: false,
        dateFrom: null,
        menuTo: false,
        dateTo: null,
      };
    },

    mounted: async function() {
      this.setFilterParams();
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.imports.tableLoading;
      },
      formattedDateFrom: function () {
        if (this.dateFrom) {
          return methods.formatDate(this.dateFrom);
        } else {
          return 'Fra';
        }
      },
      formattedDateTo: function () {
        if (this.dateTo) {
          return methods.formatDate(this.dateTo);
        } else {
          return 'Til';
        }
      },
    },

    methods: {
      clearFilters: function () {
        this.visitNumber = '';
        this.cprNumber = '';
        this.cprNumberAdd = '';
        this.dateFrom = null;
        this.dateTo = null;
        this.setFilterParams();
      },
      filterParams: function () {
        let params = {};
        this.dateFrom && (params.From = new Date(this.dateFrom).toISOString());
        this.dateTo && (params.To = new Date(this.dateTo).toISOString());
        this.visitNumber && (params.VisitId = parseInt(this.visitNumber));
        if (this.cprNumber || this.cprNumberAdd) {
          if (this.cprNumberAdd) {
            params.Cpr = `${this.cprNumber}-${this.cprNumberAdd}`;
          } else {
            params.Cpr = this.cprNumber;
          }
        }
        return params;
      },
      setFilterParams: function () {
        if (!this.tableLoading) {
          this.$store.commit('imports/updateFilterParams', this.filterParams());
        }
      },
    },
  };
</script>
