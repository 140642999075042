<template>
  <div class="intro-section mb-10">
    <v-container fluid class="py-5">
      <h2 class="mb-2">HENVIS ONLINE</h2>
      <p>Lav din henvisning her, på side 1 kan du søge på patienten via cpr. nr. På side 3 har du mulighed for at vedhæfte røntgen-fotos. Under oversigt kan du se status på alle dine henvisninger. Under din profil kan du ændre dit password.</p>
      <v-row class="py-0 my-0" justify="space-between" align="center">
        <v-col cols="12" lg="auto" class="py-0 my-2"><strong>Oplever du problemer så skriv til <a href="mailto:support@kirurgiklinik.dk">support@kirurgiklinik.dk</a> <br>eller ring til 44 12 70 96 og vi hjælper dig gerne.</strong></v-col>
      </v-row>
    </v-container>
    <div class="intro-section__bottom-box" v-if="!visitComplited">
      <v-container class="py-5" fluid>
        <v-row justify="space-between" align="center" class="py-0 my-0">
          <v-col cols="12" sm="4" class="py-0">
            <step-button :stepValue="1" :active="activeStep === 1">1
            </step-button>
            <step-button :stepValue="2" :active="activeStep === 2" :disabled="availableStep < 2">2
            </step-button>
            <step-button :stepValue="3" :active="activeStep === 3" :disabled="availableStep < 3">3
            </step-button>
          </v-col>
          <v-col cols="12" sm="auto" class="ml-sm-auto mt-4 mt-sm-0 py-0">
            <span class="blue-grey--text font-weight-bold">TRIN {{ activeStep }}</span> : {{ stepName }}
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import StepButton from './StepButton';

  export default {
    name: 'StepsHeading',

    components: {StepButton},

    computed: {
      activeStep: function () {
        return this.$store.state.visit.activeStep;
      },
      availableStep: function () {
        return this.$store.state.visit.availableStep;
      },
      stepName: function () {
        switch (this.activeStep) {
          case 1:
            return 'PATIENT INFORMATION';
          case 2:
            return 'HENVISER INFORMATION';
          case 3:
            return 'RØNTGENBILLEDER';
        }
      },
      visitComplited: function () {
        return this.$store.state.visit.visitComplited;
      },
    },
  };
</script>
