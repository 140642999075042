<template>
  <div class="intro-section__bottom-box">
    <v-container fluid class="py-5">
      <v-row align="center" class="my-0 py-0">
        <v-col cols="12" sm="auto" class="py-0">
          <step-button :stepValue="1" :active="activeStep === 1">1</step-button>
          <step-button :stepValue="2" :active="activeStep === 2" :disabled="availableStep < 2">2</step-button>
          <step-button :stepValue="3" :active="activeStep === 3" :disabled="availableStep < 3">3</step-button>
        </v-col>
        <v-col cols="12" sm="auto" class="mt-4 mt-sm-0 py-0">
          <span class="blue-grey--text font-weight-bold">TRIN {{ activeStep }}</span>
          : {{ stepName }}
        </v-col>
        <v-col cols="12" md="auto" class="ml-md-auto">
          <v-btn v-if="activeStep !== 1" depressed color="primary" class="mr-2" href="#step-heading" @click="prevStep">
            <v-icon left size="22" class="mr-4">icon-chevron-left</v-icon>
            Tilbage
          </v-btn>
          <v-btn depressed color="#233241" @click="nextStep" :loading="submitLoading" :disabled="submitLoading">
            <span class="white--text">{{ activeStep < 3 ? 'Næste' : 'Send henvisning' }}</span>
            <v-icon v-if="!editMode" color="white" right size="25" class="ml-4">mdi-chevron-right</v-icon>
            <v-icon v-if="editMode" color="white" right size="20" class="ml-4">mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import StepButton from './StepButton';

  export default {
    name: 'NavigationBlock',

    components: {StepButton},

    props: {
      submitLoading: {
        type: Boolean,
      },
    },

    methods: {
      nextStep: function () {
        this.$emit('validateInit');
      },
      prevStep: function () {
        this.$store.commit('visit/changeActiveStep', this.activeStep - 1);
      },
    },

    computed: {
      editMode: function () {
        return this.$store.state.visit.editMode;
      },
      activeStep: function () {
        return this.$store.state.visit.activeStep;
      },
      availableStep: function () {
        return this.$store.state.visit.availableStep;
      },
      stepName: function () {
        switch (this.activeStep) {
          case 1:
            return 'PATIENT INFORMATION';
          case 2:
            return 'HENVISER INFORMATION';
          case 3:
            return 'RØNTGENBILLEDER';
        }
      },
    },
  };
</script>