<template>
  <div>
    <v-progress-circular v-if="exportLoading" :size="18" :width="2" indeterminate color="grey"/>
    <v-tooltip v-else bottom :allow-overflow="true">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" size="23" @click="exportVisits(visitItem.id)" :class="iconColor">icon-download</v-icon>
      </template>
      <span>Exporter valgte kunder</span>
    </v-tooltip>
  </div>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'OverviewExportIcon',

    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        exportLoading: false,
      };
    },

    computed: {
      iconColor: function () {
        switch (this.visitItem.exportedToCsv) {
          case false:
            return 'grey--text text--darken-2';
          case true:
            return 'teal--text';
        }
      },
    },

    methods: {
      exportVisits: async function (visitId) {
        try {
          let queryData = {};
          queryData.ids = [visitId];
          this.exportLoading = true;
          let data = await api.exportVisitsById(queryData);
          let newVisit = await api.getVisits({VisitNumber: visitId});
          this.$emit('updateCommonVisitInfo', newVisit.data.paginationResult.entities[0]);
          methods.downloadBlob(URL.createObjectURL(methods.encodeBlob(data.data)), methods.getHeaderParameter('filename', data.headers['content-disposition']));
          this.exportLoading = false;
        } catch (e) {
          this.exportLoading = false;
        }
      },
    },
  };
</script>