<template>
  <v-col cols="12" class="py-0">
    <v-row justify="space-between" class="my-0 mb-5">
      <v-col cols="12" lg="2" class="py-0 mb-4 mb-lg-0">
        <div :class="[captionClasses]">Udskrivningsbrev</div>
        <v-chip v-if="letter.diagnosFileName" @click="downloadDiagnosis">
          <span class="text-truncate" style="max-width: 220px">{{ letter.diagnosFileName }}</span>
        </v-chip>
        <div v-else class="text-body-1 grey--text">Ingen data...</div>
      </v-col>
      <v-col cols="12" lg="2" class="py-0 mb-4 mb-lg-0">
        <div :class="[captionClasses]">Røntgen / fotos</div>
        <v-chip v-if="letter.rentgenFileName" @click="downloadRentgen">
          <span class="text-truncate" style="max-width: 220px">{{ letter.rentgenFileName }}</span>
        </v-chip>
        <div v-else class="text-body-1 grey--text">Ingen data...</div>
      </v-col>
      <v-col cols="12" lg="2" class="py-0 mb-4 mb-lg-0">
        <div :class="[captionClasses]">Send dato</div>
        <span v-if='letter.sendDate'>{{letterSentDate}}</span>
        <div v-else class="text-body-1 grey--text">Ingen data...</div>
      </v-col>
      <v-col v-if="isAdmin" cols="12" lg="6" class="py-0 align-self-end">
        <v-btn depressed :color="sendSmsButtonColor" :loading="sendSmsLoading" :disabled="sendSmsLoading || !letter.rentgenFileName || !visitItem.patientMobilePhone" @click="sendSms">Send Røntgen til patienten</v-btn>
        <div class='overview-data__note ma-2'>
          {{smsSentDate}}
          <span v-if="letter['smsRadiographStatus'] === 0"> Status: Afventer i kø</span>
          <span v-if="letter['smsRadiographStatus'] === 2"> Status: fejlet, prøv evt. igen</span>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';
  import HeadingBox from '../common/HeadingBox';
  import {COMMON_TYPES} from '@/constants/commonTypes';

  export default {
    name: 'OverviewLettersRow',

    components: {HeadingBox},

    data: function () {
      return {
        sendSmsButtonColor: 'primary',
        sendSmsLoading: false,
      };
    },

    props: {
      visitItem: {
        type: Object,
      },
      letter: {
        type: Object,
      },
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      captionClasses: function () {
        return [...COMMON_TYPES.CAPTION_CLASSES]
      },
      letterSentDate: function () {
        return methods.getSimpleDate(this.letter.sendDate);
      },
      smsSentDate: function () {
        return this.letter.smsRadiographStatusDate ? `Sendt: ${methods.getSimpleDate(this.letter.smsRadiographStatusDate)}` : '';
      },
    },

    methods: {
      downloadDiagnosis: function () {
        methods.downloadURL(api.diagnosisFileUrl(this.visitItem.id, this.letter.letterNumber));
      },
      downloadRentgen: function () {
        methods.downloadURL(api.rentgenFileUrl(this.visitItem.id, this.letter.letterNumber));
      },
      sendSms: async function() {
        try {
          this.sendSmsLoading = true;
          this.sendSmsButtonColor = 'primary';
          await api.sendLetterSmsWithLink(this.visitItem.id, this.letter.letterNumber);
          let newVisit = await api.getVisitInfo(this.visitItem.id);
          this.sendSmsButtonColor = 'success';
          this.sendSmsLoading = false;
          this.$emit('updateVisitItem', newVisit.data);
        } catch (e) {
          this.sendSmsButtonColor = 'error';
          this.sendSmsLoading = false;
        }
      }
    },
  };
</script>
