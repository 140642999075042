<template>
  <v-col cols="auto" class="py-0">
    <v-btn class="mr-5" depressed color="primary" @click="openCreateDialog">Opret ny henviser</v-btn>
    <v-dialog v-if="createDialog" v-model="createDialog" scrollable width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closeCreateDialog">
      <v-card>
        <v-card-title>
          <v-row justify="center">
            <v-col cols="auto">
              <div>Ny Henviser</div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider class="mb-5"/>
        <v-card-text class="py-4">
          <v-form ref="createForm" v-model="formValid">
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="firstName" label="Fornavn *" :rules="[rules.required]" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="lastName" label="Efternavn *" :rules="[rules.required]" filled/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" :disabled="createLoading" :loading="createLoading" @click="createUser">Opret</v-btn>
          <v-btn color="black darken-1" text :disabled="createLoading" @click="closeCreateDialog">Annuller</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
  import validationRules from '../../utils/validationRules';
  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'QuickUserCreateDialog',

    props: {
      queryParams: {
        type: Object,
      },
      filterParams: {
        type: Object,
      },
    },

    data: function() {
      return {
        rules: {...validationRules},
        formValid: false,
        createDialog: false,
        firstName: '',
        lastName: '',
        createLoading: false
      }
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      }
    },

    methods: {
      openCreateDialog: function() {
        this.createDialog = true;
      },
      closeCreateDialog: function() {
        this.createDialog = false;
        this.resetForm();
      },
      resetForm: function() {
        this.firstName = '';
        this.lastName = '';
      },
      createUser: async function() {
        this.$refs.createForm.validate();
        if (!this.formValid) return;
        let queryBody = {};
        queryBody.firstName = this.firstName;
        queryBody.lastName = this.lastName;
        try {
          this.createLoading = true;
          let quickProfileData = await api.createQuickProfile(queryBody);
          await this.$store.dispatch('getDoctors');
          this.createLoading = false;
          this.$emit('setSelectedDoctor', quickProfileData.data);
          this.closeCreateDialog();
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          this.createLoading = false;
        }
      }
    },
  };
</script>
