<template>
  <v-container fluid class="py-6">
    <v-row align="center" justify="space-between" class="my-0">
      <v-col cols="12" class="py-0 mb-4">
        <span>Eksport af alle kunder:</span>
      </v-col>
      <v-col cols="12" lg="4" class="py-0 mb-6 mb-lg-0">
        <v-select class="my-0 py-0" :items="typesList" item-text="text" item-value="val" label="Patient data" v-model="infoType" @change="getLastExportedDate" hide-details>
          <template v-slot:item="{item, on, attrs}">
            <v-list-item v-on="on" v-bind="attrs" :style="[!item.active && { opacity:0.5 }]">{{ item.text }}</v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col class="py-0">
        <v-row class="my-0 py-0">
          <v-col cols="12" md="6" class="py-0 mb-6 mb-md-0">
            <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :value="formattedDateFrom" label="Dato Fra" :loading="dateLoading" loader-height="1" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuFrom = true" readonly hide-details v-on="on"/>
              </template>
              <v-date-picker :min="minDateFrom" :max="dateTo" v-model="dateFrom" no-title @change="menuFrom = false" color="primary lighten-0"/>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" class="py-0 mb-6 mb-md-0">
            <v-menu ref="timeMenuFrom" v-model="timeMenuFrom" :close-on-content-click="false" :return-value.sync="timeFrom" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="timeFrom" label="Tid Fra" class="datepicker-input my-0 py-0" readonly hide-details clearable v-bind="attrs" v-on="on"/>
              </template>
              <v-time-picker v-if="timeMenuFrom" v-model="timeFrom" format="24hr" full-width @click:minute="$refs.timeMenuFrom.save(timeFrom)"/>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="py-0 px-0 mb-4 mb-md-0">
        <v-icon size="15">icon-minus</v-icon>
      </v-col>
      <v-col class="py-0">
        <v-row class="my-0 py-0">
          <v-col cols="12" md="6" class="py-0 mb-6 mb-md-0">
            <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field :value="formattedDateTo" label="Dato Til" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuTo = true" readonly hide-details v-on="on"/>
              </template>
              <v-date-picker :min="dateFrom" :max="maxDateTo" v-model="dateTo" no-title @change="menuTo = false" color="primary lighten-0"/>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" class="py-0 mb-6 mb-md-0">
            <v-menu ref="timeMenuTo" v-model="timeMenuTo" :close-on-content-click="false" :return-value.sync="timeTo" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="timeTo" label="Tid Til" class="datepicker-input my-0 py-0" readonly hide-details clearable v-bind="attrs" v-on="on"/>
              </template>
              <v-time-picker v-if="timeMenuTo" v-model="timeTo" format="24hr" full-width @click:minute="$refs.timeMenuTo.save(timeTo)"/>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="auto" class="py-0">
        <v-btn depressed :color="buttonColor" outlined :loading="loading" :disabled="loading" @click="downloadCsv">
          <v-icon left size="22">icon-download</v-icon>
          CSV
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'OverviewExportAll',

    data: function () {
      return {
        dateLoading: false,
        menuFrom: false,
        dateFrom: null,
        timeMenuFrom: false,
        timeFrom: null,
        menuTo: false,
        dateTo: null,
        timeMenuTo: false,
        timeTo: null,
        typesList: [
          {
            text: 'Patient data inkl. henvisning, (NY)',
            val: 4,
            active: true,
          },
          {
            text: 'Patientskema, (gammel)',
            val: 3,
            active: false,
          },
        ],
        infoType: 4,
        loading: false,
        buttonColor: 'primary',
      };
    },

    mounted: function () {
      this.getLastExportedDate();
    },

    computed: {
      minDateFrom: function () {
        if (this.dateTo) {
          let [year, month, day] = this.dateTo.split('-');
          return `${parseInt(year) - 1}-${month}-${day}`;
        } else {
          return null;
        }
      },
      maxDateTo: function () {
        if (this.dateFrom) {
          let [year, month, day] = this.dateFrom.split('-');
          return `${parseInt(year) + 1}-${month}-${day}`;
        } else {
          return null;
        }
      },
      formattedDateFrom: function () {
        if (this.dateFrom) {
          return methods.formatDate(this.dateFrom);
        } else {
          return '';
        }
      },
      formattedDateTo: function () {
        if (this.dateTo) {
          return methods.formatDate(this.dateTo);
        } else {
          return '';
        }
      },
    },

    methods: {
      downloadCsv: async function () {
        try {
          let params = {};
          this.dateFrom && (params.StartDate = new Date(`${this.dateFrom}T${this.timeFrom ? this.timeFrom : '00:00'}`).toISOString());
          this.dateTo && (params.EndDate = new Date(`${this.dateTo}T${this.timeTo ? this.timeTo : '00:00'}`).toISOString());
          params.ExportType = this.infoType;
          this.loading = true;
          let queryData = await api.downloadCsvByDates(params);
          if (queryData.status === 204) {
            methods.showGlobalSnackbar('grey', 'Ingen data...');
          } else {
            await this.getLastExportedDate();
            this.dateTo = null;
            this.timeTo = null;
            methods.downloadURL(queryData.request.responseURL);
          }
          this.loading = false;
        } catch (e) {
          this.loading = false;
          this.buttonColor = 'error';
        }
      },
      getLastExportedDate: async function () {
        this.dateLoading = true;
        let {data} = await api.getLastExpotedDate(this.infoType);
        this.dateLoading = false;
        if (!data.dateTime) return;
        let [simpleDay, simpleMonth, simpleYear] = methods.getDateFromIso(data.dateTime).split('.');
        this.dateFrom = `${simpleYear}-${simpleMonth}-${simpleDay}`;
        this.timeFrom = methods.getTimeFromIso(data.dateTime);
      },
    },
  };
</script>
