<template>
  <div>
    <v-container fluid class="mb-4">
      <p class="mb-0">Indtast henviser information.</p>
    </v-container>
    <v-form ref="visitForm" v-model="formValid" class="blue-grey lighten-5 pb-7" style="position: relative">
      <v-progress-linear indeterminate height="1" :active="getVisitLoading" :absolute="true" style="top:0"/>
      <v-container fluid v-if="isAdmin">
        <span class="mr-5"><strong>Oprettet af:</strong> {{ creatorInfo ? `${creatorInfo.creatorName}, ${creatorInfo.creatorClinic}, ${creatorInfo.creationTime}` : '...' }}</span> <span><strong>Sidst editeret af:</strong> {{ modifierInfo ? `${modifierInfo.modifierName}, ${modifierInfo.modifierClinic}, ${modifierInfo.modifyTime}` : '...' }}</span>
      </v-container>
      <v-container fluid>
        <template v-if="isAdmin || isSecretary">
          <v-row align="center" class="my-0 mb-5">
            <v-col cols="12" md="6" class="py-0">
              <v-autocomplete :items="doctors" item-text="name" label="Henviser" v-model="chosenDoctor" return-object :rules="[rules.requiredId]" @change="getClinicData($event); setDoctorData($event)"/>
            </v-col>
            <QuickUserCreateDialog v-if="isSecretary" @setSelectedDoctor="setSelectedDoctor($event)"/>
          </v-row>
        </template>
        <v-row class="my-0">
          <v-col cols="12" md="6" class="py-0">
            <div class="pa-5" style="background: #0000000f; border-radius: 5px; border: 1px solid #ccc; position: relative">
              <v-progress-linear indeterminate height="1" :active="getClinicLoading" :absolute="true" style="top:0"/>
              <div class="text-h6 text-center">Klinik</div>
              <div class="mb-5">
                <div :class="[captionClasses]">Klinik</div>
                <div v-if="clinicName" class="text-body-1">{{clinicName}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
              <div class="mb-5">
                <div :class="[captionClasses]">Addresse</div>
                <div v-if="clinicAddress" class="text-body-1">{{clinicAddress}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
              <div class="mb-5">
                <div :class="[captionClasses]">City</div>
                <div v-if="clinicCity" class="text-body-1">{{clinicCity}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
              <div class="mb-5">
                <div :class="[captionClasses]">Postrn</div>
                <div v-if="clinicZip" class="text-body-1">{{clinicZip}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
              <div class="mb-5">
                <div :class="[captionClasses]">Telefon</div>
                <div v-if="clinicPhone" class="text-body-1">{{clinicPhone}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
              <div class="mb-5">
                <div :class="[captionClasses]">Fax</div>
                <div v-if="clinicFax" class="text-body-1">{{clinicFax}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <div class="pa-5" style="background: #0000000f; border-radius: 5px; border: 1px solid #ccc; position: relative">
              <div class="text-h6 text-center">Henviser</div>
              <div class="mb-5">
                <div :class="[captionClasses]">Henvisernavn</div>
                <div v-if="doctorName" class="text-body-1">{{doctorName}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
              <div class="mb-5">
                <div :class="[captionClasses]">Email</div>
                <div v-if="doctorEmail" class="text-body-1">{{doctorEmail}}</div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-0">
          <v-col cols="auto" class="py-0">
            <v-checkbox hide-details v-model="sendNotification" color="primary" label="Ja, tak send venligst en notifikation når status i sagen ændres"/>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <NavigationBlock @validateInit="validate" :submitLoading="submitLoading"/>
  </div>
</template>

<script>
  import NavigationBlock from './NavigationBlock';
  import validationRules from '../../utils/validationRules';
  import api from '../../api/api';
  import {COMMON_TYPES} from '@/constants/commonTypes';
  import QuickUserCreateDialog from './QuickUserCreateDialog';
  import methods from '@/utils/methods';

  export default {
    name: 'VisitForm',

    components: {QuickUserCreateDialog, NavigationBlock},

    data: function () {
      return {
        captionClasses: [...COMMON_TYPES.CAPTION_CLASSES],
        rules: {...validationRules},
        getVisitLoading: false,
        getClinicLoading: false,
        submitLoading: false,
        formValid: false,
        clinic: '',
        chosenDoctor: null,
        doctorName: '',
        doctorEmail: '',
        clinicName: '',
        clinicAddress: '',
        clinicCity: '',
        clinicPhone: '',
        clinicFax: '',
        clinicZip: '',
        sendNotification: false,
      };
    },

    computed: {
      visitId: function () {
        return this.$store.state.visit.visitId;
      },
      editMode: function () {
        return this.$store.state.visit.editMode;
      },
      availableStep: function () {
        return this.$store.state.visit.availableStep;
      },
      activeStep: function () {
        return this.$store.state.visit.activeStep;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      isDentist: function () {
        return this.$store.getters.isDentist;
      },
      userData: function() {
        return this.$store.state.userData;
      },
      doctors: function() {
        return this.$store.state.doctors;
      },
      creatorInfo: function () {
        return this.$store.state.visit.creatorInfo;
      },
      modifierInfo: function () {
        return this.$store.state.visit.modifierInfo;
      },
    },

    watch: {
      activeStep: async function(val) {
        if (val !== 2) return;
        if (this.isSecretary) {
          this.chosenDoctor = this.doctors.filter(doctor => doctor.id === this.userData.id)[0];
          this.setDoctorData(this.userData)
          this.getClinicData(this.userData);
        }
        if (this.isDentist) {
          this.setDoctorData(this.userData)
          this.getClinicData(this.userData);
        }
        if (this.isAdmin) {
          try {
            this.getVisitLoading = true;
            let visitData = await api.getVisitById(this.visitId);
            let doctorData = this.doctors.filter(doctor => doctor.id === visitData.data.doctorId)[0];
            this.getVisitLoading = false;
            if (doctorData) {
              this.chosenDoctor = doctorData;
              this.setDoctorData(doctorData)
              this.getClinicData(doctorData);
            }
          } catch (e) {
            this.getVisitLoading = true;
          }
        }
      }
    },

    methods: {
      validate: function () {
        this.$refs.visitForm.validate();
        if (this.formValid) {
          this.sendInfo();
        } else {
          methods.showGlobalSnackbar('error', 'Der er felter som mangler at blive udfyldt, tjek venligst og udfyld disse.');
        }
      },
      clearClinicData: function() {
        this.clinicName = '';
        this.clinicAddress = '';
        this.clinicCity = '';
        this.clinicPhone = '';
        this.clinicFax = '';
        this.clinicZip = '';
      },
      getClinicData: async function(doctorData) {
        this.clearClinicData();
        try {
          this.getClinicLoading = true;
          let clinicData = await api.getSingleActiveClinic(doctorData.clinicId);
          this.getClinicLoading = false;
          clinicData.data.name && (this.clinicName = clinicData.data.name);
          clinicData.data.address && (this.clinicAddress = clinicData.data.address);
          clinicData.data.city && (this.clinicCity = clinicData.data.city);
          clinicData.data.phone && (this.clinicPhone = clinicData.data.phone);
          clinicData.data.fax && (this.clinicFax = clinicData.data.fax);
          clinicData.data.zip && (this.clinicZip = clinicData.data.zip);
        } catch (e) {
          this.getClinicLoading = true;
        }
      },
      setSelectedDoctor: function(newDoctorId) {
        let doctorData = this.doctors.filter(doctor => doctor.id === newDoctorId)[0];
        if (doctorData) {
          this.chosenDoctor = doctorData;
          this.setDoctorData(doctorData)
          this.getClinicData(doctorData);
        }
      },
      setDoctorData: function(doctorData) {
        this.doctorName = `${doctorData.firstName ? doctorData.firstName : ''} ${doctorData.lastName ? doctorData.lastName : ''}`;
        doctorData.email && (this.doctorEmail = doctorData.email);
      },
      sendInfo: async function () {
        let queryBody = {};
        queryBody.sendNotificationToDoctor = this.sendNotification;
        queryBody.doctorId = this.isDentist ? this.userData.id : this.chosenDoctor.id;
        try {
          this.submitLoading = true;
          await api.sendDoctorInfo(queryBody, this.visitId);
          this.submitLoading = false;
          this.availableStep < 3 && this.$store.commit('visit/changeAvailableStep', this.availableStep + 1);
          this.$store.commit('visit/changeActiveStep', 3);
          this.$parent.$refs.anchor.$el.scrollIntoView();
        } catch (e) {
          this.submitLoading = false;
          if (e.response.status === 417) {
            methods.showGlobalSnackbar('error', 'Forbudt at tildele besøg på en anden klinik.');
          } else {
            methods.showGlobalSnackbar('error', 'Server error. Noget gik galt. Prøv igen senere.');
          }
        }
      },
    },
  };
</script>
