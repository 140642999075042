<template>
  <div class="data-section ending-letter-data">
    <v-data-table class="data-table" :headers="tableHeaders" :items="visits" :single-expand="false" :expanded.sync="expanded" :loading-text="loadingText" loader-height="2" :loading="tableLoading" :item-key="itemKey" show-expand hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalEntities" :sort-by="defaultSortBy" :sort-desc="defaultSortDesc" @update:options="serverSort">
      <template v-slot:item.terminationEmailSendDate="{ item }">
        <DateCell :date="item.terminationEmailSendDate"/>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="d-flex align-center">
          <template v-if="item.externalId">
            <span class="text-no-wrap">{{ item.id }}</span>
            <v-tooltip bottom :allow-overflow="true">
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="21" class="ml-2" color="#48aa7b" v-bind="attrs" v-on="on">icon-edi</v-icon>
              </template>
              <span>EDI: {{ item.externalId }}</span>
            </v-tooltip>
          </template>
          <span v-else>{{ item.id }}</span>
        </div>
      </template>
      <template v-slot:item.isPDFData="{ item }">
        <span v-if="item.terminationLetters.length > 0 && item.terminationLetters[0].diagnosFileName" class="ml-7">
            <v-icon size="27" class="grey--text text--darken-2" @click="downloadDiagnosis(item.id, item.terminationLetters[0].letterNumber)">icon-pdf</v-icon>
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <EndingLettersExpanded :visitItem="item" @updateVisitItem="updateVisitItem(item, $event)" @clearLetter="clearLetter(item, $event)"/>
        </td>
      </template>
    </v-data-table>
    <v-pagination color="#e0e6ec" v-model="queryParams.PageNumber" :length="pageCount" :total-visible="7" :disabled="tableLoading" class="py-4" @input="changePageNumber"/>
  </div>
</template>

<script>
  import ResendEmailButton from './ResendEmailButton';
  import UploadDocumentsButton from './UploadDocumentsButton';
  import api from '../../api/api';
  import methods from '../../utils/methods';
  import DateCell from '../common/DateCell';
  import EndingLettersExpanded from './EndingLettersExpanded';

  export default {
    name: 'TrackingData',

    components: {
      EndingLettersExpanded,
      DateCell,
      ResendEmailButton,
      UploadDocumentsButton,
    },

    data: function () {
      return {
        loadingText: 'Loading... Please wait',
        expanded: [],
        defaultSortBy: 'terminationEmailSendDate',
        defaultSortDesc: true,
        itemKey: 'id',
        totalEntities: 0,
        pageCount: 0,
        queryParams: {
          OrderBy: 0,
          OrderByDesc: false,
          PageNumber: 1,
          PageSize: 20,
        },
        tableHeaders: [
          {text: 'Dato', value: 'terminationEmailSendDate', width: '11%'},
          {text: 'Visit nr.', value: 'id', width: '9%'},
          {text: 'CPR-nummer', value: 'cpr', width: '13%'},
          {text: 'Patient navn', value: 'patientName', width: '20%'},
          {text: 'Henviser e-mail', value: 'doctorEmail', width: '26%', sortable: false},
          {text: 'Udskrivningsbrev', value: 'isPDFData', width: '170px', sortable: false},
          {value: 'data-table-expand', width: '60px', sortable: false},
        ],
        visits: [],
      };
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.endingLetters.tableLoading;
      },
      filterParams: function () {
        return this.$store.state.endingLetters.filterParams;
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.PageNumber = 1;
        this.updateData();
      },
    },

    methods: {
      sortOrderValue: function (val) {
        switch (val.sortBy[0]) {
          case 'terminationEmailSendDate':
            return 1;
          case 'id':
            return 2;
          case 'cpr':
            return 3;
          case 'patientName':
            return 4;
          default:
            return 0;
        }
      },
      serverSort: function (val) {
        this.queryParams.OrderBy = this.sortOrderValue(val);
        this.queryParams.OrderByDesc = val.sortDesc[0] ? val.sortDesc[0] : false;
        this.updateData();
      },
      updateData: async function () {
        try {
          this.visits = [];
          this.$store.commit('endingLetters/updateTableLoading', true);
          let {data} = await api.getTerminationLetters({...this.queryParams, ...this.filterParams});
          this.pageCount = Math.ceil(
              data.totalCount / this.queryParams.PageSize,
          );
          this.totalEntities = data.totalCount;
          this.visits = data.visits;
          this.$store.commit('endingLetters/updateTableLoading', false);
        } catch (e) {
          this.visits = [];
          this.$store.commit('endingLetters/updateTableLoading', false);
        }
      },
      downloadDiagnosis: function (idValue, number) {
        methods.downloadURL(api.diagnosisFileUrl(idValue, number));
      },
      changePageNumber: function (value) {
        this.queryParams.PageNumber = value;
        this.updateData();
      },
      updateVisitItem: function(visitItem, newVisitItem) {
        visitItem.modifyDate = newVisitItem.modifyDate;
        visitItem.terminationLetters = newVisitItem.terminationLetters;
      },
      clearLetter: function(visitItem, letterNumber) {
        let checkIndex = visitItem.terminationLetters.findIndex(item => item.letterNumber === letterNumber);
        visitItem.terminationLetters.splice(checkIndex, 1);
      },
    },
  };
</script>
