<template>
  <div class="data-section overview-data">
    <v-data-table class="data-table" :headers="headers" :items="visits" v-model="selected" show-select :expanded.sync="expanded" show-expand item-key="id" :item-style="isEmptyDentistStyle" :loading-text="loadingText" loader-height="2" :loading="tableLoading" hide-default-footer :mobile-breakpoint="1160" :server-items-length="totalEntities" :sort-by="defaultOrderBy" :sort-desc="defaultOrderByDesc" @update:options="serverSort">
      <template v-slot:item.patientName="{ item }">
        <span>{{ item.patientFirstName ? item.patientFirstName.trim() : '..' }}</span>
        <span>{{ item.patientLastName ? ` ${item.patientLastName.trim()}` : ' ..' }}</span>
      </template>
      <template v-slot:item.patientAge="{ item }">
        <span>{{ item.patientAge ? item.patientAge : '--' }}</span>
      </template>
      <template v-slot:item.createDate="{ item }">
        <DateCell :date="item.createDate"/>
      </template>
      <template v-slot:item.data-table-select="{ item }">
        <v-checkbox class="my-0 py-0" v-model="selected" :value="item" hide-details/>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="d-flex align-center">
          <template v-if="item.externalId">
            <span class="text-no-wrap">{{ item.id }}</span>
            <v-tooltip bottom :allow-overflow="true">
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="21" class="ml-2" color="#48aa7b" v-bind="attrs" v-on="on">icon-edi</v-icon>
              </template>
              <span>EDI: {{ item.externalId }}</span>
            </v-tooltip>
          </template>
          <span v-else>{{ item.id }}</span>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-select class="my-0 py-0 overview-data__clear-select" v-model="item.status" @change="resetUpdateButton(item); isFinished(item)" :items="statusTypes" item-text="label" item-value="key" single-line hide-details :append-icon="selectIcon" :readonly="isDentist || isSecretary">
          <template v-slot:selection="{ item, index }">
            <span :class="[item.color, 'status-label', 'mr-2']"/>
            <span class="text-body-2 text-truncate d-block" style="max-width: 130px">{{ item.label }}</span>
          </template>
          <template v-slot:item="{ item, index }">
            <span :class="[item.color, 'status-label', 'mr-2']"/> <span class="text-body-2">{{ item.label }}</span>
          </template>
        </v-select>
      </template>
      <template v-slot:item.save="{ item }">
        <v-btn v-if="!item.isExpandedRow" depressed class="mb-2 mb-md-0 px-2" min-width="0" :color="item.updateButton.color" :loading="item.updateButton.loading" :disabled="item.updateButton.loading || isDentist || isSecretary" @click="updateVisitStatus(item)">GEM</v-btn>
      </template>
      <template v-slot:item.isExport="{ item }">
        <OverviewExportIcon :visitItem="item" @updateCommonVisitInfo="editCommonVisitInfo(item, $event)" />
      </template>
      <template v-slot:item.haveUnansweredMessage="{ item }">
        <v-icon v-if="item.haveUnansweredMessage" size="23" class="red--text text--lighten-2">mdi-message-processing</v-icon>
      </template>
      <template v-slot:item.referenceType="{ item }">
        <v-tooltip bottom :allow-overflow="true">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-if="item.referenceType === 1" v-bind="attrs" v-on="on" size="20" class="red--text">icon-high-priority</v-icon>
          </template>
          <span>Haste henvisning</span>
        </v-tooltip>
      </template>
      <template v-slot:item.radiographFiles="{ item }">
        <OverviewRadiographsIcon v-if="item.isRadiographUploaded" :visitItem="item" @changeExportStatus="changeExportStatus(item)"/>
      </template>
      <template v-slot:item.isPdfFile="{ item }">
        <span v-if="item.isPdfFile">
          <v-tooltip bottom :allow-overflow="true">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="downloadVisitPdf(item.id)" size="27" class="grey--text text--darken-2">icon-pdf</v-icon>
            </template>
            <span>Download henvisningen som PDF her</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-icon size="24" :class="[isExpanded && 'v-data-table__expand-icon--active']" @click="toggleExpand(item, expand, isExpanded)">icon-chevron-down</v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="height: auto;" class="px-0">
          <OverviewExpanded :commonVisitInfo="item" @collapseVisit="collapseVisit(item, $event)" @updateCommonVisitInfo="editCommonVisitInfo(item, $event)"/>
        </td>
      </template>
    </v-data-table>
    <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :total-visible="7" :disabled="tableLoading" class="py-4" @input="changePageNumber"/>
    <template v-if="isAdmin">
      <v-divider/>
      <OverviewMassStatus :selectedVisits="selected" @massStatusUpdate="massStatusUpdate" @massCsvExportUpdate="massCsvExportUpdate"/>
      <v-divider/>
      <OverviewExportAll/>
      <v-divider/>
      <OverviewExportByDate @updateVisits="getVisits"/>
      <v-divider/>
      <OverviewImportEDIVisits/>
    </template>
    <v-dialog v-model="dialog" persistent max-width="500" overlay-color="#fff" :overlay-opacity="0.7">
      <v-card>
        <v-card-title class="headline">Do you want to delete attached files?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="isDeleteAttachmentsChange(false)">Disagree</v-btn>
          <v-btn color="green darken-1" text @click="isDeleteAttachmentsChange(true)">Agree</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import methods from '../../utils/methods';
  import HeadingBox from '../common/HeadingBox';
  import OverviewExportAll from './OverviewExportAll';
  import api from '../../api/api';
  import OverviewExpanded from './OverviewExpanded';
  import OverviewMassStatus from './OverviewMassStatus';
  import OverviewExportByDate from './OverviewExportByDate';
  import OverviewRadiographsIcon from './OverviewRadiographsIcon';
  import DateCell from '../common/DateCell';
  import OverviewExportIcon from '@/components/overview/OverviewExportIcon';
  import CsvExport from '@/components/common/CsvExport';
  import OverviewImportEDIVisits from '@/components/overview/OverviewImportEDIVisits.vue';

  export default {
    name: 'OverviewData',

    components: {
      OverviewImportEDIVisits,
      CsvExport,
      OverviewExportIcon,
      DateCell,
      OverviewRadiographsIcon,
      OverviewExportByDate,
      OverviewMassStatus,
      OverviewExpanded,
      OverviewExportAll,
      HeadingBox
    },


    data: function () {
      return {
        visits: [],
        selected: [],
        expanded: [],
        loadingText: 'Loading... Please wait',
        dialog: false,
        dialogVisitId: null,
        defaultOrderBy: 'createDate',
        defaultOrderByDesc: true,
        totalEntities: 0,
        totalPages: 0,
        queryParams: {
          OrderBy: null,
          OrderByAscending: null,
          CurrentPage: 1,
          PageSize: 20,
        },
      };
    },

    computed: {
      role: function () {
        return this.$store.state.role;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      isDentist: function () {
        return this.$store.getters.isDentist;
      },
      headers: function () {
        if (this.isAdmin) {
          return [
            {value: 'data-table-select', sortable: false, width: '5%'},
            {text: 'Nr.', value: 'id', sortable: true, width: '9%'},
            {text: 'CPR-nummer', value: 'cpr', width: '12%'},
            {text: 'Patient', value: 'patientName', width: '12%'},
            {text: 'Alder', value: 'patientAge', width: '7%'},
            {text: 'Dato', value: 'createDate', width: '9%'},
            {text: 'Status', value: 'status', width: '200px'},
            {value: 'save', sortable: false, width: '80px', align: 'center'},
            {value: 'isExport', sortable: false, width: '45px', align: 'center'},
            {value: 'haveUnansweredMessage', sortable: false, width: '45px', align: 'center'},
            {value: 'referenceType', sortable: false, width: '45px', align: 'center'},
            {value: 'radiographFiles', sortable: false, width: '45px', align: 'center'},
            {value: 'isPdfFile', sortable: false, width: '45px', align: 'center'},
            {value: 'data-table-expand', sortable: false, width: '44px'},
          ];
        } else {
          return [
            {value: 'data-table-select', sortable: false, width: '7%'},
            {text: 'Nr.', value: 'id', sortable: true, width: '9%'},
            {text: 'CPR-nummer', value: 'cpr', width: '13%'},
            {text: 'Patient', value: 'patientName', width: '20%'},
            {text: 'Alder', value: 'patientAge', width: '7%'},
            {text: 'Dato', value: 'createDate', width: '12%'},
            {text: 'Status', value: 'status', width: '200px'},
            {value: 'save', sortable: false, width: '80px', align: 'center'},
            {value: 'haveUnansweredMessage', sortable: false, width: '45px', align: 'center'},
            {value: 'referenceType', sortable: false, width: '45px', align: 'center'},
            {value: 'isPdfFile', sortable: false, width: '45px', align: 'center'},
            {value: 'data-table-expand', sortable: false, width: '44px'},
          ];
        }
      },
      statusTypes: function () {
        return this.$store.state.overview.statusTypes;
      },
      tableLoading: function () {
        return this.$store.state.overview.tableLoading;
      },
      filterParams: function () {
        return this.$store.state.overview.filterParams;
      },
      selectIcon: function () {
        if (this.isAdmin) {
          return 'mdi-menu-down';
        } else {
          return '';
        }
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getVisits();
      },
    },

    methods: {
      isEmptyDentistStyle: function(item) {
        if (!this.isAdmin) return;
        return !item['externalDoctorId'] && {backgroundColor: '#f8f5e1 !important'};
      },
      downloadVisitPdf: function (visitId) {
        methods.downloadURL(api.visitFileUrl(visitId));
      },
      resetUpdateButton: function (item) {
        item.updateButton.color !== 'primary' && (item.updateButton.color = 'primary');
      },
      isFinished: function (item) {
        if (item.status === 7) {
          this.dialog = true;
          this.dialogVisitId = item.id;
        }
      },
      isDeleteAttachmentsChange: function (flag) {
        let index = this.visits.findIndex((item) => item.id === this.dialogVisitId);
        this.visits[index].isDeleteAttachments = flag;
        this.dialog = false;
        this.dialogVisitId = null;
      },
      sortOrderValue: function (val) {
        switch (val.sortBy[0]) {
          case 'id':
            return 1;
          case 'cpr':
            return 2;
          case 'patientName':
            return 3;
          case 'patientAge':
            return 4;
          case 'createDate':
            return 5;
          case 'status':
            return 7;
          default:
            return 0;
        }
      },
      serverSort: function (val) {
        this.queryParams.OrderBy = this.sortOrderValue(val);
        this.queryParams.OrderByAscending = !val.sortDesc[0];
        this.getVisits();
      },
      toggleExpand: function (item, expand, isExpanded) {
        expand(!isExpanded);
        item.isExpandedRow = !isExpanded;
      },
      collapseVisit: function (visitItem, data) {
        visitItem.updateButton.color = data.buttonColor;
      },
      editCommonVisitInfo: function (visitItem, newVisitItem) {
        visitItem.externalId = newVisitItem.externalId;
        visitItem.status = newVisitItem.status;
        visitItem.cpr = newVisitItem.cpr;
        visitItem.radiographExportStatus = newVisitItem.radiographExportStatus;
        visitItem.isRadiographUploaded = newVisitItem.isRadiographUploaded;
        visitItem.isDeleteAttachments = false;
        visitItem.haveUnansweredMessage = newVisitItem.haveUnansweredMessage;
        visitItem.exportedToCsv = newVisitItem.exportedToCsv;
        visitItem.externalDoctorId = newVisitItem.externalDoctorId;
      },
      massStatusUpdate: function (newStatus) {
        this.selected.forEach(function (item) {
          item.status = newStatus;
        });
        this.selected = [];
      },
      massCsvExportUpdate: function() {
        this.selected.forEach(function (item) {
          item.exportedToCsv = true;
        });
        this.selected = [];
      },
      getVisits: async function () {
        try {
          this.visits = [];
          this.selected = [];
          this.expanded = [];
          this.$store.commit('overview/updateTableLoading', true);
          let {data} = await api.getVisits({...this.queryParams, ...this.filterParams});
          let entities = data.paginationResult.entities;
          entities.forEach((item) => {
            item.isDeleteAttachments = false;
            item.isExpandedRow = false;
            item.updateButton = {
              loading: false,
              color: 'primary',
            };
          });
          this.totalPages = Math.ceil(
            data.paginationResult.totalEntities / this.queryParams.PageSize,
          );
          this.visits = entities;
          this.totalEntities = data.paginationResult.totalEntities;
          this.$store.commit('overview/updateTableLoading', false);
        } catch (e) {
          this.visits = [];
          this.selected = [];
          this.expanded = [];
          this.$store.commit('overview/updateTableLoading', false);
        }
      },
      updateVisitStatus: async function (item) {
        let newData = [
          {
            op: 'replace',
            path: '/status',
            value: item.status,
          },
        ];
        let params = {};
        item.isDeleteAttachments && (params.isDeleteAttachments = item.isDeleteAttachments);
        try {
          item.updateButton.loading = true;
          await api.updateVisitPatch(newData, item.id, params);
          let newVisit = await api.getVisits({VisitNumber: item.id});
          this.editCommonVisitInfo(item, newVisit.data.paginationResult.entities[0]);
          item.updateButton.loading = false;
          item.updateButton.color = 'success';
        } catch (e) {
          item.updateButton.loading = false;
          item.updateButton.color = 'error';
        }
      },
      changePageNumber: function (value) {
        this.queryParams.CurrentPage = value;
        this.getVisits();
      },
      changeExportStatus: function (item) {
        item.radiographExportStatus = 1;
      },
    },
  };
</script>
