export default {
  namespaced: true,
  state: {
    emailTableLoading: false,
    emailFilterParams: {},
    smsFilterParams: {},
    emailTypeList: [],
    smsTableLoading: false,
    smsTypeList: [],
    logType: ''
  },
  mutations: {
    updateEmailTableLoading(state, payload) {
      state.emailTableLoading = payload;
    },
    updateEmailFilterParams(state, payload) {
      state.emailFilterParams = payload;
    },
    updateEmailTypeList(state, payload) {
      state.emailTypeList = payload;
    },
    updateLogType(state, payload) {
      state.logType = payload;
    },
    updateSmsTableLoading(state, payload) {
      state.smsTableLoading = payload;
    },
    updateSmsFilterParams(state, payload) {
      state.smsFilterParams = payload;
    },
    updateSmsTypeList(state, payload) {
      state.smsTypeList = payload;
    },
  },
};
