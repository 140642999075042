<template>
  <v-container class="management-tabs__container py-0 pb-16">
    <SmsFilter/>
    <SmsData v-if="showDataTable"/>
  </v-container>
</template>

<script>
  import SmsFilter from '@/components/sms/SmsFilter';
  import SmsData from '@/components/sms/SmsData';

  export default {
    name: 'Sms',
    components: {SmsData, SmsFilter},
    mounted: async function () {
      // Google Analytics
      ga('set', 'page', '/management/sms');
      ga('send', 'pageview');
      // End Google Analytics
    },
    computed: {
      showDataTable: function () {
        return this.$store.state.sms.showDataTable;
      },
    },
    destroyed: function () {
      this.$store.commit('sms/updateShowDataTable', false);
    },
  };
</script>
