<template>
  <v-form ref="form" v-model="formValid" class="profile-section py-5 px-3">
    <h2 class="mb-2">ÆNDRE PASSWORD</h2>
    <p>Her kan du ændre dit password.</p>
    <v-alert v-if="changed" dense text type="success" class="py-4 mt-7">Din adgangskode er blevet ændret</v-alert>
    <v-row v-else justify="space-between" class="my-0 py-0">
      <v-col cols="12" md="6">
        <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="password" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.minLength_7, rules.digit, rules.lowerCase, rules.upperCase]" :type="passwordShow ? 'text' : 'password'" label="Indtast password" counter filled @click:append="passwordShow = !passwordShow" @change="buttonColor !== 'primary' && (buttonColor = 'primary')"/>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="repeatPassword" :append-icon="repeatShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRepeatRule]" :type="repeatShow ? 'text' : 'password'" label="Gentag password" filled @click:append="repeatShow = !repeatShow" @change="buttonColor !== 'primary' && (buttonColor = 'primary')"/>
      </v-col>
    </v-row>
    <v-row justify="space-between" align="center" class="my-0 py-0">
      <v-col cols="auto" class="py-0 ml-lg-auto">
        <v-btn v-if="changed" depressed class="mb-2 mb-md-0" color="primary" @click="changed = false">
          ÆNDRE PASSWORD
        </v-btn>
        <v-btn v-else depressed class="mb-2 mb-md-0" :color="buttonColor" :loading="loading" :disabled="!formValid || loading" @click="changePassword">
          Opdater
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import validationRules from '../../utils/validationRules';
  import api from "../../api/api";
  import methods from '@/utils/methods';

  export default {
    name: 'PasswordForm',

    data: function () {
      return {
        rules: {...validationRules},
        formValid: false,
        password: '',
        repeatPassword: '',
        passwordShow: false,
        repeatShow: false,
        buttonColor: 'primary',
        loading: false,
        changed: false,
      };
    },

    computed: {
      passwordRepeatRule: function () {
        return this.password === this.repeatPassword || 'Passwords matcher ikke';
      },
    },

    methods: {
      changePassword: async function () {
        try {
          this.loading = true;
          await api.changePassword({newPassword: this.password});
          this.loading = false;
          this.buttonColor = 'success';
          this.changed = true;
          this.repeatPassword = '';
          this.password = '';
        } catch (e) {
          this.loading = false;
          if (e.response.status === 422) {
            methods.showGlobalSnackbar('error', 'Adgangskoden er for enkel.');
          }
          this.buttonColor = 'error';
        }
      },
    },
  };
</script>
