<template>
  <v-container class="management-tabs__container py-0 pb-16">
    <TreatmentTypes/>
    <ImportFilters/>
    <div class="data-section mb-5">
      <CsvExport @exportFinished="$refs.importsData.updateImports()">
        <template v-slot:title>
          Upload af patienttider / uploades dagligt - (bruges til at udsende sms reminders)
        </template>
      </CsvExport>
    </div>
    <ImportsData ref="importsData"/>
  </v-container>
</template>

<script>
  import TreatmentTypes from '@/components/settings/TreatmentTypes';
  import ImportFilters from '@/components/settings/ImportFilters';
  import ImportsData from '@/components/settings/ImportsData';
  import CsvExport from '@/components/common/CsvExport';

  export default {
    name: 'Settings',
    components: {CsvExport, ImportsData, ImportFilters, TreatmentTypes},
    mounted: async function () {
      // Google Analytics
      ga('set', 'page', '/management/settings');
      ga('send', 'pageview');
      // End Google Analytics
    },
  };
</script>
