<template>
  <v-container v-if="roleAccess" class="management-tabs__container py-0 pb-16">
    <UsersFilter/>
    <UsersData v-if="showDataTable"/>
  </v-container>
  <v-container v-else>
    <v-alert prominent type="error">
      <v-row align="center" class="my-0 py-5">
        <v-col class="grow">Access Denied by Role</v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
  import UsersFilter from '../../components/users/UsersFilter';
  import UsersData from '../../components/users/UsersData';
  export default {
    name: 'Users',

    components: {UsersData, UsersFilter},

    mounted: async function () {
      // Google Analytics
      ga('set', 'page', '/management/users');
      ga('send', 'pageview');
      // End Google Analytics
    },

    computed: {
      showDataTable: function () {
        return this.$store.state.users.showDataTable;
      },
      role: function () {
        return this.$store.state.role;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      roleAccess: function () {
        return  (this.isAdmin || this.isSecretary);
      },
    },

    destroyed: function () {
      this.$store.commit('users/updateShowDataTable', false);
    },
  };
</script>
