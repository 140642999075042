import { render, staticRenderFns } from "./ClinicsTableBox.vue?vue&type=template&id=8b947a20"
import script from "./ClinicsTableBox.vue?vue&type=script&lang=js"
export * from "./ClinicsTableBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VIcon,VRadio,VRadioGroup})
