<template>
  <v-container class="management-tabs__container py-0 pb-16">
    <CallUpFilter/>
    <CallUpData v-if="showDataTable"/>
  </v-container>
</template>

<script>
  import CallUpFilter from '../../components/callUp/CallUpFilter';
  import CallUpData from '../../components/callUp/CallUpData';

  export default {
    name: 'CallUp',

    components: {CallUpData, CallUpFilter},

    mounted: function () {
      // Google Analytics
      ga('set', 'page', 'management/call-up-letters');
      ga('send', 'pageview');
      // End Google Analytics
    },

    computed: {
      showDataTable: function () {
        return this.$store.state.callUp.showDataTable;
      },
    },

    destroyed: function () {
      this.$store.commit('callUp/updateShowDataTable', false);
    },
  };
</script>
