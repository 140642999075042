<template>
  <v-container v-if="roleAccess" class="management-tabs__container py-0 pb-16">
    <LogsFilter/>
    <template v-if="logType === LOG_TYPES.EMAIL">
      <div class="text-center mb-2 font-weight-bold">E-MAIL LOGS</div>
      <EmailLogsData/>
    </template>
    <template v-if="logType === LOG_TYPES.SMS">
      <div class="text-center mb-2 font-weight-bold">SMS LOGS</div>
      <SmsLogsData/>
    </template>
  </v-container>
  <v-container v-else>
    <v-alert prominent type="error">
      <v-row align="center" class="my-0 py-5">
        <v-col class="grow">Access Denied by Role</v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
  import LogsFilter from '@/components/logs/LogsFilter.vue';
  import EmailLogsData from '@/components/logs/EmailLogsData.vue';
  import SmsLogsData from '@/components/logs/SmsLogsData.vue';
  import { LOG_TYPES } from '@/constants/logTypes';

  export default {
    name: 'EmailLogs',
    components: { SmsLogsData, EmailLogsData, LogsFilter },

    mounted: async function () {
      // Google Analytics
      ga('set', 'page', '/management/logs');
      ga('send', 'pageview');
      // End Google Analytics
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      roleAccess: function () {
        return (this.isAdmin);
      },
      logType: function () {
        return this.$store.state.logs.logType;
      },
      LOG_TYPES: function () {
        return LOG_TYPES;
      },
    },

    destroyed: function () {
      this.$store.commit('logs/updateLogType', '');
    },
  };
</script>
