<template>
  <v-container v-if="roleAccess" class="management-tabs__container py-0 pb-16">
    <ClinicsFilter/>
    <ClinicsData v-if="showDataTable"/>
  </v-container>
  <v-container v-else>
    <v-alert prominent type="error">
      <v-row align="center" class="my-0 py-5">
        <v-col class="grow">Access Denied by Role</v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
  import ClinicsFilter from '../../components/clinics/ClinicsFilter';
  import ClinicsData from '../../components/clinics/ClinicsData';
  export default {
    name: 'Clinics',

    components: {ClinicsData, ClinicsFilter},

    mounted: async function () {
      // Google Analytics
      ga('set', 'page', '/management/clinics');
      ga('send', 'pageview');
      // End Google Analytics
    },

    computed: {
      showDataTable: function () {
        return this.$store.state.clinicsStore.showDataTable;
      },
      role: function () {
        return this.$store.state.role;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      roleAccess: function () {
        return (this.isAdmin || this.isSecretary);
      },
    },

    destroyed: function () {
      this.$store.commit('clinicsStore/updateShowDataTable', false);
    },
  };
</script>
