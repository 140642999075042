<template>
  <div class="merge-block mb-6">
    <v-btn block depressed :ripple="false" color="#f8f9fb" class="rounded-0" @click="cancelMerge" :disabled="mergeLoading">
      <v-icon size="30">icon-chevron-up</v-icon>
    </v-btn>
    <div class="pa-6">
      <v-form ref="mergeForm" v-model="formValid" class="merge-block__scroll-area">
        <div class="merge-block__row">
          <div class="merge-block__input-holder text-center"/>
          <div class="merge-block__clinics-holder">
            <div v-for="(clinic) in mergeArray" class="merge-block__checkbox-holder text-center">
              <v-btn icon @click="deleteMergeClinic(clinic)">
                <v-icon size="25">mdi-close-thick</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder text-center">
            <span class="font-weight-bold">Resultat - Klinikkens info</span>
          </div>
          <div class="merge-block__clinics-holder">
            <div v-for="(clinic, index) in mergeArray" class="merge-block__checkbox-holder text-center">
              <v-btn color="primary" outlined @click="setDefaultMerge(index)">Klik alle af</v-btn>
            </div>
          </div>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-text-field v-model="title" label="Titel*" :rules="[rules.required]" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="nameRow" :mergeArray="mergeArray" keyName="name" @changeCategoryValue="changeCategoryValue('title', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-text-field v-model="email" label="Email*" :rules="[rules.required, rules.email]" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="emailRow" :mergeArray="mergeArray" keyName="email" @changeCategoryValue="changeCategoryValue('email', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-text-field v-model="phone" label="Telefon*" :rules="[rules.required]" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="phoneRow" :mergeArray="mergeArray" keyName="phone" @changeCategoryValue="changeCategoryValue('phone', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-text-field v-model="mobilePhone" label="Mobil" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="mobileRow" :mergeArray="mergeArray" keyName="mobilePhone" @changeCategoryValue="changeCategoryValue('mobilePhone', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-text-field v-model="address" label="Adresse*" :rules="[rules.required]" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="addressRow" :mergeArray="mergeArray" keyName="address" @changeCategoryValue="changeCategoryValue('address', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-text-field v-model="zip" label="Postnummer*" :rules="[rules.required]" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="zipRow" :mergeArray="mergeArray" keyName="zip" @changeCategoryValue="changeCategoryValue('zip', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-text-field v-model="city" label="By*" :rules="[rules.required]" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="cityRow" :mergeArray="mergeArray" keyName="city" @changeCategoryValue="changeCategoryValue('city', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder">
            <v-select :items="statusItems" :rules="[rules.requiredId]" item-text="value" item-value="key" label="Status*" v-model="status" hide-details outlined/>
          </div>
          <ClinicsTableBox ref="statusRow" :mergeArray="mergeArray" keyName="status" @changeCategoryValue="changeCategoryValue('status', $event)"/>
        </div>
        <div class="merge-block__row">
          <div class="merge-block__input-holder text-center">Henvisere/brugere</div>
          <ClinicsTableBox :mergeArray="mergeArray" keyName="members"/>
        </div>
      </v-form>
      <div class="d-flex justify-center my-10 px-lg-12">
        <p class="mb-0">"Fusioneringen" af brugerne kan ikke fortrydes. Alle medlemmer af den enkelte klinik vil blive slået sammen til een klinik. Hvis brugeren havde en sekretærrolle i den tidligere klinik, vil denne blive bibeholdt i den nye klinik. Alle henvisninger vil tilhøre den originale henviser/tandlæge. Tandlægerne kan have flere profiler, men de er unikke, så de vil blive ved med at være seperate.</p>
      </div>
      <div class="d-flex justify-center mt-6">
        <v-btn color="primary" @click="saveMerge" :loading="mergeLoading" :disabled="mergeLoading">Gem "fusioneringen"</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import ClinicsTableBox from '../../components/clinics/ClinicsTableBox';
  import validationRules from '../../utils/validationRules';
  import api from '../../api/api';

  export default {
    name: 'ClinicsMergeDialog',

    components: {ClinicsTableBox},

    props: {
      mergeArray: {
        type: Array,
      },
      filterParams: {
        type: Object
      }
    },

    data: function() {
      return {
        rules: {...validationRules},
        formValid: false,
        mergeDialog: false,
        mergeLoading: false,
        title: '',
        address: '',
        zip: '',
        city: '',
        status: null,
        email: '',
        phone: '',
        mobilePhone: '',
      }
    },

    computed: {
      statusItems: function() {
        return this.$store.state.clinicsStore.statusItems;
      }
    },

    methods: {
      changeCategoryValue: function(dataName, data) {
        this[dataName] = data;
      },
      deleteMergeClinic: function(clinic) {
        this.resetData();
        this.setDefaultMerge(null);
        this.$emit('deleteMergeClinic', clinic);
      },
      resetData: function() {
        this.title = '';
        this.address = '';
        this.zip = '';
        this.city = '';
        this.status = null;
        this.email = '';
        this.phone = '';
        this.title = '';
        this.mobilePhone = '';
      },
      setDefaultMerge: function(index) {
        this.$refs.nameRow.setDefaultValue(index);
        this.$refs.emailRow.setDefaultValue(index);
        this.$refs.phoneRow.setDefaultValue(index);
        this.$refs.mobileRow.setDefaultValue(index);
        this.$refs.addressRow.setDefaultValue(index);
        this.$refs.zipRow.setDefaultValue(index);
        this.$refs.cityRow.setDefaultValue(index);
        this.$refs.statusRow.setDefaultValue(index);
      },
      cancelMerge: function() {
        this.resetData();
        this.$emit('closeMergeDialog');
      },
      saveMerge: async function() {
        this.$refs.mergeForm.validate();
        if (!this.formValid) return;
        let queryBody = {};
        queryBody.name = this.title;
        queryBody.address = this.address;
        queryBody.zip = this.zip;
        queryBody.city = this.city;
        queryBody.phone = this.phone;
        queryBody.email = this.email;
        queryBody.status = this.status;
        queryBody.mobilePhone = this.mobilePhone;
        queryBody.clinicIdsToMerge = [];
        this.mergeArray.forEach((item) => {
          queryBody.clinicIdsToMerge.push(item.clinicId);
        });
        try {
          this.mergeLoading = true;
          await api.mergeClinics(queryBody);
          let newClinicsData = await api.getClinics(this.filterParams);
          this.mergeLoading = false;
          this.$emit('updateClinicsData', newClinicsData.data);
          this.$emit('clearMerge');
          this.$emit('closeMergeDialog');
          this.$emit('moveToControls');
        } catch (e) {
          this.mergeLoading = false;
        }
      }
    }
  };
</script>

