<template>
  <v-container fluid class="py-6">
    <v-row align="center" justify="space-between" class="my-0">
      <v-col cols="12" lg="4" class="py-0 mb-4 mb-lg-0">
        <span>Statistik liste eksport:</span>
      </v-col>
      <v-col class="py-0 mb-4 mb-md-0">
        <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field :value="formattedDateFrom" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuFrom = true" label="Dato" readonly hide-details v-on="on"/>
          </template>
          <v-date-picker v-model="dateFrom" no-title @change="menuFrom = false" color="primary lighten-0"/>
        </v-menu>
      </v-col>
      <v-col cols="auto" class="py-0 px-0 mb-4 mb-md-0">
        <v-icon size="15">icon-minus</v-icon>
      </v-col>
      <v-col class="py-0 mb-4 mb-md-0">
        <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field :value="formattedDateTo" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuTo = true" readonly hide-details v-on="on"/>
          </template>
          <v-date-picker v-model="dateTo" no-title @change="menuTo = false" color="primary lighten-0"/>
        </v-menu>
      </v-col>
      <v-col cols="12" md="auto" class="py-0">
        <v-btn depressed class="mb-2 mb-md-0" color="primary" :loading="loading" :disabled="loading" @click="exportCsv">Export til CSV</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'StatisticsExport',

    data: function () {
      return {
        menuFrom: false,
        dateFrom: null,
        menuTo: false,
        dateTo: null,
        loading: false,
      };
    },

    computed: {
      formattedDateFrom: function () {
        if (this.dateFrom) {
          return methods.formatDate(this.dateFrom);
        } else {
          return 'Fra';
        }
      },
      formattedDateTo: function () {
        if (this.dateTo) {
          return methods.formatDate(this.dateTo);
        } else {
          return 'Til';
        }
      },
      dataType: function () {
        return this.$store.state.statistics.dataType;
      },
    },

    methods: {
      queryParams: function () {
        let params = {};
        this.dateFrom && (params.DateFrom = new Date(this.dateFrom).toISOString());
        this.dateTo && (params.DateTo = new Date(this.dateTo).toISOString());
        return params;
      },
      exportCsv: async function () {
        try {
          this.loading = true;
          let data = {};
          if (this.dataType === 0) {
            data = await api.getOperationsCsv(this.queryParams());
          } else if (this.dataType === 1) {
            data = await api.getDoctorsCsv(this.queryParams());
          }
          methods.downloadURL(data.request.responseURL);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
    },
  };
</script>