import axios from 'axios';
import Qs from 'qs';
import store from '../store/index';
import methods from '../utils/methods';

const axios_blank = axios.create({});

axios.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      methods.backToLogin();
    }
    return Promise.reject(error);
  },
);

export default {
  // Account
  getAccountInfo: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Account`,
    });
  },
  setAccountData: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Account`,
      data,
    });
  },
  getAccountAccess: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Account/Access`,
    });
  },
  changePassword: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Account/password`,
      data,
    });
  },
  forgotPassword: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Account/password/forgot`,
      data,
    });
  },
  resetPassword: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Account/password/reset`,
      data,
    });
  },
  signIn: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Account/SignIn`,
      data,
    });
  },
  signOut: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Account/SignOut`,
      data,
    });
  },

  // Admin
  importEDIVisits: () => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Admin/run-import-edi-visits`,
    });
  },

  // Appointments
  uploadAppointmentCsv: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Appointments/csv/upload`,
      data,
    });
  },
  trackUploadAppointmentCsv: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Appointments/csv/track-process`,
      params: data,
    });
  },
  getAppointmentLogs: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Appointments/logs`,
      params: data,
    });
  },
  getAppointmentCsvLogs: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Appointments/csv/logs`,
      data,
    });
  },

  // Clinics
  getClinics: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Clinics`,
      params: data,
    });
  },
  getActiveClinics: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Clinics/active-only`,
    });
  },
  getSingleActiveClinic: (clinicId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Clinics/active-only/${clinicId}`,
    });
  },
  createClinic: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Clinics`,
      data,
    });
  },
  updateClinic: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Clinics`,
      data,
    });
  },
  mergeClinics: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Clinics/merge`,
      data,
    });
  },

  // Cpr
  getExternalPatientInfo: (Cpr) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Cpr/address`,
      params: {
        Cpr,
      },
    });
  },

  // Doctors
  getDoctors: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Doctors`,
    });
  },
  acceptConditions: () => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Doctors/conditions`,
      data: {accepted: true},
    });
  },

  // Drafts
  getDraftsList: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Drafts`,
      params: data,
    });
  },
  deleteDraft: (visitId) => {
    return axios({
      method: 'delete',
      url: `${store.state.apiUrl}/Drafts/${visitId}`,
    });
  },

  // Incalls
  getIncalls: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Incall`,
      params: data,
      paramsSerializer: params => {
        return Qs.stringify(params, {arrayFormat: 'repeat'});
      },
    });
  },
  sendIncall: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Incall`,
      data,
    });
  },
  sendCallUpPhone: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Incall/sendCallUpPhone`,
      data,
    });
  },
  getIncallLogs: (visitId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Incall/logs/${visitId}`,
    });
  },
  incallLogsFileUrl: (visitLogId) => {
    return `${store.state.apiUrl}/Incall/download/pdf/${visitLogId}`;
  },
  getIncallArchive: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Incall/archive`,
      params: data,
    });
  },
  archiveFileUrl: (archiveId) => {
    return `${store.state.apiUrl}/Incall/archive/${archiveId}`;
  },

  // Patient
  getRadiographs: (token) => {
    return axios_blank({
      method: 'get',
      url: `${store.state.apiUrl}/radiographs/token/${token}`,
    });
  },
  downloadRadiograph: (radiographId, token) => {
    return axios_blank({
      method: 'get',
      url: `${store.state.apiUrl}/radiographs/${radiographId}/download/token/${token}`,
      responseType: 'blob',
    });
  },
  downloadRadiographZip: (token) => {
    return axios_blank({
      method: 'get',
      url: `${store.state.apiUrl}/radiographs/download/token/${token}`,
      responseType: 'blob',
    });
  },

  // Pdf
  visitPdf: (visitId, regenerate) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Pdf/Visit/${visitId}`,
      params: {
        regenerate,
      },
    });
  },
  visitFileUrl: (visitId) => {
    return `${store.state.apiUrl}/Pdf/Visit/${visitId}`;
  },

  // Sms Appointments
  getSmsAppointments: (params) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/SmsAppointments`,
      params,
    });
  },
  sendSmsWithCancellation: (visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/SmsAppointments/visitid/${visitId}/send-sms-with-cancellation`,
    });
  },
  sendSmsWithoutCancellation: (visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/SmsAppointments/visitid/${visitId}/send-sms-without-cancellation`,
    });
  },

  // Sms Log
  getSmsLogs: (params) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/SmsLog`,
      params,
    });
  },

  // Statistics
  getDoctorsStatistic: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Statistics/doctors`,
      params: data,
    });
  },
  getDoctorsCsv: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Statistics/doctors/csv`,
      params: data,
    });
  },
  getOperationStatistic: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Statistics/operations`,
      params: data,
    });
  },
  getOperationsCsv: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Statistics/operations/csv`,
      params: data,
    });
  },

  // Surgeons
  getSurgeons: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Surgeons`,
    });
  },

  // TerminationLetters
  getTerminationLetters: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/TerminationLetters`,
      params: data,
    });
  },
  diagnosisFileUrl: (idValue, number) => {
    return `${store.state.apiUrl}/TerminationLetters/${idValue}/diagnosis/${number}`;
  },
  rentgenFileUrl: (idValue, number) => {
    return `${store.state.apiUrl}/TerminationLetters/${idValue}/rentgen/${number}`;
  },
  sendTerminationLettersData: (visitId, docType, letterNumber, data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/TerminationLetters/${visitId}/${docType}/${letterNumber}`,
      data,
    });
  },
  resendLetterEmail: (visitId, letterNumber) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/TerminationLetters/${visitId}/resendemail/${letterNumber}`,
      data: null,
    });
  },
  deleteLetter: (visitId, letterNumber) => {
    return axios({
      method: 'delete',
      url: `${store.state.apiUrl}/TerminationLetters/${visitId}/number/${letterNumber}`,
    });
  },
  updateEdiLetterAsEmail: (visitId, letterNumber, data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/TerminationLetters/edi/${visitId}/number/${letterNumber}`,
      data
    });
  },
  sendLetterSmsWithLink: (visitId, letterNumber) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/TerminationLetters/${visitId}/rentgen/${letterNumber}/send-smslink-to-patient`,
    });
  },
  downloadLetterFilesZip: (token) => {
    return axios_blank({
      method: 'get',
      url: `${store.state.apiUrl}/termination-letter-zip/token/${token}`,
      responseType: 'blob',
    });
  },

  // TreatmentTypes
  getTreatmentTypes: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/TreatmentTypes`,
    });
  },
  editTretmentType: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/TreatmentTypes`,
      data,
    });
  },
  addNewTreatmentType: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/TreatmentTypes`,
      data,
    });
  },
  deleteTretmentType: (id) => {
    return axios({
      method: 'delete',
      url: `${store.state.apiUrl}/TreatmentTypes`,
      params: {id: id},
    });
  },

  // Umbraco
  getConditions: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Umbraco/conditions`,
    });
  },
  getManagement: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Umbraco/management`,
    });
  },
  getInfoPopup: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Umbraco/info-popup`,
    });
  },

  // Users
  getUsers: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Users`,
      params: data,
    });
  },
  createUser: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Users`,
      data,
    });
  },
  updateUser: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Users`,
      data,
    });
  },
  getSingleUser: (userId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Users/${userId}`,
    });
  },
  createAdmin: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Users/admin`,
      data,
    });
  },
  updateAdmin: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Users/admin`,
      data,
    });
  },
  createQuickProfile: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Users/quick-profile`,
      data,
    });
  },

  // Visits
  getVisitInfo: (visitId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/${visitId}`,
    });
  },
  updateVisitPatch: (data, visitId, params) => {
    return axios({
      method: 'patch',
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
      url: `${store.state.apiUrl}/Visits/${visitId}`,
      data,
      params: params,
    });
  },
  getVisits: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits`,
      params: data,
      paramsSerializer: params => {
        return Qs.stringify(params, {arrayFormat: 'repeat'});
      },
    });
  },
  updateVisit: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Visits`,
      data,
    });
  },
  appropriationFileUrl: (visitId) => {
    return `${store.state.apiUrl}/Visits/${visitId}/download/appropriation`;
  },
  journalsFileUrl: (visitId, journalId) => {
    return `${store.state.apiUrl}/Visits/${visitId}/download/journals/${journalId}`;
  },
  sendPatientInfo: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/patientinfo`,
      data,
    });
  },
  updatePatientInfo: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Visits/patientinfo`,
      data,
    });
  },
  getInternalPatientInfo: (cpr) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/patientinfo/cpr/${cpr}`,
    });
  },
  getDrafts: (cpr) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/patientinfo/cpr/check/${cpr}`,
    });
  },
  getVisitById: (visitId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/${visitId}/patientinfo`,
    });
  },
  getDoctorInfo: (visitId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/${visitId}/doctorinfo`,
    });
  },
  sendDoctorInfo: (data, visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/doctorinfo`,
      data,
    });
  },
  sendCallUp: (visitId, type) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/callups/${type}`,
      data: null,
    });
  },
  getAppointments: (visitId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/${visitId}/appointmentinfo`,
    });
  },
  sendAppointment: (visitId, data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/appointmentinfo`,
      data,
    });
  },
  sendStatusToDentist: (visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/notification/surgeon`,
    });
  },
  getLog: (visitId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/${visitId}/visitlog`,
    });
  },
  massStatusUpdate: (data) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Visits/status`,
      data,
    });
  },
  getCallupStats: () => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/callupstats`,
    });
  },

  // VisitCsv
  downloadCsvByDates: (data) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/download/csv/bydates`,
      params: data,
    });
  },
  exportVisitsById: (data) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/download/csv/byids`,
      data,
    });
  },
  getLastExpotedDate: (type) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/csv/settings`,
      params: {type},
    });
  },

  // VisitMessages
  getMessages: (visitId) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/Visits/${visitId}/messages`,
    });
  },
  sendMessage: (data, visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/messages`,
      data,
    });
  },
  updateMessage: (data, visitId, messageId) => {
    return axios({
      method: 'put',
      url: `${store.state.apiUrl}/Visits/${visitId}/messages/${messageId}`,
      data,
    });
  },
  attachmentFileUrl: (visitId, fileId) => {
    return `${store.state.apiUrl}/Visits/${visitId}/messages/attachment/${fileId}`;
  },
  markMessageAsRead: (data, visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/messages/read`,
      data,
    });
  },

  // VisitsRadiographs
  radiographsFileUrl: (visitId) => {
    return `${store.state.apiUrl}/Visits/${visitId}/download/radiographs`;
  },
  radiographFileUrl: (visitId, radiographId) => {
    return `${store.state.apiUrl}/Visits/${visitId}/download/${radiographId}/radiograph`;
  },
  sendRadiographs: (data, visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/radiographs`,
      data,
    });
  },
  sendSmsWithLink: (visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/radiographs/send-sms-with-link-to-patient`,
    });
  },
  uploadRadiographs: (data, visitId) => {
    return axios({
      method: 'post',
      url: `${store.state.apiUrl}/Visits/${visitId}/upload/radiographs`,
      data,
    });
  },
  deleteRadiograph: (visitId, radiographId) => {
    return axios({
      method: 'delete',
      url: `${store.state.apiUrl}/Visits/${visitId}/radiographs/${radiographId}`,
    });
  },

  // Email logs
  getEmailLogs: (params) => {
    return axios({
      method: 'get',
      url: `${store.state.apiUrl}/EmailLog`,
      params
    });
  },
};
