<template>
  <div class="py-5">
    <div v-if="messages.length > 0">
      <div v-for="messageItem in messages" class="mb-2">
        <div v-if="!messageItem.isIncomingMessage" class="text-right">
          <v-alert border="right" color="grey lighten-2" class="my-0 d-inline-block" style="min-width: 50%; max-width: 90%" dense>
            <v-row justify="space-between" class="my-0">
              <v-col cols="auto" class="py-0">
                <v-tooltip bottom :allow-overflow="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" size="22" :class="[iconColor(messageItem.status)]">{{ iconName(messageItem.status) }}</v-icon>
                  </template>
                  <span>{{ messageItem.statusText }}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="auto" class="py-0">
                <div class="text-caption grey--text text--darken-2 font-weight-bold mb-1">Kirurgiklinik</div>
                <div class="text-caption grey--text text--darken-2">{{ messageItem.date | formattedDate }}</div>
              </v-col>
            </v-row>
            <p class="my-0 text-left">{{ messageItem.message }}</p>
          </v-alert>
        </div>
        <div v-if="messageItem.isIncomingMessage">
          <v-alert border="left" :color="messageItem.cancellationDetected ? 'error lighten-3' : 'grey lighten-2'" class="my-0 d-inline-block" style="min-width: 50%; max-width: 90%" dense>
            <v-row justify="space-between" class="my-0">
              <v-col cols="auto" class="py-0">
                <div class="text-caption grey--text text--darken-2 font-weight-bold mb-1">From {{ messageItem.from }}</div>
                <div class="text-caption grey--text text--darken-2">{{ messageItem.date | formattedDate }}</div>
              </v-col>
              <v-col v-if="messageItem.cancellationDetected" cols="auto" class="py-0">
                <v-icon size="22" class="grey--text text--darken-2">mdi-calendar-remove</v-icon>
              </v-col>
            </v-row>
            <p class="my-0">{{ messageItem.message }}</p>
          </v-alert>
        </div>
      </div>
    </div>
    <span v-else class="d-block text-body-1 grey--text">Ingen data...</span>
    <div>
      <v-row align="center" justify="space-between" class="my-0">
        <v-spacer/>
        <v-col md="auto">
          <v-btn depressed :color="cancellationColor" :loading="cancellationLoading" :disabled="cancellationLoading || withoutCancellationLoading" @click="sendCancellatonSms">Send SMS med afbud</v-btn>
          <v-btn class="ml-2" depressed :color="withoutCancellationColor" :loading="withoutCancellationLoading" :disabled="cancellationLoading || withoutCancellationLoading" @click="sendWithoutCancellatonSms">Send SMS uden afbud</v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import methods from '@/utils/methods';
  import api from '@/api/api';

  export default {
    name: 'SmsExpanded',

    props: ['messages', 'visitId'],

    data: function () {
      return {
        cancellationLoading: false,
        cancellationColor: 'primary',
        withoutCancellationLoading: false,
        withoutCancellationColor: 'primary',
      };
    },

    filters: {
      formattedDate: function (value) {
        if (!value) return '';
        return `${methods.getDateFromUtc(value)} ${methods.getTimeFromUtc(value)}`;
      }
    },

    methods: {
      iconName: function (status) {
        let names = {
          0: 'mdi-human-queue',
          1: 'mdi-check-circle',
          2: 'mdi-alert-circle',
        };
        return names[status];
      },
      iconColor: function (status) {
        let colors = {
          0: 'grey--text text--darken-2',
          1: 'teal--text',
          2: 'deep-orange--text text--accent-4',
        };
        return colors[status];
      },
      sendCancellatonSms: async function () {
        try {
          this.cancellationLoading = true;
          await api.sendSmsWithCancellation(this.visitId);
          let visitsData = await api.getSmsAppointments({VisitId: this.visitId});
          this.$emit('updateVisit', visitsData.data.entities[0]);
          this.cancellationLoading = false;
        } catch (e) {
          this.cancellationLoading = false;
          this.cancellationColor = 'error';
        }
      },
      sendWithoutCancellatonSms: async function () {
        try {
          this.withoutCancellationLoading = true;
          await api.sendSmsWithoutCancellation(this.visitId);
          let visitsData = await api.getSmsAppointments({VisitId: this.visitId});
          this.$emit('updateVisit', visitsData.data.entities[0]);
          this.withoutCancellationLoading = false;
        } catch (e) {
          this.withoutCancellationLoading = false;
          this.withoutCancellationColor = 'error';
        }
      },
    },
  };
</script>
