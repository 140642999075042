<template>
  <v-container class="management-tabs__container py-0 pb-16">
    <StatisticsFilter/>
    <StatisticsData/>
  </v-container>
</template>

<script>
  import StatisticsFilter from '../../components/statistics/StatisticsFilter';
  import StatisticsData from '../../components/statistics/StatisticsData';

  export default {
    name: 'Statistics',

    components: {StatisticsData, StatisticsFilter},

    mounted: function () {
      // Google Analytics
      ga('set', 'page', 'management/statistics');
      ga('send', 'pageview');
      // End Google Analytics
    },
  };
</script>