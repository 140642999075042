<template>
  <v-btn depressed :loading="loading" :disabled="loading || !letterItem.diagnosFileName" :color="buttonColor" @click="resendLetter">
    {{letterItem.diagnosFileName ? 'Gensend udskrivningsbrev' : 'Pdf-filen mangler'}}
  </v-btn>
</template>

<script>
  import api from '../../api/api';

  export default {
    name: 'ResendEmailButton',

    props: {
      visitItem: {
        type: Object,
      },
      letterItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        loading: false,
        buttonColor: 'primary',
      };
    },

    methods: {
      resendLetter: async function () {
        try {
          let newVisitId = this.letterItem.visitId ? this.letterItem.visitId : this.visitItem.id;
          this.loading = true;
          await api.resendLetterEmail(newVisitId, this.letterItem.letterNumber);
          let newVisit = await api.getTerminationLetters({VisitNumber: this.visitItem.id});
          this.loading = false;
          this.buttonColor = 'green lighten-2';
          this.$emit('updateVisitItem', newVisit.data.visits[0]);
        } catch (e) {
          this.loading = false;
          this.buttonColor = 'error';
        }
      },
    },
  };
</script>
