<template>
  <v-container class="management-tabs__container py-0 pb-16">
    <OverviewFilter/>
    <OverviewCallupStats v-if="isAdmin"/>
    <CsvExport v-if="appointmentAlert && isAdmin" btnColor="#fd6d02" progressColor="#fd6d02" btnClass="white--text" class="mb-5 rounded" style="background: #0070bc">
      <template v-slot:title>
        <div class="white--text">
          <strong>OBS:</strong> Dagens upload af patienttider mangler (bruges til at udsende sms reminders)
        </div>
      </template>
    </CsvExport>
    <OverviewData v-if="showDataTable"/>
  </v-container>
</template>

<script>
  import OverviewFilter from '../../components/overview/OverviewFilter';
  import OverviewData from '../../components/overview/OverviewData';
  import OverviewCallupStats from '../../components/overview/OverviewCallupStats';
  import CsvExport from '@/components/common/CsvExport';

  export default {
    name: 'Overview',

    components: {CsvExport, OverviewCallupStats, OverviewData, OverviewFilter},

    mounted: async function () {
      // Google Analytics
      ga('set', 'page', '/management/overview');
      ga('send', 'pageview');
      // End Google Analytics
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      showDataTable: function () {
        return this.$store.state.overview.showDataTable;
      },
      appointmentAlert: function () {
        return this.$store.state.appointmentAlert;
      },
    },

    destroyed: function () {
      this.$store.commit('overview/updateShowDataTable', false);
    }
  };
</script>
