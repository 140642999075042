<template>
  <div>
    <v-btn depressed color="primary" @click="dialog = true">Upload</v-btn>
    <v-dialog v-model="dialog" persistent max-width="700" overlay-color="#fff" :overlay-opacity="0.7">
      <v-card>
        <v-card-title class="headline">RØNTGENBILLEDER</v-card-title>
        <v-card-text class="py-4">
          <v-row v-for="(item, index) in xrayFiles" :key="item.id" align="center" class="my-0">
            <v-col cols="12" md="5" class="py-0">
              <v-select :items="xrayFileTypes" item-text="text" item-value="val" label="Image file type:" v-model="item.type"/>
            </v-col>
            <v-col cols="8" md="5" class="py-0">
              <v-file-input :value="item.file" show-size label="File" disabled :clearable="false"/>
            </v-col>
            <v-col class="py-0">
              <v-btn icon color="primary" @click="xrayRemoveFile(index)">
                <v-icon>icon-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" class="my-0 mt-5">
            <v-col cols="12" md="5" class="py-0">
              <v-select :items="xrayFileTypes" item-text="text" item-value="val" label="Image file type:" v-model="xrayNewType"/>
            </v-col>
            <v-col class="py-0">
              <div class="d-inline-block">
                <v-btn depressed color="primary" @click="xrayFileTrigger">
                  Gennemse...
                </v-btn>
                <input ref="fileUploader" class="d-none" :accept="availableFileTypes" type="file" @input="xrayAddFile($event)"/>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed :color="uploadButtonColor" :loading="uploadLoading" :disabled="uploadLoading" @click="uploadRadiographs">Upload</v-btn>
          <v-btn color="black darken-1" text @click="closePopup">Annuller</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import api from '../../api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'OverviewXrayUpload',

    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        dialog: false,
        uploadLoading: false,
        uploadButtonColor: 'primary',
        xrayNewType: 0,
        xrayFiles: [],
      };
    },

    computed: {
      xrayFileTypes: function () {
        return this.$store.state.xrayFileTypes;
      },
      availableFileTypes: function() {
        return this.xrayNewType === 4 ? '.dcm' : '.jpg,.jpeg,.gif,.png,.bmp'
      },
    },

    methods: {
      closePopup: function () {
        this.xrayNewType = 0;
        this.xrayFiles = [];
        this.dialog = false;
      },
      xrayFileTrigger: function () {
        this.$refs.fileUploader.click();
      },
      xrayAddFile: function (data) {
        if (data.target.files.length > 0) {
          let item = {};
          let fileType = data.target.files[0].name.split('.').pop().toLowerCase();
          switch (fileType) {
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'png':
            case 'bmp':
            case 'dcm':
              item.id = crypto.randomUUID();
              item.file = data.target.files[0];
              item.type = this.xrayNewType;
              this.xrayFiles.push(item);
              break;
            default:
              methods.showGlobalSnackbar('error', 'Denne filtype er ikke godkendt i dette felt.');
          }
          data.target.value = '';
          this.xrayNewType = 0;
        }
      },
      xrayRemoveFile: function (index) {
        this.$delete(this.xrayFiles, index);
      },
      uploadRadiographs: async function () {
        try {
          let newData = new FormData();
          let xrayTypesArray = [];
          if (this.xrayFiles.length > 0) {
            this.xrayFiles.forEach((item) => {
              xrayTypesArray.push(item.type);
              newData.append(`Radiographs`, item.file);
            });
            newData.append('RadiographFileTypes', JSON.stringify(xrayTypesArray));
          }
          this.uploadLoading = true;
          await api.uploadRadiographs(newData, this.visitItem.id);
          let newVisit = await api.getVisitInfo(this.visitItem.id);
          this.uploadLoading = false;
          this.xrayNewType = 0;
          this.xrayFiles = [];
          this.dialog = false;
          this.$emit('updateVisitItem', newVisit.data);
          this.$emit('updateCommonVisitInfo', newVisit.data);
        } catch (e) {
          this.uploadLoading = false;
          this.uploadButtonColor = 'error';
          if (e.response.status === 409) {
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
    },
  };
</script>
