export default {
  namespaced: true,
  state: {
    tableLoading: false,
    filterParams: {},
    showDataTable: false,
    statusTypes: [
      {
        label: 'Afventer visitering',
        key: 0,
        color: 'color-gray',
      },
      {
        label: 'Visiteret haster',
        key: 1,
        color: 'color-monza',
      },
      {
        label: 'Visiteret',
        key: 2,
        color: 'color-corn',
      },
      {
        label: 'Visiteret til GA',
        key: 14,
        color: 'color-portage',
      },
      {
        label: 'Klar til indkaldelse',
        key: 3,
        color: 'color-lima',
      },
      {
        label: 'Afventer yderligere materiale',
        key: 4,
        color: 'color-blaze-orange',
      },
      {
        label: 'Indkaldt, afventer opring',
        key: 5,
        color: 'color-bilbao',
      },
      {
        label: 'Indkaldt 2 gang, afventer',
        key: 9,
        color: 'color-lavender',
      },
      {
        label: 'SMS - aflyst tid',
        key: 13,
        color: 'color-ultramarine',
      },
      {
        label: 'Ønsker ikke behandlingen',
        key: 8,
        color: 'color-java',
      },
      {
        label: 'I behandling',
        key: 6,
        color: 'color-lochmara',
      },
      {
        label: 'Afventer patienten ringer tilbage for tid',
        key: 12,
        color: 'color-shakespeare',
      },
      {
        label: 'Har forsøgt at kontakte via alle kanaler uden held',
        key: 11,
        color: 'color-froly',
      },
      {
        label: 'Udeblevet',
        key: 10,
        color: 'color-black',
      },
      {
        label: 'Afsluttet',
        key: 7,
        color: 'color-affair',
      },
    ],
  },
  mutations: {
    updateTableLoading(state, payload) {
      state.tableLoading = payload;
    },
    updateFilterParams(state, payload) {
      state.filterParams = payload;
    },
    updateShowDataTable(state, payload) {
      state.showDataTable = payload;
    },
  },
};
