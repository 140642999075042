<template>
  <v-alert border="left" text color="grey lighten-1 d-inline-block" class="my-0 mt-2" dense>
    <v-textarea ref="area" v-model="editText" label="Besked" auto-grow :rows="1" outlined hide-details/>
    <v-row justify="space-between" class="my-0">
      <v-col cols="12" lg="9">
        <v-btn icon class="mr-2">
          <v-icon size="22" class="grey--text text--darken-2" @click="getFileTrigger">{{ currentFiles.length > 0 ? 'mdi-plus-thick' : 'mdi-paperclip' }}</v-icon>
        </v-btn>
        <input ref="fileAttach" class="d-none" type="file" @input="addFile($event)" multiple/>
        <v-chip v-for="(fileItem, index) in currentFiles" :key="fileItem.id" class="mr-2 my-1" close small @click:close="deleteFile(index)">
          <span style="max-width: 200px" class="text-truncate d-inline-block">{{ fileItem.name }}</span>
        </v-chip>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn depressed @click="sendMessage(messageItem)" :color="buttonColor" :loading="sendLoading" :disabled="sendLoading">Send besked</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'OverviewEditArea',

    props: {
      messageItem: {
        type: Object,
      },
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        editText: '',
        currentFiles: [],
        file: null,
        sendLoading: false,
        buttonColor: 'primary',
      };
    },

    mounted: function () {
      this.messageItem.text && (this.editText = this.messageItem.text);
      this.messageItem.attachments && (this.currentFiles = [...this.messageItem.attachments]);
    },

    methods: {
      getFileTrigger: function () {
        this.$refs.fileAttach.click();
      },
      deleteFile: function (index) {
        this.$delete(this.currentFiles, index);
      },
      addFile: function (data) {
        if (data.target.files.length > 0) {
          Object.keys(data.target.files).forEach(key => {
            let fileItem = data.target.files[key]
            let newId = crypto.randomUUID();
            this.currentFiles.push({
              newFile: true,
              id: newId,
              file: fileItem,
              name: fileItem.name,
            });
          })
          data.target.value = '';
        }
      },
      sendMessage: async function () {
        try {
          let newData = new FormData();
          newData.append('Text', methods.checkLength(this.editText) ? this.editText : 'Ingen tekst');
          if (this.currentFiles.length > 0) {
            this.currentFiles.forEach((item) => {
              if (item.newFile) {
                newData.append(`attachedFiles`, item.file);
              } else {
                newData.append(`Attachments`, item.id)
              }
            });
          }
          this.sendLoading = true;
          await api.updateMessage(newData, this.visitItem.id, this.messageItem.id);
          let messages = await api.getMessages(this.visitItem.id);
          this.$emit('updateMessagesList', messages.data);
          this.sendLoading = false;
        } catch (e) {
          this.sendLoading = false;
          this.buttonColor = 'error';
        }
      },
    },
  };
</script>
