import Vue from 'vue';
import VueRouter from 'vue-router';
import Tabs from '../views/management/Tabs';
import Overview from '../views/management/Overview';
import EndingLetter from '../views/management/EndingLetter';
import Visit from '../views/management/Visit';
import VisitSent from '../views/management/VisitSent';
import DownloadPDF from '../views/DownloadPDF';
import CallUp from '../views/management/CallUp';
import Draft from '../views/management/Draft';
import ChangePassword from '../views/management/ChangePassword';
import Statistics from '../views/management/Statistics';
import Users from '../views/management/Users';
import Clinics from '../views/management/Clinics';
import Login from '../views/Login';
import ResetPassword from '../views/ResetPassword';
import Settings from '../views/management/Settings';
import Sms from '../views/management/Sms';
import Logs from '../views/management/Logs.vue';
import DownloadRadiographs from '../views/DownloadRadiographs.vue';
import DownloadLetters from '../views/DownloadLetters.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/management',
    component: Tabs,
    children: [
      {
        path: 'overview',
        component: Overview,
        meta: {title: 'Kirurgiklinik - admin - Oversigt'}
      },
      {
        path: 'call-up-letters',
        component: CallUp,
        meta: {title: 'Kirurgiklinik - admin - Indkald'}
      },
      {
        path: 'ending-letter',
        component: EndingLetter,
        meta: {title: 'Kirurgiklinik - admin - Udskrivningsbrev'}
      },
      {
        path: 'henvisning-tandlaeger',
        component: Visit,
        meta: {title: 'Kirurgiklinik - admin - Henvis'}
      },
      {
        path: 'visit-sent',
        component: VisitSent,
        meta: {title: 'Kirurgiklinik - admin - Henvis'}
      },
      {
        path: 'draft-visits',
        component: Draft,
        meta: {title: 'Kirurgiklinik - admin - Drafts'}
      },
      {
        path: 'change-password',
        component: ChangePassword,
        meta: {title: 'Kirurgiklinik - admin - Din profil'}
      },
      {
        path: 'statistics',
        component: Statistics,
        meta: {title: 'Kirurgiklinik - admin - Statistik'}
      },
      {
        path: 'users',
        component: Users,
        meta: {title: 'Kirurgiklinik - admin - Henvisere'}
      },
      {
        path: 'clinics',
        component: Clinics,
        meta: {title: 'Kirurgiklinik - admin - Klinik'}
      },
      {
        path: 'settings',
        component: Settings,
        meta: {title: 'Kirurgiklinik - admin - Indstillinger'}
      },
      {
        path: 'sms',
        component: Sms,
        meta: {title: 'Kirurgiklinik - admin - SMS'}
      },
      {
        path: 'logs',
        component: Logs,
        meta: {title: 'Kirurgiklinik - admin - Logs'}
      },
    ],
  },
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/pdf/visit',
    component: DownloadPDF,
    meta: {title: 'Kirurgiklinik - admin - Download'}
  },
  {
    path: '/xr',
    component: DownloadRadiographs,
    meta: {title: 'Kirurgiklinik - admin - Download'}
  },
  {
    path: '/dl',
    component: DownloadLetters,
    meta: {title: 'Kirurgiklinik - admin - Download'}
  },
  {
    path: '/login',
    component: Login,
    meta: {title: 'Kirurgiklinik - login henviser'}
  },
  {
    path: '/change-password',
    component: ResetPassword,
    meta: {title: 'Kirurgiklinik - admin - Nulstil adgangskode'}
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Kirurgiklinik';
  next();
});

export default router;
