import Vue from 'vue';
import Vuex from 'vuex';
import visit from './modules/visit';
import overview from './modules/overview';
import endingLetters from './modules/endingLetters';
import callUp from './modules/callUp';
import draft from './modules/draft';
import statistics from './modules/statistics';
import users from './modules/users';
import clinicsStore from './modules/clinicsStore';
import imports from './modules/imports';
import api from '../api/api';
import sms from './modules/sms';
import logs from './modules/logs';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    endingLetters,
    visit,
    overview,
    callUp,
    draft,
    statistics,
    users,
    clinicsStore,
    imports,
    sms,
    logs,
  },
  state: {
    apiUrl: process.env.VUE_APP_URL,
    role: null,
    userData: {},
    doctors: [],
    surgeons: [],
    treatmentTypes: [],
    appointmentAlert: false,
    csvExport: false,
    csvExportValue: 0,
    csvExportId: null,
    globalSnackbar: false,
    globalSnackbarColor: 'error',
    globalSnackbarText: '',
    xrayFileTypes: [
      {
        text: 'Intraoralt',
        val: 0,
      },
      {
        text: 'Foto',
        val: 1,
      },
      {
        text: 'Panorama',
        val: 2,
      },
      {
        text: 'Cephalostat',
        val: 3,
      },
      {
        text: 'DICOM-file',
        val: 4,
      },
    ],
  },
  getters: {
    isAdmin: function (state) {
      return state.role === 'Admin' || state.role === 'Surgeon';
    },
    isSecretary: function (state) {
      return state.role === 'Secretary' || state.role === 'Dentist Secretary';
    },
    isDentist: function (state) {
      return state.role === 'Dentist';
    },
  },
  mutations: {
    changeRole(state, payload) {
      state.role = payload;
    },
    changeUserData(state, payload) {
      state.userData = payload;
    },
    changeDoctors(state, payload) {
      let newDoctors = [];
      payload.forEach(function (item) {
        newDoctors.push({
          ...item,
          name: `${item.fullName && item.fullName.trim()} ${item.clinic && item.clinic.trim() && '(klinik: ' + item.clinic.trim() + ')'}`,
        });
      });
      state.doctors = newDoctors.sort((a, b) => a.name.localeCompare(b.name));
    },
    updateSurgeons(state, payload) {
      state.surgeons = payload.sort((a, b) => a.fullName.localeCompare(b.fullName));
    },
    updateTreatmentTypes(state, payload) {
      state.treatmentTypes = payload;
    },
    updateAppointmentAlert(state, payload) {
      state.appointmentAlert = payload;
    },
    updateCsvExport(state, payload) {
      state.csvExport = payload;
    },
    updateCsvExportValue(state, payload) {
      state.csvExportValue = payload;
    },
    updateCsvExportId(state, payload) {
      state.csvExportId = payload;
    },
    changeGlobalSnackbar(state, payload) {
      state.globalSnackbar = payload;
    },
    changeGlobalSnackbarColor(state, payload) {
      state.globalSnackbarColor = payload;
    },
    changeGlobalSnackbarText(state, payload) {
      state.globalSnackbarText = payload;
    },
  },
  actions: {
    async getAccountInfo(context) {
      try {
        let {data} = await api.getAccountInfo();
        context.commit('changeUserData', data);
        context.commit('changeRole', data.role);
        context.commit('updateAppointmentAlert', data.showAppointmentFileNotUploadedAlert);
      } catch (e) {}
    },
    async getDoctors(context) {
      try {
        let {data} = await api.getDoctors();
        context.commit('changeDoctors', data);
      } catch (e) {}
    },
    async getSurgeons(context) {
      try {
        let {data} = await api.getSurgeons();
        context.commit('updateSurgeons', data);
      } catch (e) {}
    },
    async getTreatmentTypes(context) {
      try {
        let {data} = await api.getTreatmentTypes();
        context.commit('updateTreatmentTypes', data);
      } catch (e) {}
    },
  },
});
