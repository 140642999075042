<template>
  <div>
    <v-row v-if="isAdmin" class="py-0 my-0 mb-5" justify="end">
      <v-col id="sub-controls" cols="auto" class="py-0 d-flex align-center">
        <v-btn class="mr-5" color="primary" :disabled="merge.length === 0" @click="clearMerge(); closeMergeDialog()" outlined>Fortryd "Fusioneringen"</v-btn>
        <v-btn class="mr-5" color="primary" :disabled="merge.length === 0" @click="mergeDialog = true" outlined>Fusioner profilerne ({{merge.length}})</v-btn>
        <ClinicsCreateDialog :filterParams="filterParams" @updateClinicsData="setClinics($event)"/>
      </v-col>
    </v-row>
    <ClinicsMergeDialog v-if="mergeDialog" ref="mergeDialog" :mergeArray="merge" :filterParams="filterParams" @deleteMergeClinic="toggleMergeClinic($event)" @clearMerge="clearMerge" @closeMergeDialog="closeMergeDialog" @updateClinicsData="setClinics($event)" @moveToControls="moveToControls"/>
    <div class="data-section">
      <v-data-table class="data-table" :headers="tableHeaders" :items="clinics" :expanded.sync="expanded" show-expand :loading-text="loadingText" loader-height="2" :loading="tableLoading" item-key="clinicId" hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalClinics"  @update:options="getClinics">
        <template v-slot:item.name="{ item }">
          <span>{{ item.name ? item.name.trim() : '--' }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          {{statusValue(item.status)}}
        </template>
        <template v-slot:item.employeeCount="{ item }">
          <span>{{ item.employeeCount }}</span>
        </template>
        <template v-slot:item.city="{ item }">
          <span>{{ item.city ? item.city : '--' }}</span>
        </template>
        <template v-slot:item.phone="{ item }">
          <span>{{ item.phone ? item.phone : '--' }}</span>
        </template>
        <template v-slot:item.email="{ item }">
          <span>{{ item.email ? item.email : '--' }}</span>
        </template>
        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
          <v-row justify="center">
            <v-col cols="auto">
              <v-icon size="24" class="grey--text text--darken-2" @click="toggleExpand(item, expand, isExpanded)">{{isExpanded ? 'icon-close' : 'mdi-square-edit-outline'}}</v-icon>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.merge="{ item }">
          <v-row justify="center">
            <v-col cols="auto">
              <ClinicsMergeIcon :clinicItem="item" :merge="merge" @toggleMergeClinic="toggleMergeClinic(item)"/>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="height: auto;" class="px-0">
            <ClinicsExpanded :clinicItem="item" @updateCommonClinicData="updateCommonClinicData(item, $event)"/>
          </td>
        </template>
      </v-data-table>
      <div class="d-flex align-center justify-center">
        <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :disabled="tableLoading" :total-visible="7" class="py-4" @input="changeCurrentPage"/>
        <div class="d-flex align-center shrink">
          <v-text-field style="width: 70px" class="mr-2" v-model="newPage" hide-details dense outlined/>
          <v-btn color="primary" depressed @click="changeCurrentPage(parseInt(newPage))">Gå til side</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../api/api';
  import ClinicsCreateDialog from '../../components/clinics/ClinicsCreateDialog';
  import ClinicsExpanded from '../../components/clinics/ClinicsExpanded';
  import ClinicsMergeIcon from '../../components/clinics/ClinicsMergeIcon';
  import ClinicsMergeDialog from '../../components/clinics/ClinicsMergeDialog';
  import {CLINICS_STATUS_VALUES} from '@/constants/clinicsTypes';

  export default {
    name: 'ClinicsData',

    components: {ClinicsMergeDialog, ClinicsMergeIcon, ClinicsExpanded, ClinicsCreateDialog},

    data: function () {
      return {
        expanded: [],
        clinics: [],
        merge: [],
        mergeDialog: false,
        loadingText: 'Loading... Please wait',
        defaultOrderBy: 'modifyDate',
        defaultOrderByDesc: true,
        totalClinics: 0,
        totalPages: 0,
        queryParams: {
          CurrentPage: 1,
          PageSize: 20,
        },
        newPage: null
      };
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.clinicsStore.tableLoading;
      },
      filterParams: function () {
        return this.$store.state.clinicsStore.filterParams;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      tableHeaders: function () {
        if (this.isAdmin) {
          return [
            {text: 'Titel', value: 'name', width: '20%', sortable: false},
            {text: 'Status', value: 'status', width: '10%', sortable: false},
            {text: 'Henvisere', value: 'employeeCount', width: '10%', sortable: false},
            {text: 'By', value: 'city', width: '10%', sortable: false},
            {text: 'Telefon', value: `phone`, width: '10%', sortable: false},
            {text: 'Email', value: 'email', width: '25%', sortable: false},
            {value: 'data-table-expand', width: 'auto', sortable: false},
            {value: 'merge', width: 'auto', sortable: false},
          ];
        } else {
          return [
            {text: 'Title', value: 'name', width: '20%', sortable: false},
            {text: 'Status', value: 'status', width: '10%', sortable: false},
            {text: 'Members', value: 'employeeCount', width: '10%', sortable: false},
            {text: 'City', value: 'city', width: '10%', sortable: false},
            {text: 'Phone', value: `phone`, width: '10%', sortable: false},
            {text: 'Email', value: 'email', width: '30%', sortable: false},
            {value: 'data-table-expand', width: 'auto', sortable: false},
          ];
        }
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getClinics();
      },
    },

    methods: {
      changeCurrentPage: function (value) {
        this.queryParams.CurrentPage = value;
        this.getClinics();
        this.newPage = null;
      },
      setClinics: function(clinicsData) {
        this.totalPages = Math.ceil(
            clinicsData.totalEntities / this.queryParams.PageSize,
        );
        clinicsData.entities.forEach((item) => {
          item.isExpandedRow = false;
        });
        this.clinics = clinicsData.entities;
        this.totalClinics = clinicsData.totalEntities;
      },
      statusValue: function(statusValue) {
        return CLINICS_STATUS_VALUES[statusValue]
      },
      toggleExpand: function (item, expand, isExpanded) {
        expand(!isExpanded);
        item.isExpandedRow = !isExpanded;
      },
      getClinics: async function () {
        try {
          this.clinics = [];
          this.$store.commit('clinicsStore/updateTableLoading', true);
          let {data} = await api.getClinics({...this.queryParams, ...this.filterParams});
          this.setClinics(data)
          this.$store.commit('clinicsStore/updateTableLoading', false);
        } catch (e) {
          this.clinics = [];
          this.$store.commit('clinicsStore/updateTableLoading', false);
        }
      },
      toggleMergeClinic: function(newItem) {
        let checkExistIndex = this.merge.findIndex(oldItem => newItem.clinicId === oldItem.clinicId);
        if (checkExistIndex >= 0) {
          this.merge.splice(checkExistIndex, 1);
        } else {
          this.merge.push(newItem);
        }
      },
      moveToControls: async function() {
        if (window.location.hash === '#sub-controls') {
          await this.$router.replace({hash: ''});
        }
        await this.$router.replace({hash: 'sub-controls'});
      },
      closeMergeDialog: async function() {
        this.mergeDialog = false;
      },
      clearMerge: function() {
        if (this.$refs.mergeDialog) {
          this.$refs.mergeDialog.resetData();
        }
        this.merge = [];
      },
      updateCommonClinicData: function(clinicItem, newClinicData) {
        clinicItem.name = newClinicData.name;
        clinicItem.status = newClinicData.status;
        clinicItem.city = newClinicData.city;
        clinicItem.phone = newClinicData.phone;
        clinicItem.email = newClinicData.email;
      }
    }
  };
</script>
