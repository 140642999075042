<template>
  <div>
    <template>
      <v-container fluid class="mb-4">
        <p class="mb-0">Her skal du vælge, om du vil uploade dine røntgen billeder nu, eller om du sender dem separat med post eller email.<br>
          <strong>Max pr. dokument er 1 GB. Bemærk - vi tager desværre ikke imod TIFF filer. Følgende filtyper er tilladt: JPG, GIF, PNG, BMP, DCM.</strong>
        </p>
      </v-container>
      <v-form ref="attachmentform" v-model="formValid" class="blue-grey lighten-5">
        <v-container fluid v-if="isAdmin">
          <span class="mr-5"><strong>Oprettet af:</strong> {{ creatorInfo ? `${creatorInfo.creatorName}, ${creatorInfo.creatorClinic}, ${creatorInfo.creationTime}` : '...' }}</span>
          <span><strong>Sidst editeret af:</strong> {{ modifierInfo ? `${modifierInfo.modifierName}, ${modifierInfo.modifierClinic}, ${modifierInfo.modifyTime}` : '...' }}</span>
        </v-container>
        <v-container fluid class="pt-5 pb-7">
          <v-row align="center" class="my-0 mb-10">
            <v-col cols="12" lg="2" class="py-0">
              <v-subheader class="px-0 text-subtitle-1">Røntgenbilleder:</v-subheader>
            </v-col>
            <v-col cols="auto" class="py-0">
              <v-radio-group hide-details row v-model="sendType" class="my-0 pt-0">
                <v-radio class="my-2" label="Uploaded nu" :value="0" color="primary"/>
                <v-radio class="my-2" label="Sendt via post" :value="1" color="primary"/>
                <v-radio class="my-2" label="Sendt via krypteret mail/EDI" :value="2" color="primary"/>
                <v-radio class="my-2" label="Ingen ekstra materiale" :value="3" color="primary"/>
              </v-radio-group>
            </v-col>
          </v-row>
          <template v-if="sendType === 0">
            <v-row v-for="(item, index) in files" :key="item.id" align="center" class="my-0 mb-5">
              <v-col cols="12" md="3" class="py-0">
                <v-select :items="xrayFileTypes" item-text="text" item-value="val" label="Image file type:" v-model="item.type"/>
              </v-col>
              <v-col cols="8" md="5" class="py-0">
                <v-file-input :value="item.file" show-size label="File" disabled :clearable="false"/>
              </v-col>
              <v-col class="py-0">
                <v-btn icon color="primary" @click="removeFile(index)">
                  <v-icon>icon-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" class="my-0">
              <v-col cols="12" md="3" class="py-0">
                <v-select :items="xrayFileTypes" item-text="text" item-value="val" label="Image file type:" v-model="xrayNewType" :rules="[checkFiles]"/>
              </v-col>
              <v-col class="py-0">
                <div class="d-inline-block">
                  <v-btn depressed color="primary" @click="getFileTrigger">
                    Gennemse...
                  </v-btn>
                  <input ref="fileUploader" class="d-none" :accept="availableFileTypes" multiple type="file" @input="addFile($event)"/>
                </div>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-form>
      <NavigationBlock @validateInit="validate" :submitLoading="submitLoading"/>
    </template>
  </div>
</template>

<script>
  import NavigationBlock from './NavigationBlock';
  import api from '../../api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'AttachmentForm',

    components: {NavigationBlock},

    data: function () {
      return {
        submitLoading: false,
        formValid: false,
        sendType: 0,
        uploadedFileName: 'ingen fil',
        files: [],
        xrayNewType: 0,
      };
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      checkFiles: function () {
        return this.files.length > 0 || 'Add file';
      },
      visitId: function () {
        return this.$store.state.visit.visitId;
      },
      creatorInfo: function () {
        return this.$store.state.visit.creatorInfo;
      },
      modifierInfo: function () {
        return this.$store.state.visit.modifierInfo;
      },
      xrayFileTypes: function () {
        return this.$store.state.xrayFileTypes;
      },
      availableFileTypes: function() {
        return this.xrayNewType === 4 ? '.dcm' : '.jpg,.jpeg,.gif,.png,.bmp'
      },
    },

    watch: {
      sendType: function (val) {
        if (val !== 0) {
          this.files = [];
        }
      },
    },

    methods: {
      addFile: function (data) {
        if (data.target.files.length > 0) {
          Object.keys(data.target.files).forEach(key => {
            let fileItem = data.target.files[key]
            let newFileItem = {};
            let fileType = fileItem.name.split('.').pop().toLowerCase();
            switch (fileType) {
              case 'jpg':
              case 'jpeg':
              case 'gif':
              case 'png':
              case 'bmp':
              case 'dcm':
                newFileItem.id = crypto.randomUUID();
                newFileItem.file = fileItem;
                newFileItem.type = this.xrayNewType;
                this.files.push(newFileItem);
                break;
              default:
                methods.showGlobalSnackbar('error', 'Denne filtype er ikke godkendt i dette felt.');
            }
          })
          data.target.value = '';
          this.xrayNewType = 0;
        }
      },
      removeFile: function (index) {
        this.$delete(this.files, index);
      },
      getFileTrigger: function () {
        this.$refs.fileUploader.click();
      },
      validate: function () {
        this.$refs.attachmentform.validate();

        if (this.formValid) {
          this.sendInfo();
        } else {
          methods.showGlobalSnackbar('error', 'Der er felter som mangler at blive udfyldt, tjek venligst og udfyld disse.');
        }
      },
      sendInfo: async function () {
        let newData = new FormData();
        let typesArray = [];

        newData.append('VisitId', this.visitId);
        newData.append('RadiographsSendType', this.sendType);

        if (this.files.length > 0) {
          this.files.forEach((item) => {
            typesArray.push(item.type);
            newData.append(`Radiographs`, item.file);
          });
          newData.append('RadiographFileTypes', JSON.stringify(typesArray));
        } else {
          newData.append(`Radiographs`, null);
        }

        try {
          this.submitLoading = true;
          await api.sendRadiographs(newData, this.visitId);
          this.submitLoading = false;
          this.$router.push({path: '/management/visit-sent', hash: 'tabs'});
        } catch (e) {
          this.submitLoading = false;
          if (e.response.status === 409) {
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          } else {
            methods.showGlobalSnackbar('error', 'Server error. Noget gik galt. Prøv igen senere.');
          }
        }
      },
    },
  };
</script>
