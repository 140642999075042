<template>
  <div v-if="keyName === 'members'" class="merge-block__clinics-holder">
    <div v-for="clinic in mergeArray" class="merge-block__checkbox-holder">
      {{ `(${clinic.employeeCount})` }}
      <div v-for="member in clinic.employees" :style="{opacity: member.active ? 1 : 0.3}">
        <div v-if="member.name && member.name.trim()" class="text-body-1">- {{ member.name }}</div>
        <div v-else>- <v-icon>mdi-account-question</v-icon>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="merge-block__clinics-holder">
    <v-radio-group  v-model="indexValue" class="my-0 py-0" @change="changeCategoryValue" row hide-details>
      <div v-for="(clinic, index) in mergeArray" class="merge-block__checkbox-holder">
        <v-radio :value="index" class="my-0">
          <template v-slot:label>
            <div v-if="keyName === 'status'">{{ statusValue(clinic[keyName]) }}</div>
            <div v-else>{{ `${clinic[keyName] ? clinic[keyName] : ''}` }}</div>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
  import {CLINICS_STATUS_VALUES} from '@/constants/clinicsTypes';

  export default {
    name: 'ClinicsTableBox',

    props: ['keyName', 'mergeArray'],

    data: function () {
      return {
        indexValue: null,
      };
    },

    methods: {
      statusValue: function (statusValue) {
        return CLINICS_STATUS_VALUES[statusValue];
      },
      changeCategoryValue: function () {
        if (this.mergeArray[this.indexValue] !== undefined) {
          this.$emit('changeCategoryValue', this.mergeArray[this.indexValue][this.keyName]);
        }
      },
      setDefaultValue: function (index) {
        this.indexValue = index;
        this.changeCategoryValue();
      },
    },
  };
</script>
