<template>
  <div class="intro-section mb-5">
    <v-container class="py-5">
      <v-row justify="center" align="center" class="my-0">
        <v-col cols="12" md="4" class="py-0">
          <v-select :items="statusItems" item-text="value" item-value="key" label="Status" v-model="status"/>
        </v-col>
        <v-col cols="12" md="8" class="py-0">
          <v-text-field v-model="search" label="Søg" @keyup.enter="setFilterParams"/>
        </v-col>
      </v-row>
    </v-container>
    <div class="intro-section__bottom-box py-5">
      <v-row justify="end" class="my-0 mx-0 px-5">
        <v-btn depressed class="mb-2 mr-2 mb-md-0" color="primary" outlined :disabled="tableLoading" @click="clearFilters">
          <v-icon left size="25" class="mr-4">icon-close</v-icon>
          Reset filtre
        </v-btn>
        <v-btn depressed class="ml-2 mb-2 mb-md-0" color="primary" :disabled="tableLoading" @click="setFilterParams">
          <v-icon left size="22" class="mr-4">icon-search</v-icon>
          Søg
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ClinicsFilter',

    data: function () {
      return {
        search: '',
        status: 1
      };
    },

    mounted: function() {
      this.setFilterParams();
      this.$store.commit('clinicsStore/updateShowDataTable', true);
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.clinicsStore.tableLoading;
      },
      statusItems: function() {
        let defaultItem = [
          {
            key: null,
            value: 'Alle'
          }
        ];
        return [...defaultItem, ...this.$store.state.clinicsStore.statusItems];
      }
    },

    methods: {
      clearFilters: function () {
        this.search = '';
        this.status = 1;
        this.setFilterParams();
      },
      filterParams: function () {
        let params = {};
        this.search && (params.Search = this.search);
        this.status !== null && (params.Active = !!this.status);
        return params;
      },
      setFilterParams: function () {
        if (!this.tableLoading) {
          this.$store.commit('clinicsStore/updateFilterParams', this.filterParams());
        }
      },
    },
  };
</script>
