<template>
  <div>
    <div class="data-section">
      <v-data-table class="data-table" :headers="tableHeaders" :items="logs" :loading-text="loadingText" loader-height="2" :loading="tableLoading" hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalLogs" @update:options="getLogs">
        <template v-slot:item.dateTimeStamp="{ item }">
          {{ methods.getSimpleDate(item['dateTimeStamp']) }} {{ methods.getSimpleTime(item['dateTimeStamp']) }}
        </template>
        <template v-slot:item.sender="{ item }">
          <div class="d-flex align-center">
            <v-icon size="15" class="mr-2">mdi-email</v-icon>
            {{ item['sender'] }}
          </div>
        </template>
        <template v-slot:item.recipient="{ item }">
          <div v-for="mail in item['recipient'].split(',')" :key="mail" class="d-flex align-center">
            <v-icon size="15" class="mr-2">mdi-email</v-icon>
            {{ mail }}
          </div>
        </template>
        <template v-slot:item.content="{ item }">
          <v-tooltip bottom :allow-overflow="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="item['content']" v-bind="attrs" v-on="on" icon @click="openPreviewDialog(item['content'])">
                <v-icon size="20" class="grey--text text--darken-2">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Forhåndsvisning af e-mail</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="d-flex align-center justify-center">
      <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :disabled="tableLoading" :total-visible="7" class="py-4" @input="changeCurrentPage"/>
    </div>
    <v-dialog v-model="previewDialog" width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closePreviewDialog">
      <v-card>
        <v-card-text class="py-4">
          <div v-html="previewDialogContent" class="pa-3 rounded" style="white-space: pre-line;background-color: rgba(0, 0, 0, 0.05);font-size: 12px; line-height: 2; font-family: monospace, monospace;"/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="black darken-1" text @click="previewDialog = false; closePreviewDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import methods from '@/utils/methods';
  import api from '@/api/api';

  export default {
    name: 'EmailLogsData',

    data: function () {
      return {
        logs: [],
        loadingText: 'Loading... Please wait',
        totalLogs: 0,
        totalPages: 0,
        queryParams: {
          CurrentPage: 1,
          PageSize: 20,
        },
        previewDialog: false,
        previewDialogContent: null,
      };
    },

    computed: {
      methods() {
        return methods;
      },
      tableLoading: function () {
        return this.$store.state.logs.emailTableLoading;
      },
      filterParams: function () {
        return this.$store.state.logs.emailFilterParams;
      },
      tableHeaders: function () {
        return [
          { text: 'Date', value: 'dateTimeStamp', width: '15%', sortable: false },
          { text: 'Sender', value: 'sender', width: '30%', sortable: false },
          { text: 'Recipient', value: 'recipient', width: '30%', sortable: false },
          { text: 'Subject', value: 'subject', width: 'auto', sortable: false },
          { text: '', value: `content`, width: '80px', sortable: false },
        ];
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getLogs();
      },
    },

    methods: {
      openPreviewDialog: function (value) {
        this.previewDialogContent = value;
        this.previewDialog = true;
      },
      closePreviewDialog: function () {
        this.previewDialog = false;
        setTimeout(() => {
          this.previewDialogContent = null;
        }, 100);
      },
      changeCurrentPage: function (value) {
        this.queryParams.CurrentPage = value;
        this.getLogs();
      },
      getLogs: async function () {
        try {
          this.$store.commit('logs/updateEmailTableLoading', true);
          let logsData = await api.getEmailLogs({...this.queryParams, ...this.filterParams});
          this.logs = logsData.data['paginationResult'].entities;
          this.totalLogs = logsData.data['paginationResult'].totalEntities;
          this.totalPages = logsData.data['paginationResult'].totalPages;
          this.$store.commit('logs/updateEmailTableLoading', false);
        } catch (e) {
          this.$store.commit('logs/updateEmailTableLoading', false);
        }
      },
    },
  };
</script>
