import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  // icons: {
  //     values: {
  //       'my-event': 'icon-event',
  //       'my-pdf': 'icon-pdf',
  //       'my-arrow-left': 'icon-chevron-left',
  //       'my-arrow-right': 'icon-chevron-right',
  //       'my-arrow-up': 'icon-chevron-up',
  //       'my-arrow-down': 'icon-chevron-down',
  //       'my-print': 'icon-printer',
  //       'my-search': 'icon-search',
  //       'my-close': 'icon-close',
  //       'my-download': 'icon-download'
  //     },
  //   },
    theme: {
      themes: {
        light: {
          primary: '#3B6287'
        }
      }
    }
});
