<template>
  <v-container fluid class="py-6">
    <v-row align="center" justify="space-between" class="my-0">
      <v-col cols="12" lg="auto" class="py-0 mb-4 mb-lg-0">
        <span>Importer EDI henvisninger (push)</span>
      </v-col>
      <v-col cols="12" md="auto" class="py-0">
        <v-btn depressed outlined :color="importButtonColor" :loading="loading" :disabled="loading" @click="importEDI">Importer EDI</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import api from '../../api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'OverviewImportEDIVisits',

    data: function () {
      return {
        loading: false,
        importButtonColor: 'primary',
      };
    },

    methods: {
      importEDI: async function () {
        try {
          this.loading = true;
          await api.importEDIVisits();
          this.loading = false;
          methods.showGlobalSnackbar('success', 'Importen kan tage et par minutter.');
        } catch (e) {
          this.loading = false;
          this.exportButtonColor = 'error';
        }
      },
    }
  };
</script>
