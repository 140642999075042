<template>
  <v-btn :color="active ? 'primary' : 'primary lighten-2'" :ripple="false" class="mr-1 px-0" depressed width="32" height="32" min-width="32" rounded :disabled="disabled">
    <slot/>
  </v-btn>
</template>

<script>
  export default {
    name: 'StepButton',

    props: {
      active: {
        type: Boolean,
      },
      disabled: {
        type: Boolean,
      },
      stepValue: {
        type: Number,
      },
    },
  };
</script>