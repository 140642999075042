<template>
  <v-container class="px-xl-7" fluid>
    <v-row class="my-0 align-start">
      <v-col cols="12" lg="8" class="pr-lg-7">
        <v-form ref="editForm" v-model="formValid">
          <v-row class="my-0">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="title" label="Titel *" :rules="[rules.required]" @input="resetButtons" filled/>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-select :items="statusItems" item-text="value" item-value="key" label="Status" v-model="status" :disabled="!isAdmin" @change="resetButtons" filled/>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="address" label="Adresse *" :rules="[rules.required]" @input="resetButtons" filled/>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="zip" label="Postnummer *" :rules="[rules.required]" @input="resetButtons" filled/>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="city" label="By *" :rules="[rules.required]" @input="resetButtons" filled/>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="email" label="E-mail *" :rules="[rules.required, rules.email]" @input="resetButtons" filled/>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="mobilePhone" label="Mobil" @input="resetButtons" filled/>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field v-model="phone" label="Telefon *" :rules="[rules.required]" @input="resetButtons" filled/>
            </v-col>
          </v-row>
          <v-row class="my-0">
            <v-col cols="auto" class="py-0">
              <v-btn depressed :color="updateClinicButtonColor" :loading="updateLoading" :disabled="updateLoading" @click="updateClinic">Opdater Klinik</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="pa-5" style="background: #0000000f; border-radius: 5px; border: 1px solid #ccc">
          <div class="mb-10">
            <div :class="[captionClasses]">Henvisere/brugere ({{ clinicItem.employeeCount }})</div>
            <div v-if="clinicItem.employeeCount === 0" class="text-body-1 grey--text">Ingen data...</div>
            <div v-else v-for="member in clinicItem.employees" :style="{opacity: member.active ? 1 : 0.3}">
              <div v-if="member.name && member.name.trim()" class="text-body-1">- {{ member.name }}</div>
              <div v-else>-
                <v-icon>mdi-account-question</v-icon>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import validationRules from '../../utils/validationRules';
  import api from '@/api/api';
  import methods from '@/utils/methods';
  import {COMMON_TYPES} from '@/constants/commonTypes';

  export default {
    name: 'ClinicsExpanded',

    props: {
      clinicItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        clinicData: {},
        captionClasses: [...COMMON_TYPES.CAPTION_CLASSES],
        rules: {...validationRules},
        formValid: false,
        title: '',
        address: '',
        zip: '',
        city: '',
        status: null,
        email: '',
        phone: '',
        mobilePhone: '',
        updateLoading: false,
        updateClinicButtonColor: 'primary',
      };
    },

    mounted: async function () {
      this.clinicData = {...this.clinicItem};
      this.setEditableValues();
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      statusItems: function () {
        return this.$store.state.clinicsStore.statusItems;
      },
    },

    methods: {
      setEditableValues: function () {
        this.title = this.clinicData.name;
        this.address = this.clinicData.address;
        this.status = this.clinicData.status;
        this.zip = this.clinicData.zip;
        this.city = this.clinicData.city;
        this.phone = this.clinicData.phone;
        this.email = this.clinicData.email;
        this.clinicData.mobilePhone && (this.mobilePhone = this.clinicData.mobilePhone);
      },
      resetButtons: function () {
        this.updateClinicButtonColor !== 'primary' && (this.updateClinicButtonColor = 'primary');
      },
      updateClinic: async function () {
        this.$refs.editForm.validate();
        if (!this.formValid) return;
        let queryBody = {};
        queryBody.clinicId = this.clinicData.clinicId;
        queryBody.name = this.title;
        queryBody.address = this.address;
        queryBody.zip = this.zip;
        queryBody.city = this.city;
        queryBody.phone = this.phone;
        queryBody.email = this.email;
        queryBody.status = this.status;
        methods.checkLength(this.mobilePhone) && (queryBody.mobilePhone = this.mobilePhone);
        try {
          this.updateLoading = true;
          await api.updateClinic(queryBody);
          this.updateLoading = false;
          this.updateClinicButtonColor = 'success';
          this.$emit('updateCommonClinicData', queryBody);
        } catch (e) {
          this.updateLoading = false;
          this.updateClinicButtonColor = 'error';
        }
      },
    },
  };
</script>
