<template>
  <div class="mb-4">
    <div v-if="radiographFiles && radiographFiles.length > 0">
      <div v-for="radiographItem in radiographFiles">
        <div v-if="showDate && radiographItem.uploadDate" class="text-caption">
          {{ getDateFromUtc( radiographItem.uploadDate) }} {{ getTimeFromUtc( radiographItem.uploadDate) }}
        </div>
        <v-chip class="mb-2" color="grey lighten-1" @click="downloadRadiograph(visitId, radiographItem.radiographId)" @click:close="openXrayDialog(radiographItem)" :close="isAdmin">
          <span class="text-truncate" style="max-width: 220px">{{ radiographItem.fileName }}</span>
        </v-chip>
      </div>
    </div>
    <div v-else class="text-body-1 grey--text mb-4">Ingen data...</div>
    <v-dialog v-model="xrayRemoveDialog" persistent max-width="500" overlay-color="#fff" :overlay-opacity="0.7">
      <v-card>
        <v-card-title class="headline">Er du sikker på at du vil slette denne fil ?</v-card-title>
        <v-card-text class="py-3">
          <v-chip>
            <span class="text-truncate" style="max-width: 420px">{{ xrayRemoveName }}</span>
          </v-chip>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed :color="xrayRemoveButtonColor" @click="xrayRemove" :loading="xrayRemoveLoading" :disabled="xrayRemoveLoading">Ja, slet filen</v-btn>
          <v-btn color="black darken-1" text @click="xrayRemoveCancel" :disabled="xrayRemoveLoading">Annuller</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'OverviewXrayList',

    props: ['radiographFiles', 'visitId', 'showDate'],

    data: function () {
      return {
        xrayRemoveDialog: false,
        xrayRemoveId: null,
        xrayRemoveName: null,
        xrayRemoveButtonColor: '',
        xrayRemoveLoading: false,
      }
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
    },

    methods: {
      getDateFromUtc: function (date) {
        return methods.getDateFromUtc(date);
      },
      getTimeFromUtc: function (date) {
        return methods.getTimeFromUtc(date);
      },
      downloadRadiograph: function (visitId, radiographId) {
        methods.downloadURL(api.radiographFileUrl(visitId, radiographId));
      },
      openXrayDialog: function (radiographData) {
        this.xrayRemoveId = radiographData.radiographId;
        this.xrayRemoveName = radiographData.fileName;
        this.xrayRemoveButtonColor = 'primary';
        this.xrayRemoveDialog = true;
      },
      xrayRemoveCancel: function () {
        this.xrayRemoveId = null;
        this.xrayRemoveName = null;
        this.xrayRemoveDialog = false;
      },
      xrayRemove: async function () {
        this.xrayRemoveLoading = true;
        try {
          this.xrayRemoveLoading = true;
          await api.deleteRadiograph(this.visitId, this.xrayRemoveId);
          let newVisit = await api.getVisitInfo(this.visitId);
          this.xrayRemoveLoading = false;
          this.xrayRemoveId = null;
          this.xrayRemoveName = null;
          this.xrayRemoveDialog = false;
          this.$emit('updateVisitItem', newVisit.data);
          this.$emit('updateCommonVisitInfo', newVisit.data);
        } catch (e) {
          this.xrayRemoveLoading = false;
          this.xrayRemoveButtonColor = 'error';
        }
      },
    },
  };
</script>
