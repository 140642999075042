<template>
  <div>
    <div class="data-section">
      <v-data-table class="data-table" :headers="tableHeaders" :items="logs" :loading-text="loadingText" loader-height="2" :loading="tableLoading" hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalLogs" @update:options="getLogs">
        <template v-slot:item.createDate="{ item }">
          <template v-if="item['createDate']">
            {{ methods.getDateFromUtc(item['createDate']) }} {{ methods.getTimeFromUtc(item['createDate']) }}
          </template>
          <div v-else class="text-body-2 grey--text">Ingen data...</div>
        </template>
        <template v-slot:item.sendDate="{ item }">
          <template v-if="item['sendDate']">
            {{ methods.getDateFromUtc(item['sendDate']) }} {{ methods.getTimeFromUtc(item['sendDate']) }}
          </template>
          <div v-else class="text-body-2 grey--text">Ingen data...</div>
        </template>
        <template v-slot:item.patientCpr="{ item }">
          {{ item['patientCpr'] }}
        </template>
        <template v-slot:item.mobilePhone="{ item }">
          <template v-if="item['mobilePhone']">
            {{ item['mobilePhone'] }}
          </template>
          <div v-else class="text-body-2 grey--text">Ingen data...</div>
        </template>
        <template v-slot:item.status="{ item }">
          <v-tooltip bottom :allow-overflow="true">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="22" :class="[iconColor(item['status'])]">{{ iconName(item['status']) }}</v-icon>
            </template>
            <span>{{ statusText(item['status']) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.smsBodyText="{ item }">
          <v-tooltip bottom :allow-overflow="true">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="item['smsBodyText']" v-bind="attrs" v-on="on" icon @click="openPreviewDialog(item['smsBodyText'])">
                <v-icon size="20" class="grey--text text--darken-2">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Forhåndsvisning af SMS</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="d-flex align-center justify-center">
      <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :disabled="tableLoading" :total-visible="7" class="py-4" @input="changeCurrentPage"/>
    </div>
    <v-dialog v-model="previewDialog" width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closePreviewDialog">
      <v-card>
        <v-card-text class="py-4">
          <div v-html="previewDialogContent" class="pa-3 rounded" style="white-space: pre-line;background-color: rgba(0, 0, 0, 0.05);font-size: 12px; line-height: 2; font-family: monospace, monospace;"/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="black darken-1" text @click="previewDialog = false; closePreviewDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import methods from '@/utils/methods';
  import api from '@/api/api';

  export default {
    name: 'SmsLogsData',

    data: function () {
      return {
        logs: [],
        loadingText: 'Loading... Please wait',
        totalLogs: 0,
        totalPages: 0,
        queryParams: {
          CurrentPage: 1,
          PageSize: 20,
        },
        previewDialog: false,
        previewDialogContent: null,
      };
    },

    computed: {
      methods() {
        return methods;
      },
      tableLoading: function () {
        return this.$store.state.logs.smsTableLoading;
      },
      filterParams: function () {
        return this.$store.state.logs.smsFilterParams;
      },
      smsTypeList: function() {
        return this.$store.state.logs.smsTypeList;
      },
      tableHeaders: function () {
        return [
          { text: 'Oprette dato', value: 'createDate', width: '20%', sortable: false },
          { text: 'Send dato', value: 'sendDate', width: '20%', sortable: false },
          { text: 'CPR-nummner', value: 'patientCpr', width: '20%', sortable: false },
          { text: 'Mobiltelefon', value: 'mobilePhone', width: '20%', sortable: false },
          { text: 'Status', value: 'status', width: '20%', sortable: false },
          { text: '', value: `smsBodyText`, width: '80px', sortable: false },
        ];
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getLogs();
      },
    },

    methods: {
      iconName: function (status) {
        let names = {
          0: 'mdi-human-queue',
          1: 'mdi-check-circle',
          2: 'mdi-alert-circle',
        };
        return names[status];
      },
      iconColor: function (status) {
        let colors = {
          0: 'grey--text text--darken-2',
          1: 'teal--text',
          2: 'deep-orange--text text--accent-4',
        };
        return colors[status];
      },
      statusText: function (status) {
        let text = {
          0: 'I kø',
          1: 'Succes',
          2: 'Mislykkedes',
        };
        return text[status];
      },
      openPreviewDialog: function (value) {
        this.previewDialogContent = value;
        this.previewDialog = true;
      },
      closePreviewDialog: function () {
        this.previewDialog = false;
        setTimeout(() => {
          this.previewDialogContent = null;
        }, 100);
      },
      changeCurrentPage: function (value) {
        this.queryParams.CurrentPage = value;
        this.getLogs();
      },
      getLogs: async function () {
        try {
          this.$store.commit('logs/updateSmsTableLoading', true);
          let logsData = await api.getSmsLogs({...this.queryParams, ...this.filterParams});
          this.logs = logsData.data.paginationResult.entities;
          this.totalLogs = logsData.data.paginationResult.totalEntities;
          this.totalPages = logsData.data.paginationResult.totalPages;
          this.$store.commit('logs/updateSmsTableLoading', false);
        } catch (e) {
          this.$store.commit('logs/updateSmsTableLoading', false);
        }
      },
    },
  };
</script>
