<template>
  <div class="data-section">
    <v-data-table class="data-table" :headers="headers" :items="visits" show-expand :expanded.sync="expanded" :loading-text="loadingText" loader-height="2" :loading="tableLoading" item-key="id" :item-class="isTransparent" hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalVisits" :sort-by="defaultOrderBy" :sort-desc="defaultOrderByDesc" @update:options="serverSort">
      <template v-slot:item.patientFullName="{ item }">
        <span>{{ item.patientFullName ? item.patientFullName.trim() : '--' }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <CallUpStatusPin :visitItem="item"/>
      </template>
      <template v-slot:item.createDate="{ item }">
        <DateCell :date="item.createDate"/>
      </template>
      <template v-slot:item.callUpType="{ item }">
        <v-icon v-if="item.callUpType === 0" size="22" class="ml-1">mdi-at</v-icon>
        <v-icon v-if="item.callUpType === 1" size="22" class="ml-1">mdi-email</v-icon>
        <v-icon v-if="item.callUpType === 2" size="22" class="ml-1">mdi-message-text</v-icon>
      </template>
      <template v-slot:item.callUpDate="{ item }">
        <DateCell :date="item.callUpDate"/>
      </template>
      <template v-slot:item.canRecall="{ item }">
        <CallUpRecall :visitItem="item" @updateCommonInfo="updateVisitInfo(item, $event)"/>
      </template>
      <template v-slot:item.callUpStatus="{ item }">
        <v-icon v-if="item.callUpStatus === 1" size="25" class="ml-2">mdi-timer-outline</v-icon>
        <v-icon v-if="item.callUpStatus === 2" size="25" color="deep-orange accent-4" class="ml-2">mdi-timer-off</v-icon>
        <v-icon v-if="item.callUpStatus === 3" size="25" color="teal" class="ml-2">mdi-timer</v-icon>
        <v-icon v-if="item.callUpStatus === 4" size="25" color="deep-orange accent-4" class="ml-2">mdi-timer-off</v-icon>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" style="height: auto;" class="px-0">
          <CallUpExpanded :visitItem="item" @updateCommonInfo="updateVisitInfo(item, $event)"/>
        </td>
      </template>
    </v-data-table>
    <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :total-visible="7" class="py-4" :disabled="tableLoading" @input="changePageNumber"/>
    <v-divider/>
    <CallUpUpdateCSV/>
  </div>
</template>

<script>
  import CallUpExpanded from './CallUpExpanded';
  import CallUpUpdateCSV from './CallUpUpdateCSV';
  import api from '../../api/api';
  import CallUpStatusPin from './CallUpStatusPin';
  import CallUpRecall from './CallUpRecall';
  import DateCell from '../common/DateCell';

  export default {
    name: 'CallUpData',

    components: {DateCell, CallUpRecall, CallUpStatusPin, CallUpUpdateCSV, CallUpExpanded},

    data: function () {
      return {
        visits: [],
        expanded: [],
        headers: [
          {text: 'Nr.', value: 'id', width: '8%'},
          {text: 'CPR-nummer', value: 'patientCPR', width: '11%'},
          {text: 'Patient', value: 'patientFullName', width: '18%'},
          {text: 'Henv. dato', value: 'createDate'},
          {text: 'Status', value: 'status'},
          {text: 'Type', value: 'callUpType'},
          {text: 'Indkald. dato', value: 'callUpDate'},
          {text: 'Genindkald', value: 'canRecall', sortable: false},
          {text: 'Indkald', value: 'callUpStatus'},
          {value: 'data-table-expand', sortable: false},
        ],
        loadingText: 'Loading... Please wait',
        defaultOrderBy: 'createDate',
        defaultOrderByDesc: true,
        totalVisits: 0,
        totalPages: 0,
        queryParams: {
          OrderBy: null,
          OrderByAscending: null,
          CurrentPage: 1,
          PageSize: 20,
        },
      };
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.callUp.tableLoading;
      },
      filterParams: function () {
        return this.$store.state.callUp.filterParams;
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getVisits();
      },
    },

    methods: {
      isTransparent: function (item) {
        let visible = false;
        for (let index = 0; index < this.filterParams.Statuses.length; index++) {
          const element = this.filterParams.Statuses[index];
          if (element === item.status) {
            visible = true;
            break;
          }
        }
        if (!visible) return 'transparent-area';
      },
      sortOrderValue: function (val) {
        switch (val.sortBy[0]) {
          case 'id':
            return 1;
          case 'patientCPR':
            return 2;
          case 'patientFullName':
            return 3;
          case 'createDate':
            return 4;
          case 'status':
            return 6;
          case 'callUpType':
            return 7;
          case 'callUpDate':
            return 8;
          case 'callUpStatus':
            return 9;
          default:
            return 0;
        }
      },
      serverSort: function (val) {
        this.queryParams.OrderBy = this.sortOrderValue(val);
        this.queryParams.OrderByAscending = !val.sortDesc[0];
        this.getVisits();
      },
      changePageNumber: function (value) {
        this.queryParams.CurrentPage = value;
        this.getVisits();
      },
      getVisits: async function () {
        try {
          this.visits = [];
          this.expanded = [];
          this.$store.commit('callUp/updateTableLoading', true);
          let {data} = await api.getIncalls({...this.queryParams, ...this.filterParams});
          let entities = data.paginationResult.entities;
          this.totalPages = Math.ceil(
            data.paginationResult.totalEntities / this.queryParams.PageSize,
          );
          this.visits = entities;
          this.totalVisits = data.paginationResult.totalEntities;
          this.$store.commit('callUp/updateTableLoading', false);
        } catch (e) {
          this.visits = [];
          this.expanded = [];
          this.$store.commit('callUp/updateTableLoading', false);
        }
      },
      updateVisitInfo: function (visitItem, newVisitItem) {
        for (let index = 0; index < this.visits.length; index++) {
          if (this.visits[index].id === visitItem.id) {
            this.$set(this.visits, index, newVisitItem);
            break;
          }
        }
      },
    },
  };
</script>
