<template>
  <div class="login-holder py-5">
    <v-container>
      <v-row justify="center">
        <v-col cols="6">
          <v-card outlined>
            <v-card-text>
              <a href="/" class="login-holder__logo-holder">
                <img src="../images/kk-logo.svg" alt="KIRURGIKLINIK"/>
              </a>
              <v-btn href="/" icon class="login-holder__close-button">
                <v-icon size="35" color="#43688c">mdi-close</v-icon>
              </v-btn>
              <v-form ref="loginForm" v-model="loginFormValid">
                <v-text-field v-model="loginName" placeholder="Brugernavn:*" :rules="[rules.required]" @input="resetButtons" outlined/>
                <v-text-field v-model="password" placeholder="Password:*" type="password" :rules="[rules.required]" @input="resetButtons" outlined/>
                <v-btn class="mr-4" :color="loginButtonColor" :loading="loginLoading" :disabled="loginLoading" @click="logIn">Log in</v-btn>
              </v-form>
              <v-form ref="forgotForm" v-model="forgotFormValid">
                <v-divider class="my-5"/>
                <v-alert v-if="emailSent" dense text type="success" class="py-4 my-0">Tjek venligst din email</v-alert>
                <template v-else>
                  <div class="text-h5 font-weight-black mb-2 text-center" style="color: #43688c">Glemt Login ?</div>
                  <p>Indsæt dit brugernavn og vi sender et nyt password til dig.</p>
                  <v-text-field v-model="userName" label="Brugernavn" :rules="[rules.required]" @input="resetButtons" outlined/>
                  <v-alert v-if='forgetError' type="info" color="#3b6287">
                    Beklager, denne profil/email eksisterer ikke i vores system eller er inaktiveret. Tjek profilnavn/email og prøv igen, eller skriv til <a href="mailto:support@kirurgiklinik.dk" style="color: white;">support@kirurgiklinik.dk</a> hvis du har brug for hjælp.
                  </v-alert>
                  <v-btn color="primary" :color="forgotButtonColor" :loading="forgotLoading" :disabled="forgotLoading" @click="sendEmail">Send</v-btn>
                </template>
              </v-form>
              <v-divider class="my-5"/>
              <div class="text-center">
                <div class="text-h5 font-weight-black mb-2" style="color: #43688c">Ny Bruger ?</div>
                <a href="https://www.kirurgiklinik.dk/henvisning/registerdentist/"><strong>Har du aldrig henvist hos os før? så klik her ></strong></a>
              </div>
              <v-divider class="my-5"/>
              <div class="text-center">
                <div class="text-h5 font-weight-black" style="color: #43688c">Kontakt os</div>
                <div class="font-weight-black">Telefontid: man.-fre.: kl. 8:30 til 11:45</div>
                <div class="font-weight-black">Telefon: +45 70 22 35 53</div>
                <div class="font-weight-black">Support: <a href="mailto:support@kirurgiklinik.dk">&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#107;&#105;&#114;&#117;&#114;&#103;&#105;&#107;&#108;&#105;&#110;&#105;&#107;&#046;&#100;&#107;</a></div>
                <div class="font-weight-black">Email: <a href="mailto:kontor@kirurgiklinik.dk">&#107;&#111;&#110;&#116;&#111;&#114;&#064;&#107;&#105;&#114;&#117;&#114;&#103;&#105;&#107;&#108;&#105;&#110;&#105;&#107;&#046;&#100;&#107;</a></div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import validationRules from '../utils/validationRules';
  import api from '../api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'Login',

    data: function() {
      return {
        rules: {...validationRules},
        loginFormValid: false,
        forgotFormValid: false,
        loginName: '',
        password: '',
        userName: '',
        emailSent: false,
        loginLoading: false,
        loginButtonColor: 'primary',
        forgotLoading: false,
        forgetError: false,
        forgotButtonColor: 'primary',
      }
    },

    mounted: function() {
      localStorage.clear();
      localStorage.setItem('callupPopup', 'true');
    },

    methods: {
      resetButtons: function () {
        this.loginButtonColor !== 'primary' && (this.loginButtonColor = 'primary');
        this.forgotButtonColor !== 'primary' && (this.forgotButtonColor = 'primary');
      },
      logIn: async function() {
        this.$refs.loginForm.validate();
        if (!this.loginFormValid) return;
        let queryBody = {};
        queryBody.userName = this.loginName;
        queryBody.password = this.password;
        try {
          this.loginLoading = true;
          await api.signIn(queryBody);
          this.loginButtonColor = 'success';
          this.loginLoading = false;
          await this.$router.push({path: '/management/overview'});
        } catch (e) {
          methods.showGlobalSnackbar('error', 'Kontoen blev ikke fundet, eller adgangskoden er ikke korrekt.');
          this.loginButtonColor = 'error'
          this.loginLoading = false;
        }
      },
      sendEmail: async function() {
        this.$refs.forgotForm.validate();
        if (!this.forgotFormValid) return;
        let queryBody = {};
        queryBody.userName = this.userName;
        try {
          this.forgotLoading = true;
          this.forgetError = false;
          await api.forgotPassword(queryBody);
          this.forgotButtonColor = 'success';
          this.forgotLoading = false;
          this.emailSent = true;
        } catch (e) {
          this.forgotButtonColor = 'error'
          this.forgotLoading = false;
          if (e.response.status === 410) {
            this.forgetError = true;
          }
        }
      }
    }
  };
</script>
