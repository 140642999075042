export default {
  namespaced: true,
  state: {
    tableLoading: false,
    showDataTable: false,
    filterParams: {},
  },
  mutations: {
    updateTableLoading(state, payload) {
      state.tableLoading = payload;
    },
    updateFilterParams(state, payload) {
      state.filterParams = payload;
    },
    updateShowDataTable(state, payload) {
      state.showDataTable = payload;
    },
  },
};
