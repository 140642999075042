export const USERS_STATUS_TYPES = {
    PENDING_KEY: 0,
    PENDING_NAME: 'Inaktiv',
    ACTIVE_KEY: 1,
    ACTIVE_NAME: 'Aktiv',
}

export const USERS_STATUS_VALUES = {
    [USERS_STATUS_TYPES.ACTIVE_KEY]: USERS_STATUS_TYPES.ACTIVE_NAME,
    [USERS_STATUS_TYPES.PENDING_KEY]: USERS_STATUS_TYPES.PENDING_NAME
}
