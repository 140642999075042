<template>
  <v-tooltip bottom :allow-overflow="true">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" size="22" :class="iconColor">{{iconName}}</v-icon>
    </template>
    <span>{{ statusDescription }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'ImportsIcon',

    props: ['status', 'statusDescription'],

    computed: {
      iconName: function () {
        switch (this.status) {
          case 0:
            return 'mdi-check-circle';
          default:
            return 'mdi-alert-circle';
        }
      },
      iconColor: function () {
        switch (this.status) {
          case 0:
            return 'teal--text';
          case 3:
            return 'deep-orange--text text--lighten-2';
          default:
            return 'deep-orange--text text--accent-4';
        }
      },
    },
  };
</script>
