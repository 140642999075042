<template>
  <span>{{ dateValue }}</span>
</template>

<script>
  import methods from '../../utils/methods';

  export default {
    name: 'DateTimeCell',

    props: ['date'],

    computed: {
      dateValue: function () {
        return this.date ? `${methods.getDateFromUtc(this.date)} ${methods.getTimeFromUtc(this.date)}` : '--.--';
      },
    },
  };
</script>