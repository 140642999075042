<template>
  <span>{{ dateValue }}</span>
</template>

<script>
  import methods from '@/utils/methods';

  export default {
    name: "DateTimeCellSimple",

    props: ['date'],

    computed: {
      dateValue: function () {
        return this.date ? `${methods.getSimpleDate(this.date)} ${methods.getSimpleTime(this.date)}` : '--.--';
      },
    },
  };
</script>
