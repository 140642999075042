<template>
  <v-row class="my-0 mb-5">
    <v-col class="py-0">
      <HeadingBox icon="mdi-bell" title="Indkaldelse" :loading="logsLoading">
        <v-alert v-if="!logsLoading && (visitItem['callUpStatus'] === 1 || visitItem['callUpStatus'] === 2 || visitItem['callUpStatus'] === 4)" dense text border="left" type="info" :color="callupStatusColor">{{ callupStatusText }}</v-alert>
        <template v-if="logList.length > 0">
          <div v-for="logItem in logList" class="mb-2 d-flex flex-wrap align-center">
            <v-icon v-if="logItem['callUpStatus'] === 2 || logItem['callUpStatus'] === 4" size="20" class="mr-4 red--text">mdi-alert-circle</v-icon>
            <span class="text-body-1 mr-4 text-no-wrap">{{ logItem.formattedDate }}</span>
            <v-icon v-if="logItem['incallType'] === 0" size="20" class="mr-2">mdi-at</v-icon>
            <v-icon v-if="logItem['incallType'] === 1" size="20" class="mr-2">mdi-email</v-icon>
            <v-icon v-if="logItem['incallType'] === 2" size="20" class="mr-2">mdi-message-text</v-icon>
            <v-btn v-if="logItem['pdfFileName']" icon @click.prevent="downloadIncallLogPdf(logItem['visitLogId'])">
              <v-icon size="25">icon-pdf</v-icon>
            </v-btn>
            <v-btn v-if="logItem['incallEmailContent']" icon @click="openPreviewDialog(logItem['incallEmailContent'])">
              <v-icon size="20" class="grey--text text--darken-2">mdi-eye</v-icon>
            </v-btn>
          </div>
        </template>
        <span v-if="logList.length === 0" class="text-body-1 grey--text">Ingen data...</span>
      </HeadingBox>
      <v-dialog v-model="previewDialog" width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closePreviewDialog">
        <v-card>
          <v-card-text class="py-4">
            <div v-html="previewDialogContent" class="pa-3 rounded" style="white-space: pre-line;background-color: rgba(0, 0, 0, 0.05);font-size: 12px; line-height: 2; font-family: monospace, monospace;"/>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="black darken-1" text @click="previewDialog = false; closePreviewDialog()">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';
  import HeadingBox from '../common/HeadingBox';

  export default {
    name: 'CallUpLogs',
    components: {HeadingBox},
    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        logsLoading: false,
        logList: [],
        previewDialog: false,
        previewDialogContent: null
      };
    },

    mounted: function () {
      this.getIncallLogs();
    },

    computed: {
      callupStatusColor: function () {
        switch (this.visitItem['callUpStatus']) {
          case 2:
            return 'deep-orange accent-4';
          case 4:
            return 'deep-orange accent-4';
          default:
            return 'grey darken-2';
        }
      },
      callupStatusText: function () {
        switch (this.visitItem['callUpStatus']) {
          case 2:
            return 'Nogle incalls har fejl.';
          case 4:
            return 'Indkald ikke mulig pga. hemlig adresse.';
          default:
            return 'Incall er planlagt.';
        }
      },
    },

    methods: {
      openPreviewDialog: function (value) {
        this.previewDialogContent = value;
        this.previewDialog = true;
      },
      closePreviewDialog: function () {
        this.previewDialog = false;
        setTimeout(() => {
          this.previewDialogContent = null;
        }, 100);
      },
      downloadIncallLogPdf: function (visitLogId) {
        methods.downloadURL(api.incallLogsFileUrl(visitLogId));
      },
      getIncallLogs: async function () {
        this.logsLoading = true;
        let logsData = await api.getIncallLogs(this.visitItem.id);
        setTimeout(() => {
          this.setIncallList(logsData);
          this.logsLoading = false;
        }, 500);
      },
      setIncallList: function (data) {
        let newData = data;
        newData.data.length > 0 && newData.data.forEach((item) => {
          item.formattedDate = `${methods.getSimpleDate(item['incallDate'])}, ${methods.getSimpleTime(item['incallDate'])}`;
        });
        this.logList = newData.data;
      },
    },
  };
</script>
