<template>
  <div class="d-inline-block">
    <v-btn depressed small :loading="loading" :disabled="loading" :color="buttonColor" :outlined="outlined" @click="uploadTrigger">
      UPLOAD
    </v-btn>
    <input ref="lettersUploader" class="d-none" type="file" @input="fileChanged($event)"/>
  </div>
</template>
<script>
  import api from '../../api/api';

  export default {
    name: 'UploadDocumentsButton',

    props: {
      visitItem: {
        type: Object,
      },
      number: {
        type: Number,
      },
      type: {
        type: String,
      },
    },

    data: function () {
      return {
        loading: false,
        buttonColor: 'primary',
        outlined: true,
      };
    },

    methods: {
      uploadTrigger: function () {
        this.$refs.lettersUploader.click();
      },
      fileChanged: async function (event) {
        let docType = this.type === 'doc' ? 'diagnose' : 'rentgen';
        let fileData = new FormData();
        fileData.append('file', event.target.files[0]);
        event.target.value = '';
        try {
          this.loading = true;
          await api.sendTerminationLettersData(this.visitItem.id, docType, this.number, fileData);
          let newVisit = await api.getTerminationLetters({VisitNumber: this.visitItem.id});
          this.loading = false;
          this.outlined = false;
          this.buttonColor = 'green lighten-2';
          this.$emit('updateVisitItem', newVisit.data.visits[0]);
        } catch (e) {
          this.loading = false;
          this.outlined = false;
          this.buttonColor = 'error';
        }
      },
    },
  };
</script>
