<template>
  <div class="visit-wrapper">
    <v-container class="management-tabs__container py-0 grow">
      <StepsHeading ref="anchor" id="step-heading"/>
      <PatientForm v-show="activeStep === 1"/>
      <VisitForm v-show="activeStep === 2"/>
      <AttachmentForm v-show="activeStep === 3"/>
    </v-container>
    <div class="warning">
      <v-container class="management-tabs__container py-0">
        <v-alert type="warning" dense tile class="my-0 px-0">
          Denne side er SSL krypteret, hvilket betyder at alt data vil blive sendt krypteret over nettet til vores server, så ingen andre kan få adgang til jeres data.
        </v-alert>
      </v-container>
    </div>
  </div>
</template>

<script>
  import VisitForm from '../../components/visit/VisitForm';
  import AttachmentForm from '../../components/visit/AttachmentForm';
  import PatientForm from '../../components/visit/PatientForm';
  import StepsHeading from '../../components/visit/StepsHeading';
  import api from '../../api/api';
  import methods from '../../utils/methods';

  export default {
    name: 'Visit',

    components: {
      StepsHeading,
      VisitForm,
      PatientForm,
      AttachmentForm,
    },

    mounted: function () {
      // Google Analytics
      ga('set', 'page', 'management/henvisning-tandlaeger');
      ga('send', 'pageview');
      // End Google Analytics
    },

    computed: {
      activeStep: function () {
        return this.$store.state.visit.activeStep;
      },
      visitId: function () {
        return this.$store.state.visit.visitId;
      },
    },

    watch: {
      visitId: async function (val) {
        if (val) {
          try {
            let {data} = await api.getDoctorInfo(val);
            this.setCreatorInfo(data);
            this.setModifierInfo(data);
          } catch (e) {}
        } else {
          this.$store.commit('visit/changeCreatorInfo', null);
          this.$store.commit('visit/changeModifierInfo', null);
        }
      },
    },

    methods: {
      setCreatorInfo: function (data) {
        let obj = {};
        obj.creatorName = data.creatorName ? data.creatorName : '...';
        obj.creatorClinic = data.creatorClinic ? data.creatorClinic : '...';
        obj.creationTime = data.creationTime ? `${methods.getDateFromUtc(data.creationTime)} ${methods.getTimeFromUtc(data.creationTime)}` : '...';
        this.$store.commit('visit/changeCreatorInfo', obj);
      },
      setModifierInfo: function (data) {
        let obj = {};
        obj.modifierName = data.modifierName ? data.modifierName : '...';
        obj.modifierClinic = data.modifierClinic ? data.modifierClinic : '...';
        obj.modifyTime = data.modifyTime ? `${methods.getDateFromUtc(data.modifyTime)} ${methods.getTimeFromUtc(data.modifyTime)}` : '...';
        this.$store.commit('visit/changeModifierInfo', obj);
      },
    }
  };
</script>
