<template>
  <v-row class="py-0 my-0 mb-5" justify="end">
    <v-col cols="auto" class="py-0">
      <v-btn class="mr-5" depressed color="primary" @click="openCreateDialog">Opret bruger</v-btn>
      <v-dialog v-if="createDialog" v-model="createDialog" scrollable width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closeCreateDialog">
        <v-card>
          <v-card-title>
            <v-row justify="center">
              <v-col cols="auto">
                <v-radio-group hide-details row v-model="userType" class="my-0 pt-0">
                  <v-radio v-if="isAdmin" class="my-2 mr-14" label="Admin" :value="0" color="primary"/>
                  <v-radio class="my-2 mr-0" label="Henviser / Tandlæge sekretær" :value="1" color="primary"/>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mb-5"/>
          <v-card-text class="py-4">
            <v-form ref="createForm" v-model="formValid">
              <v-row class="my-0 mb-6">
                <v-col v-if="userType === 0" cols="12" md="6" class="py-0">
                  <div class="d-flex justify-start">
                    <v-checkbox :class="['my-0', 'py-0']" v-model="isSurgeon" label="Surgeon" hide-details />
                  </div>
                </v-col>
                <v-col v-if="userType === 1" cols="12" md="6" class="py-0">
                  <div class="d-flex justify-start">
                    <v-checkbox :class="['my-0', 'py-0']" v-model="secretary" label="Tandlæge sekretær" hide-details />
                  </div>
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="firstName" label="Fornavn *" :rules="[rules.required]" filled/>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="lastName" label="Efternavn *" :rules="[rules.required]" filled/>
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="userName" label="Brugernavn *" :rules="[rules.required]" filled/>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-select :items="statusItems" item-text="value" item-value="key" label="Status" v-model="status" filled/>
                </v-col>
              </v-row>
              <v-row v-if="userType === 0" class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="email" label="E-mail *" :rules="[rules.required, rules.email]" filled/>
                </v-col>
              </v-row>
              <v-row v-if="userType === 1" class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="hemviserId" label="Henviser id" filled/>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <div class="d-flex justify-start">
                    <v-autocomplete :items="activeClinics" item-text="name" item-value="id" label="Klinik *" v-model="clinicId" :rules="[rules.requiredId]" :loading="activeClinicsLoading" filled/>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="userType === 1" class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="email" label="E-mail *" :rules="[rules.required, rules.email]" filled/>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="mobilePhone" label="Mobil" filled/>
                </v-col>
              </v-row>
              <v-row v-if="userType === 1" class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field v-model="phone" label="Telefon" filled/>
                </v-col>
              </v-row>
              <v-row class="my-0">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="password" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.minLength_7, rules.digit, rules.lowerCase, rules.upperCase]" :type="passwordShow ? 'text' : 'password'" label="Indtast password *" counter filled @click:append="passwordShow = !passwordShow"/>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="passwordConfirm" :append-icon="passwordConfirmShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRepeatRule]" :type="passwordConfirmShow ? 'text' : 'password'" label="Gentag password *" filled @click:append="passwordConfirmShow = !passwordConfirmShow"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" :disabled="createLoading" :loading="createLoading" @click="createUser">Opret</v-btn>
            <v-btn color="black darken-1" text :disabled="createLoading" @click="closeCreateDialog">Annuller</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import validationRules from '../../utils/validationRules';
  import methods from '../../utils/methods';
  import api from '@/api/api';

  export default {
    name: 'UsersCreateDialog',

    props: {
      queryParams: {
        type: Object,
      },
      filterParams: {
        type: Object,
      },
    },

    data: function() {
      return {
        rules: {...validationRules},
        formValid: false,
        createDialog: false,
        userType: 1,
        secretary: false,
        isSurgeon: false,
        firstName: '',
        lastName: '',
        userName: '',
        status: 1,
        email: '',
        clinicId: null,
        hemviserId: '',
        phone: '',
        mobilePhone: '',
        password: '',
        passwordShow: false,
        passwordConfirm: '',
        passwordConfirmShow: false,
        createLoading: false
      }
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      userData: function () {
        return this.$store.state.userData;
      },
      passwordRepeatRule: function () {
        return this.password === this.passwordConfirm || 'Passwords matcher ikke';
      },
      activeClinics: function () {
        return this.$store.state.users.activeClinics;
      },
      activeClinicsLoading: function() {
        return this.$store.state.users.activeClinicsLoading;
      },
      statusItems: function() {
        return this.$store.state.users.statusItems;
      }
    },

    mounted: function() {
      if (this.isSecretary) {
        this.clinicId = this.userData.clinicId
      }
    },

    methods: {
      openCreateDialog: function() {
        this.createDialog = true;
      },
      closeCreateDialog: function() {
        this.createDialog = false;
        this.resetForm();
      },
      resetForm: function() {
        this.userType = 1;
        this.firstName = '';
        this.lastName = '';
        this.userName = '';
        this.status = 1;
        this.secretary = false;
        this.isSurgeon = false;
        this.email = '';
        if (this.isSecretary) {
          this.clinicId = this.userData.clinicId
        } else {
          this.clinicId = null
        }
        this.hemviserId = '';
        this.phone = '';
        this.mobilePhone = '';
        this.password = '';
        this.passwordConfirm = '';
      },
      createUser: async function() {
        this.$refs.createForm.validate();
        if (!this.formValid) return;
        let queryBody = {};
        let newUsersData = {};
        if (this.userType === 0) {
          queryBody.firstName = this.firstName;
          queryBody.lastName = this.lastName;
          queryBody.userName = this.userName;
          queryBody.email = this.email;
          queryBody.password = this.password;
          queryBody.passwordConfirm = this.passwordConfirm;
          queryBody.status = this.status;
          queryBody.isSurgeon = this.isSurgeon;
        } else {
          queryBody.firstName = this.firstName;
          queryBody.lastName = this.lastName;
          queryBody.userName = this.userName;
          queryBody.email = this.email;
          queryBody.password = this.password;
          queryBody.passwordConfirm = this.passwordConfirm;
          queryBody.status = this.status;
          queryBody.isSecretary = this.secretary;
          methods.checkLength(this.phone) && (queryBody.phone = this.phone);
          methods.checkLength(this.mobilePhone) && (queryBody.mobilePhone = this.mobilePhone);
          methods.checkLength(this.hemviserId) && (queryBody.dentistExternalId = this.hemviserId);
          this.clinicId && this.clinicId !== 0 && (queryBody.clinicId = this.clinicId);
        }
        try {
          this.createLoading = true;
          if (this.userType === 0) {
            await api.createAdmin(queryBody);
          } else {
            await api.createUser(queryBody);
          }
          newUsersData = await api.getUsers({...this.queryParams, ...this.filterParams});
          this.$store.dispatch('getDoctors');
          this.createLoading = false;
          this.$emit('updateUsersData', newUsersData.data);
          this.closeCreateDialog();
        } catch (e) {
          this.createLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      }
    },
  };
</script>
