<template>
  <v-icon size="24" :class="iconColor" @click="toggleMerge">{{'mdi-merge'}}</v-icon>
</template>

<script>
  export default {
    name: 'ClinicsMergeIcon',

    props: {
      clinicItem: {
        type: Object,
      },
      merge: {
        type: Array,
      },
    },

    computed: {
      iconColor: function() {
        if (this.merge.findIndex(oldItem => this.clinicItem.clinicId === oldItem.clinicId) >= 0) {
          return 'red--text text--lighten-2'
        } else {
          return 'grey--text text--darken-2'
        }
      }
    },

    methods: {
      toggleMerge: function() {
        this.$emit('toggleMergeClinic');
      }
    }
  };
</script>
