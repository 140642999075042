<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="400" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closeDialog">
      <v-card>
        <v-card-title class="headline">Skift henviser (samme klinik)</v-card-title>
        <v-card-text class="py-4">
          <v-autocomplete :items="doctors" item-value="id" item-text="name" label="Henviser" :disabled="editLoading" v-model="selectedDoctorId"/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed color="primary" @click="changeDoctorId" :loading="editLoading" :disabled="editLoading">Gem</v-btn>
          <v-btn color="black darken-1" text @click="closeDialog" :disabled="editLoading">Annuller</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'OverviewEditDentistDialog',

    props: ['dialog', 'doctorId', 'visitId', 'clinicName'],

    data: function () {
      return {
        editLoading: false,
        dentistDialog: false,
        selectedDoctorId: null
      };
    },

    watch: {
      dialog: function (val) {
        val && this.doctorId && (this.selectedDoctorId = this.doctorId)
      },
    },

    computed: {
      doctors: function () {
        let allDoctors = this.$store.state.doctors;
        let filteredDoctors = allDoctors.filter(doctor => doctor.clinic === this.clinicName);
        return filteredDoctors ? filteredDoctors : [];
      },
    },

    methods: {
      closeDialog: function () {
        this.$emit('closeDialog');
      },
      changeDoctorId: async function () {
        let queryData = [
          {
            op: 'replace',
            path: '/doctorId',
            value: this.selectedDoctorId,
          },
        ];
        try {
          this.editLoading = true;
          await api.updateVisitPatch(queryData, this.visitId, null);
          let newVisit = await api.getVisitInfo(this.visitId);
          this.editLoading = false;
          this.$emit('updateVisitItem', newVisit.data);
          this.$emit('updateCommonVisitInfo', newVisit.data);
          this.$emit('closeDialog');
        } catch (e) {
          if (e.response.status === 417) {
            methods.showGlobalSnackbar('error', 'Forbudt at tildele besøg til en anden klinik.');
          }
          this.editLoading = false;
        }
      },
    },
  };
</script>
