<template>
  <div>
    <v-row justify="end" class="my-0">
      <v-col cols="auto">
        <span class="text-caption d-inline-block grey--text text--darken-2 mr-4 my-2">{{ `Sidst redigeret: ${modifyDate}` }}</span>
        <v-btn color="primary" depressed outlined :loading="logLoading" :disabled="logLoading" @click="getLog">
          Vis log
          <v-icon right size="22" class="ml-2">{{ !showLogs ? 'icon-chevron-down' : 'icon-chevron-up' }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-for="logItem in logs">
      <v-alert dense outlined color="grey">
        <v-row class="my-0 py-0 mb-2">
          <v-col class="py-0">
            <span class="text-caption grey--text text--darken-4 mr-2">{{ logItem.modifierName }}</span> <span class="text-caption grey--text text--darken-2">{{ logItem.formattedDate }}</span>
          </v-col>
        </v-row>
        <v-row justify="space-between" class="my-0">
          <v-col cols="12" md="3" class="py-0 mb-2 mb-md-0">
            <div :class="[logCaptionClasses]">Status</div>
            <div class="d-flex align-center">
              <span :class="[logItem.statusInfo.color, 'status-label', 'mr-2']"/><span :class="['grey--text', 'text--darken-2']">{{ logItem.statusInfo.label }}</span>
            </div>
          </v-col>
          <v-col cols="12" md="3" class="py-0 mb-2 mb-md-0">
            <div :class="[logCaptionClasses]">Behadlingstype</div>
            <div :class="[logValueClasses]">{{ logItem.treatmentType ? logItem.treatmentType : '--' }}</div>
          </v-col>
          <v-col cols="12" md="3" class="py-0 mb-2 mb-md-0">
            <div :class="[logCaptionClasses]">Surgeon comment</div>
            <div :class="[logValueClasses]">{{ logItem.surgeonComment ? logItem.surgeonComment : '--' }}</div>
          </v-col>
          <v-col cols="12" md="3" class="py-0 mb-2 mb-md-0">
            <div :class="[logCaptionClasses]">Log type</div>
            <div :class="[logValueClasses]">{{ logItem.logType }}</div>
          </v-col>
        </v-row>
      </v-alert>
    </template>
  </div>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'OverviewLogs',

    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        logCaptionClasses: ['text-caption', 'd-block', 'grey--text', 'text--darken-2'],
        logValueClasses: ['d-block', 'grey--text', 'text--darken-4'],
        logLoading: false,
        showLogs: false,
        logs: [],
      };
    },

    computed: {
      statusTypes: function () {
        return this.$store.state.overview.statusTypes;
      },
      modifyDate: function () {
        if (this.visitItem.modifyDate) {
          return `${methods.getDateFromUtc(this.visitItem.modifyDate)}, ${methods.getTimeFromUtc(this.visitItem.modifyDate)}`;
        } else {
          return 'Ingen data...';
        }
      },
    },

    methods: {
      getLog: async function () {
        this.showLogs = !this.showLogs;
        if (this.showLogs) {
          this.logLoading = true;
          let newLogs = await api.getLog(this.visitItem.id);
          this.logLoading = false;
          newLogs.data.forEach((logItem) => {
            logItem.formattedDate = `${methods.getDateFromUtc(logItem.modifyDate)}, ${methods.getTimeFromUtc(logItem.modifyDate)}`;
            for (let index = 0; index < this.statusTypes.length; index++) {
              const statusElement = this.statusTypes[index];
              if (statusElement.key === logItem.status) {
                logItem.statusInfo = statusElement;
                break;
              }
            }
          });
          newLogs.data.reverse();
          this.logs = newLogs.data;
        } else {
          this.logs = [];
        }
      },
    },
  };
</script>