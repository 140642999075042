<template>
  <div class="download-letters py-5">
    <v-container>
      <v-row justify="center">
        <v-col cols="9">
          <v-card outlined>
            <v-card-text>
              <a href="/" class="download-letters__logo-holder">
                <img src="../images/kk-logo.svg" alt="KIRURGIKLINIK"/>
              </a>
              <div class="text-center mb-5" style="font-size: 15px; line-height: 1.2">Bemærk filerne gemmes i appen Arkiver på iPhone, og appen Downloads på android.</div>
              <v-alert v-if="expired" type="info" color="#3b6287">
                Kære patient,
                <br>Linket til at downloade dine røntgen er desværre udløbet. Hvis du ønsker et nyt link, så kontakt os venligst på: <a href="mailto:kontakt@kirurgiklinik.dk" style="color: white;">kontakt@kirurgiklinik.dk</a>
                <br>
                <br>Med venlig hilsen
                <br>Kirurgiklinikken
              </v-alert>
              <v-btn v-else block depressed x-large color="primary" :disabled="zipLoading" :loading="zipLoading" @click.stop="downloadZip">
                <v-icon left size="22">icon-download</v-icon>
                Alle filer - ZIP
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'DownloadLetters',

    metaInfo: {
      meta: [
        {
          name: 'robots',
          content: 'noindex'
        }
      ]
    },

    data: function () {
      return {
        expired: false,
        zipLoading: false,
      };
    },

    methods: {
      getToken: function () {
        return methods.getUrlParameter('tk');
      },
      downloadZip: async function () {
        try {
          this.zipLoading = true;
          let fileData = await api.downloadLetterFilesZip(this.getToken());
          const url = window.URL.createObjectURL(new Blob([fileData.data]));
          methods.downloadBlob(url, 'Kirurgiklinik.zip');
          this.zipLoading = false;
        } catch (e) {
          if (e.response.status === 401 || e.response.status === 403) {
            this.expired = true;
          }
          this.zipLoading = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .download-letters {
    background: #233241;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__icon-holder {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 0 10px;
    }

    &__logo-holder {
      display: block;
      width: 150px;
      margin: 15px auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
</style>
