<template>
  <div class="reset-page py-5">
    <v-container>
      <v-row justify="center">
        <v-col cols="5">
          <v-card outlined>
            <v-card-text>
              <a href="/" class="reset-page__logo-holder">
                <img src="../images/kk-logo.svg" alt="KIRURGIKLINIK"/>
              </a>
              <v-form ref="resetForm" v-model="formValid">
                <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="password" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.minLength_7, rules.digit, rules.lowerCase, rules.upperCase]" :type="passwordShow ? 'text' : 'password'" label="Indtast password" counter filled @click:append="passwordShow = !passwordShow" @change="buttonColor !== 'primary' && (buttonColor = 'primary')"/>
                <v-text-field autocomplete="new-password" :name="`new_password_${Math.random()}`" v-model="repeatPassword" :append-icon="repeatShow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRepeatRule]" :type="repeatShow ? 'text' : 'password'" label="Gentag password" filled @click:append="repeatShow = !repeatShow" @change="buttonColor !== 'primary' && (buttonColor = 'primary')"/>
                <v-btn class="mr-4" block depressed x-large :color="buttonColor" :loading="loading" :disabled="loading" @click="resetPassword">Opdater</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import validationRules from '@/utils/validationRules';
  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'ResetPassword',

    data: function() {
      return {
        rules: {...validationRules},
        formValid: false,
        password: '',
        repeatPassword: '',
        passwordShow: false,
        repeatShow: false,
        loading: false,
        buttonColor: 'primary'
      }
    },

    computed: {
      passwordRepeatRule: function () {
        return this.password === this.repeatPassword || 'Passwords matcher ikke';
      },
    },

    methods: {
      resetPassword: async function() {
        this.$refs.resetForm.validate();
        if (!this.formValid) return;
        let queryBody = {};
        queryBody.userName = methods.getUrlParameter('userName');
        queryBody.token = methods.getUrlParameter('token');
        queryBody.newPassword = this.password;
        try {
          this.loading = true;
          await api.resetPassword(queryBody);
          this.buttonColor = 'success';
          this.loading = false;
          methods.backToLogin();
        } catch (e) {
          this.buttonColor = 'error'
          this.loading = false;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .reset-page {
    background: #233241;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__logo-holder {
      display: block;
      width: 150px;
      margin: 15px auto;

      img{
        width: 100%;
        height: auto;
      }
    }
  }
</style>
