<template>
  <div>
    <v-btn class="mr-5" depressed color="primary" @click="openCreateDialog">Opret klinik</v-btn>
    <v-dialog v-if="createDialog" v-model="createDialog" scrollable width="700" overlay-color="#fff" :overlay-opacity="0.7" @click:outside="closeCreateDialog">
      <v-card>
        <v-card-text class="py-4">
          <v-form ref="createForm" v-model="formValid">
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="title" label="Titel *" :rules="[rules.required]" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select :items="statusItems" item-text="value" item-value="key" label="Status" v-model="status" filled/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="address" label="Adresse *" :rules="[rules.required]" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="zip" label="Postnummer *" :rules="[rules.required]" filled/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="city" label="By *" :rules="[rules.required]" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="email" label="E-mail *" :rules="[rules.required, rules.email]" filled/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="mobilePhone" label="Mobil" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="phone" label="Telefon *" :rules="[rules.required]" filled/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn depressed color="primary" :disabled="createLoading" :loading="createLoading" @click="createUser">Opret</v-btn>
          <v-btn color="black darken-1" text :disabled="createLoading" @click="closeCreateDialog">Annuller</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import validationRules from '@/utils/validationRules';
  import api from '@/api/api';
  import methods from '@/utils/methods';

  export default {
    name: 'ClinicsCreateDialog',

    props: {
      filterParams: {
        type: Object,
      },
    },

    data: function() {
      return {
        rules: {...validationRules},
        formValid: false,
        createDialog: false,
        title: '',
        address: '',
        zip: '',
        city: '',
        status: 1,
        email: '',
        phone: '',
        mobilePhone: '',
        createLoading: false
      }
    },

    computed: {
      statusItems: function() {
        return this.$store.state.clinicsStore.statusItems;
      }
    },

    methods: {
      openCreateDialog: function() {
        this.createDialog = true;
      },
      closeCreateDialog: function() {
        this.createDialog = false;
        this.resetForm();
      },
      resetForm: function() {
        this.title = '';
        this.address = '';
        this.zip = '';
        this.city = '';
        this.status = 1;
        this.email = '';
        this.phone = '';
        this.mobilePhone = '';
      },
      createUser: async function() {
        this.$refs.createForm.validate();
        if (!this.formValid) return;
        let queryBody = {};
        queryBody.name = this.title;
        queryBody.address = this.address;
        queryBody.zip = this.zip;
        queryBody.city = this.city;
        queryBody.phone = this.phone;
        queryBody.email = this.email;
        queryBody.status = this.status;
        methods.checkLength(this.mobilePhone) && (queryBody.mobilePhone = this.mobilePhone);
        try {
          this.createLoading = true;
          await api.createClinic(queryBody);
          let newClinicsData = await api.getClinics(this.filterParams);
          this.createLoading = false;
          this.$emit('updateClinicsData', newClinicsData.data);
          this.closeCreateDialog();
        } catch (e) {
          this.createLoading = false;
          methods.showGlobalSnackbar('error', 'Server error. Noget gik galt. Prøv igen senere.');
        }
      }
    },
  };
</script>
