<template>
  <v-app>
    <router-view/>
    <v-snackbar
      v-model="globalSnackbar"
      :color="globalSnackbarColor"
      :timeout="5000"
      :top="true"
      class="text-center justify-center"
    >
      <div class="text-center flex-grow-1"><strong>{{ globalSnackbarText }}</strong></div>
    </v-snackbar>
  </v-app>
</template>

<script>
  import methods from './utils/methods';

  export default {
    name: 'App',

    mounted: async function () {
      this.isCorrectUrl();
    },

    computed: {
      globalSnackbar: {
        get() {
          return this.$store.state.globalSnackbar;
        },
        set(newValue) {
          this.$store.commit('changeGlobalSnackbar', newValue);
        },
      },
      globalSnackbarColor() {
        return this.$store.state.globalSnackbarColor;
      },
      globalSnackbarText() {
        return this.$store.state.globalSnackbarText;
      },
    },

    methods: {
      isCorrectUrl: function () {
        if (methods.isAdminHost()) return;
        if (methods.isLocalhost()) return;
        let startHostname = window.location.hostname.slice(0, 3);
        if (startHostname !== 'www') {
          let newUrl = window.location.href.replace('//kirurgiklinik', '//www.kirurgiklinik');
          window.location.replace(newUrl);
        }
      },
    },
  };
</script>
