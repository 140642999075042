<template>
  <div v-if="visitItem">
    <v-container class="px-xl-7" fluid>
      <v-row class="my-0 align-start">
        <v-col cols="12" lg="8" class="pr-lg-7 pt-7">
          <v-btn class="mb-5" color="primary" @click="showPatientInfo = !showPatientInfo" outlined depressed>
            Patient Info
            <v-icon right size="22" class="ml-2">{{ !showPatientInfo ? 'icon-chevron-down' : 'icon-chevron-up' }}</v-icon>
          </v-btn>
          <div v-if="showPatientInfo">
            <v-row class="my-0">
              <v-col cols="5" md="3" class="py-0">
                <v-text-field v-model="cprNumber" label="CPR-Nummer *" maxlength="6" @paste.prevent="cprPasteHandler($event); resetFormButtons()" @input="resetFormButtons" filled/>
              </v-col>
              <v-col cols="5" md="3" class="py-0">
                <v-text-field ref="cprAdd" v-model="cprNumberAdd" label=" " maxlength="4" @input="resetFormButtons" filled/>
              </v-col>
              <v-col cols="12" md="auto" class="py-0 mb-6 mb-md-0 mt-md-5">
                <div class="d-flex justify-start">
                  <v-checkbox :class="['my-0', 'py-0']" v-model="testPatient" @change="resetFormButtons" hide-details>
                    <template v-slot:label>
                      Test henvisning
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="firstName" label="Fornavn *" @input="resetFormButtons" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="lastName" label="Efternavn *" @input="resetFormButtons" filled/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="address" label="Adresse" @input="resetFormButtons" filled/>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="zip" label="Postnr *" @input="resetFormButtons" filled/>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="city" label="Bynavn *" @input="resetFormButtons" filled/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="phoneNumber" label="Tlf" @input="resetFormButtons" filled/>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field v-model="mobilePhone" label="Mobil nr." @input="resetFormButtons" filled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="patientEmail" label="Patient Email" @input="resetFormButtons" filled/>
              </v-col>
            </v-row>
          </div>
          <v-form ref="patientVisitForm" v-model="formValid">
            <v-row class="my-0 mb-5">
              <template v-if="isAdmin">
                <v-col cols="12" md="4" class="py-0 mt-4">
                  <v-select v-model="visitType" :items="VISIT_TYPES_LIST" item-value="key" item-text="name" label="Henvisning type" dense hide-details/>
                </v-col>
                <v-col v-if="visitType === VISIT_TYPES.EDI_KEY" cols="12" md="3" class="py-0 mt-4">
                  <v-text-field v-model="externalId" label="EDI ID *" dense :rules="[rules.required, rules.digit]" @input="resetFormButtons"/>
                </v-col>
              </template>
              <v-col cols="12" md="3" class="py-0 mt-4">
                <v-text-field v-model="externalDoctorId" label="Tandlæge-id" dense :disabled="!isAdmin" @input="resetFormButtons"/>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-5">
              <v-col cols="12" md="6" class="py-0 mb-4 mb-md-0">
                <div :class="[captionClasses]">Henviser navn</div>
                <div v-if="visitItem.doctorFullName" class="text-body-1">
                  {{ visitItem.doctorFullName }} (<strong>klinik:</strong> {{visitItem.doctorClinic}})
                  <v-icon size="20" class="ml-3 mb-1" @click="openEditDentistDialog">mdi-square-edit-outline</v-icon>
                </div>
                <div v-else class="text-body-1 grey--text">Ingen data...</div>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div :class="[captionClasses]">Sygesikring Danmark</div>
                <div v-if="healthInsuranceType" class="text-body-1">{{ healthInsuranceType }}</div>
                <div v-else class="text-body-1">Nej</div>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-5">
              <v-col cols="12" md="6" class="py-0">
                <v-row class="my-0 py-0">
                  <v-col cols="7" class="py-0">
                    <v-menu ref="dateMenu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :value="formattedDate" class="datepicker-input" append-icon="mdi-calendar" @click:append="dateMenu = true" label="Dato" readonly :disabled="!isAdmin" v-on="on"/>
                      </template>
                      <v-date-picker v-model="date" no-title @change="dateMenu = false; resetFormButtons()" color="primary lighten-0"/>
                    </v-menu>
                  </v-col>
                  <v-col cols="5" class="py-0">
                    <v-menu ref="timeMenu" v-model="timeMenu" :close-on-content-click="false" :return-value.sync="time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="time" label="Tid" append-icon="mdi-clock-outline" @click:append="timeMenu = true" readonly :disabled="!isAdmin || !date" v-bind="attrs" v-on="on"/>
                      </template>
                      <v-time-picker v-if="timeMenu" v-model="time" format="24hr" full-width @click:minute="$refs.timeMenu.save(time)" @change="resetFormButtons"/>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3" class="py-0">
                <v-text-field label="Tidsplan" append-icon="mdi-timer-outline" v-model="operationDuration" @input="resetFormButtons" :disabled="!isAdmin"/>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-5">
              <v-col cols="12" md="6" class="py-0">
                <v-select v-if="isAdmin" :items="treatmentTypes" item-text="name" item-value="id" label="Vælg behandlingstype" v-model="treatmentType" @change="resetFormButtons">
                  <template v-slot:item="{ item, on }">
                    <v-list-item v-on="on" v-show="item.id !== -100">
                      <span>{{ item.name }}</span>
                    </v-list-item>
                  </template>
                </v-select>
                <v-text-field v-else label="Vælg behandlingstype" :value="treatmentTypeValue" disabled/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select v-if="isAdmin" :items="surgeons" item-text="fullName" item-value="id" label="Behandler" :loading="surgeonLoading" loader-height="1" :disabled="surgeonLoading" v-model="surgeon" @change="resetFormButtons"/>
                <v-text-field v-else label="Specialist" :value="visitItem.surgeonFullName" disabled/>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-5" v-if="isAdmin">
              <v-col cols="12" class="py-0">
                <v-textarea v-model="comment" @input="resetFormButtons" label="Bemærk" outlined :auto-grow="true" :rows="1"/>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-5" v-if="commonVisitInfo.status === 8 && isAdmin">
              <v-col cols="12" class="py-0">
                <v-textarea v-model="additionalComment" @input="resetFormButtons" label="Ekstra kommentar til status" outlined :auto-grow="true" :rows="1"/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" md="6" class="py-0" v-if="isAdmin">
                <v-text-field v-model="price" @input="resetFormButtons" label="Pris"/>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <div :class="[captionClasses]">Betalingst.:</div>
                <div class="text-body-1">{{ paymentType }}</div>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col cols="12" lg="4">
          <div class="pa-5" style="background: #0000000f; border-radius: 5px; border: 1px solid #ccc">
            <div class="mb-10">
              <div :class="[captionClasses]">Henvisningstype</div>
              <div v-if="kindOfVisit" class="text-body-1">{{ kindOfVisit }}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
            <div class="mb-10">
              <div :class="[captionClasses]">Journal/doc.:</div>
              <div v-if="visitItem.journalFiles && visitItem.journalFiles.length > 0">
                <div v-for="journalItem in visitItem.journalFiles">
                  <v-chip class="mb-1" color="grey lighten-1" @click="downloadJournal(visitItem.id, journalItem.journalId)">
                    <span class="text-truncate" style="max-width: 220px">{{ journalItem.fileName }}</span>
                  </v-chip>
                </div>
              </div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
            <div class="mb-10">
              <div :class="[captionClasses]">Godkendelse/Bevilling</div>
              <v-chip v-if="visitItem.appropriationFileName" color="grey lighten-1" @click="downloadAppropriation(visitItem.id)">
                <span class="text-truncate" style="max-width: 220px">{{ visitItem.appropriationFileName }}</span>
              </v-chip>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
            <div class="mb-10">
              <span :class="[captionClasses]">DICOM filer</span>
              <OverviewXrayList :radiographFiles="visitItem.radiographFiles.filter(file => file.fileType === 4)" :visitId="visitItem.id" @updateCommonVisitInfo="updateCommonVisitInfo($event)" @updateVisitItem="updateVisitItem($event)"/>
              <OverviewXrayUpload :visitItem="visitItem" @updateCommonVisitInfo="updateCommonVisitInfo($event)" @updateVisitItem="updateVisitItem($event)"/>
            </div>
            <div class="mb-10">
              <span :class="[captionClasses]">Billeder og filer</span>
              <OverviewXrayList :radiographFiles="visitItem.radiographFiles.filter(file => file.fileType !== 4)" :visitId="visitItem.id" @updateCommonVisitInfo="updateCommonVisitInfo($event)" @updateVisitItem="updateVisitItem($event)" :showDate="true"/>
              <OverviewXrayUpload :visitItem="visitItem" @updateCommonVisitInfo="updateCommonVisitInfo($event)" @updateVisitItem="updateVisitItem($event)"/>
            </div>
            <div class="mb-10">
              <div :class="[captionClasses]">Billeder Eksport Status</div>
              <div v-if="radiographExportStatus" class="text-body-1">{{ radiographExportStatus }}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </div>
            <v-btn v-if="isAdmin" depressed :color="sendSmsButtonColor" :loading="sendSmsLoading" :disabled="sendSmsLoading" @click="sendSms">Send Røntgen til patienten</v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isAdmin" class="my-0">
        <v-col v-if="visitItem.sendNotificationToDentist" cols="12" lg="auto" class="mr-lg-auto">
          <v-btn depressed :color="sendStatusColor" :loading="sendStatusLoading" :disabled="sendStatusLoading" @click="sendStatusToDentist">Send status til henviser</v-btn>
        </v-col>
        <v-col cols="12" lg="auto" class="ml-lg-auto">
          <v-btn depressed class="mr-2 my-2 my-lg-0" :color="updateVisitButtonColor" :loading="updateVisitLoading" :disabled="updateVisitLoading" @click="updateVisit">Gem ændringer</v-btn>
          <v-btn depressed outlined :color="regenerateButtonColor" :loading="regenerateLoading" :disabled="regenerateLoading" @click="regenerateVisitPdf">Regenerere Henvisning PDF</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="px-xl-7 pt-2" fluid>
      <v-divider class="mb-8"/>
      <OverviewCallUp v-if="isAdmin" :visitItem="visitItem" :patientEmail="visitItem.patientEmail" :patientMobilePhone='visitItem.patientMobilePhone' @updateCommonVisitInfo="updateCommonVisitInfo($event)" @updateVisitItem="updateVisitItem($event)"/>
      <OverviewAppointment v-if="isAdmin" :visitItem="visitItem"/>
      <OverviewLetters :visitItem="visitItem" @updateVisitItem="updateVisitItem($event)"/>
      <OverviewMessages :visitItem="visitItem" @updateCommonVisitInfo="updateCommonVisitInfo($event)" @updateVisitItem="updateVisitItem($event)"/>
      <OverviewLogs v-if="isAdmin" :visitItem="visitItem"/>
    </v-container>
    <OverviewEditDentistDialog :dialog="dentistDialog" :doctorId="visitItem.doctorId" :clinicName="visitItem.doctorClinic" :visitId="visitItem.id" @closeDialog="closeDentistDialog" @updateVisitItem="updateVisitItem($event)" @updateCommonVisitInfo="updateCommonVisitInfo($event)"/>
  </div>
  <div v-else>
    <v-progress-linear indeterminate color="blue-grey" :height="1"/>
  </div>
</template>

<script>
  import api from '../../api/api';
  import methods from '../../utils/methods';
  import HeadingBox from '../common/HeadingBox';
  import OverviewXrayUpload from './OverviewXrayUpload';
  import OverviewLogs from './OverviewLogs';
  import OverviewMessages from './OverviewMessages';
  import OverviewLetters from './OverviewLetters';
  import OverviewAppointment from './OverviewAppointment';
  import OverviewCallUp from './OverviewCallUp';
  import OverviewXrayList from './OverviewXrayList';
  import {COMMON_TYPES} from '@/constants/commonTypes';
  import OverviewEditDentistDialog from '@/components/overview/OverviewEditDentistDialog';
  import validationRules from '@/utils/validationRules';
  import {VISIT_TYPES, VISIT_TYPES_LIST} from '@/constants/visitTypes';

  export default {
    name: 'OverviewExpanded',

    components: {OverviewEditDentistDialog, OverviewXrayList, OverviewCallUp, OverviewAppointment, OverviewLetters, OverviewMessages, OverviewLogs, OverviewXrayUpload, HeadingBox},

    props: {
      commonVisitInfo: {
        type: Object,
      },
    },

    data: function () {
      return {
        visitItem: null,
        VISIT_TYPES,
        VISIT_TYPES_LIST,
        formValid: false,
        rules: {...validationRules},
        captionClasses: [...COMMON_TYPES.CAPTION_CLASSES],
        dateMenu: false,
        timeMenu: false,
        surgeonLoading: false,
        sendStatusLoading: false,
        sendStatusColor: 'primary',
        regenerateLoading: false,
        regenerateButtonColor: 'primary',
        updateVisitLoading: false,
        updateVisitButtonColor: 'primary',
        showPatientInfo: false,
        sendSmsButtonColor: 'primary',
        sendSmsLoading: false,
        firstName: '',
        lastName: '',
        address: '',
        zip: '',
        city: '',
        mobilePhone: '',
        phoneNumber: '',
        patientEmail: '',
        testPatient: false,
        cprNumber: '',
        cprNumberAdd: '',
        visitType: VISIT_TYPES.SIMPLE_KEY,
        externalId: '',
        externalDoctorId: '',
        date: null,
        time: null,
        operationDuration: '',
        treatmentType: null,
        treatmentTypeValue: '',
        surgeon: null,
        comment: '',
        additionalComment: '',
        price: '',
        dentistDialog: false,
      };
    },

    mounted: async function () {
      let visitInfo = await api.getVisitInfo(this.commonVisitInfo.id);
      this.visitItem = visitInfo.data;
      this.setEditableValues(visitInfo.data);
    },

    beforeDestroy: function () {
      let data = {};
      data.buttonColor = this.updateVisitButtonColor;
      this.$emit('collapseVisit', data);
    },

    computed: {
      role: function () {
        return this.$store.state.role;
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      treatmentTypes: function () {
        return this.$store.state.treatmentTypes;
      },
      surgeons: function () {
        return this.$store.state.surgeons;
      },
      formattedDate: function () {
        if (this.date) {
          return methods.formatDate(this.date);
        } else {
          return '';
        }
      },
      kindOfVisit: function () {
        switch (this.visitItem.referenceType) {
          case 0:
            return 'Almindelig';
          case 1:
            return 'Haste henvisning';
          default:
            return null;
        }
      },
      radiographExportStatus: function () {
        switch (this.visitItem.radiographExportStatus) {
          case null:
            return null;
          case 0:
            return 'Ingen data';
          case 1:
            return 'Planlagt';
          case 2:
            return 'Fejl i export - tjek format';
          case 3:
            return 'Arkiveret korrekt';
        }
      },
      healthInsuranceType: function () {
        switch (this.visitItem.healthInsuranceType) {
          case null:
            return null;
          case 0:
            return 'Basis';
          case 1:
            return 'Gruppe1';
          case 2:
            return 'Gruppe2';
          case 3:
            return 'Gruppe5';
        }
      },
      paymentType: function () {
        switch (this.visitItem.paymentType) {
          case 0:
            return 'Egenbet';
          case 1:
            return 'Forsikringssag';
          case 2:
            return 'Regionstandpleje';
          case 3:
            return 'BUT';
        }
      },
    },

    methods: {
      downloadJournal: function (visitId, journalId) {
        methods.downloadURL(api.journalsFileUrl(visitId, journalId));
      },
      downloadAppropriation: function (visitId) {
        methods.downloadURL(api.appropriationFileUrl(visitId));
      },
      setExternalId: function (id) {
        this.visitType = VISIT_TYPES.EDI_KEY;
        this.externalId = id;
      },
      resetFormButtons: function () {
        this.updateVisitButtonColor !== 'primary' && (this.updateVisitButtonColor = 'primary');
        this.regenerateButtonColor !== 'primary' && (this.regenerateButtonColor = 'primary');
        this.sendStatusColor !== 'primary' && (this.sendStatusColor = 'primary');
      },
      setTreatmentTypeValue: function () {
        if (this.visitItem.treatmentType === null) return;
        if (this.visitItem.treatmentType === -100) return this.treatmentTypeValue = 'Unknown treatment';
        for (let index = 0; index < this.treatmentTypes.length; index++) {
          const element = this.treatmentTypes[index];
          if (element.id === this.visitItem.treatmentType) {
            this.treatmentTypeValue = element.name;
            break;
          }
        }
      },
      getDatepickerDate: function (date) {
        let dateArray = date.split('-');
        let simpleYear = dateArray[0];
        let simpleMonth = dateArray[1];
        let simpleDay = dateArray[2].split('T')[0];
        return `${simpleYear}-${simpleMonth}-${simpleDay}`;
      },
      getTimepickerTime: function (date) {
        let dateArray = date.split('-');
        let simpleTime = dateArray[2].split('T')[1].split('.')[0];
        let [hourse, minutes] = simpleTime.split(':');
        return `${hourse}:${minutes}`;
      },
      sendStatusToDentist: async function () {
        try {
          this.sendStatusLoading = true;
          await api.sendStatusToDentist(this.visitItem.id);
          this.sendStatusLoading = false;
          this.sendStatusColor = 'success';
        } catch (e) {
          this.sendStatusLoading = false;
          this.sendStatusColor = 'error';
        }
      },
      regenerateVisitPdf: async function () {
        try {
          this.regenerateLoading = true;
          await api.visitPdf(this.visitItem.id, true);
          this.regenerateLoading = false;
          this.regenerateButtonColor = 'success';
        } catch (e) {
          this.regenerateLoading = false;
          this.regenerateButtonColor = 'error';
        }
      },
      setEditableValues: function (visitItem) {
        this.cprNumber = visitItem.cpr.slice(0, visitItem.cpr.indexOf('-'));
        this.cprNumberAdd = visitItem.cpr.slice(visitItem.cpr.indexOf('-') + 1);
        this.testPatient = visitItem.isTestVisit;
        this.firstName = visitItem.patientFirstName ? visitItem.patientFirstName : '';
        this.lastName = visitItem.patientLastName ? visitItem.patientLastName : '';
        this.address = visitItem.patientAddress ? visitItem.patientAddress : '';
        this.zip = visitItem.patientZip ? visitItem.patientZip : '';
        this.city = visitItem.patientCity ? visitItem.patientCity : '';
        this.phoneNumber = visitItem.patientPhone ? visitItem.patientPhone : '';
        this.mobilePhone = visitItem.patientMobilePhone ? visitItem.patientMobilePhone : '';
        this.patientEmail = visitItem.patientEmail ? visitItem.patientEmail : '';
        visitItem.externalId && this.setExternalId(visitItem.externalId);
        this.externalDoctorId = visitItem.externalDoctorId ? visitItem.externalDoctorId : '';
        if (visitItem.operationDate) {
          this.date = this.getDatepickerDate(visitItem.operationDate);
          this.time = this.getTimepickerTime(visitItem.operationDate);
        } else {
          this.date = null;
          this.time = null;
        }
        this.operationDuration = visitItem.operationDuration ? visitItem.operationDuration : '';
        this.treatmentType = visitItem.treatmentType >= 0 || visitItem.treatmentType === -100 ? visitItem.treatmentType : null;
        this.setTreatmentTypeValue();
        this.surgeon = visitItem.surgeonId ? visitItem.surgeonId : null;
        this.comment = visitItem.surgeonComment ? visitItem.surgeonComment : '';
        this.additionalComment = visitItem.notNeedTreatmentComment ? visitItem.notNeedTreatmentComment : '';
        this.price = visitItem.price ? visitItem.price : '';
      },
      updateVisit: async function () {
        if (!this.$refs.patientVisitForm.validate()) {
          methods.showGlobalSnackbar('error', 'Der er felter som mangler at blive udfyldt, tjek venligst og udfyld disse.');
          return
        }
        let newData = {};
        newData.makeTest = this.testPatient;
        this.firstName && (newData.patientFirstName = this.firstName);
        this.lastName && (newData.patientLastName = this.lastName);
        this.address && (newData.patientAddress = this.address);
        this.zip && (newData.patientZip = this.zip);
        this.city && (newData.patientCity = this.city);
        this.phoneNumber && (newData.patientPhone = this.phoneNumber);
        this.mobilePhone && (newData.patientMobilePhone = this.mobilePhone);
        this.patientEmail && (newData.patientEmail = this.patientEmail);
        this.visitItem.id && (newData.visitId = this.visitItem.id);
        this.visitType === VISIT_TYPES.EDI_KEY && (newData.externalId = this.externalId);
        this.externalDoctorId && (newData.externalDoctorId = this.externalDoctorId);
        this.date && (newData.operationDate = new Date(this.date).toISOString());
        if (this.time) {
          newData.operationTimeHours = parseInt(this.time.split(':')[0]);
          newData.operationTimeMinutes = parseInt(this.time.split(':')[1]);
        }
        this.cprNumber && this.cprNumberAdd && (newData.patientCpr = `${this.cprNumber}-${this.cprNumberAdd}`);
        this.treatmentType !== null && (newData.treatmentType = this.treatmentType);
        this.operationDuration && (newData.operationDuration = this.operationDuration);
        this.price && (newData.price = this.price);
        if (this.surgeon) {
          for (let index = 0; index < this.surgeons.length; index++) {
            const element = this.surgeons[index];
            if (element.id === this.surgeon) {
              newData.surgeonId = this.surgeon;
              break;
            }
          }
        }
        this.comment && (newData.surgeonComment = this.comment);
        this.additionalComment && (newData.notNeedTreatmentComment = this.additionalComment);
        this.commonVisitInfo.isDeleteAttachments && (newData.isDeleteAttachments = this.commonVisitInfo.isDeleteAttachments);
        newData.status = this.commonVisitInfo.status;
        try {
          this.updateVisitLoading = true;
          await api.updateVisit(newData);
          let newVisit = await api.getVisitInfo(this.visitItem.id);
          this.visitItem = newVisit.data;
          this.setEditableValues(newVisit.data);
          this.updateVisitLoading = false;
          this.updateVisitButtonColor = 'success';
          this.$emit('updateCommonVisitInfo', newVisit.data);
        } catch (e) {
          this.updateVisitLoading = false;
          this.updateVisitButtonColor = 'error';
        }
      },
      updateCommonVisitInfo: function (data) {
        this.$emit('updateCommonVisitInfo', data);
      },
      updateVisitItem: function (data) {
        this.visitItem = data;
        this.setEditableValues(data);
      },
      openEditDentistDialog: function () {
        this.dentistDialog = true
      },
      closeDentistDialog: function() {
        this.dentistDialog = false
      },
      cprPasteHandler: function(event) {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (pastedData?.length > 6) {
          if (pastedData.includes('-')) {
            this.cprNumber = pastedData.split('-')[0].slice(0, 6);
            this.cprNumberAdd = pastedData.split('-')[1].slice(0, 4);
          } else {
            this.cprNumber = pastedData.slice(0, 6);
            this.cprNumberAdd = pastedData.slice(6, 10);
          }
          this.$refs.cprAdd.focus();
        } else if (pastedData?.length === 6) {
          this.cprNumber = pastedData;
          this.$refs.cprAdd.focus();
        } else {
          this.cprNumber = pastedData;
        }
      },
      sendSms: async function() {
        if (!this.mobilePhone) return methods.showGlobalSnackbar('error', 'Mobilnummer er ikke udfyldt.');
        try {
          this.sendSmsLoading = true;
          this.sendSmsButtonColor = 'primary';
          await api.sendSmsWithLink(this.visitItem.id);
          this.sendSmsButtonColor = 'success';
          this.sendSmsLoading = false;
        } catch (e) {
          this.sendSmsButtonColor = 'error';
          this.sendSmsLoading = false;
        }
      }
    },
  };
</script>
