<template>
  <v-container class="management-tabs__container py-0 pb-16">
    <ProfileData/>
    <PasswordForm/>
  </v-container>
</template>

<script>
  import PasswordForm from '@/components/changePassword/PasswordForm';
  import ProfileData from '@/components/changePassword/ProfileData';

  export default {
    name: 'ChangePassword',

    components: {ProfileData, PasswordForm},

    mounted: function () {
      // Google Analytics
      ga('set', 'page', 'management/change-password');
      ga('send', 'pageview');
      // End Google Analytics
    },
  };
</script>
