<template>
  <v-container fluid class="py-6">
    <v-row align="center" justify="space-between" class="my-0">
      <v-col cols="12" lg="4" class="py-0 mb-4 mb-lg-0">
        <span>Indkaldelser PDF:</span>
      </v-col>
      <v-col class="py-0 mb-4 mb-md-0">
        <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field :value="formattedDateFrom" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuFrom = true" label="Dato" readonly hide-details v-on="on"/>
          </template>
          <v-date-picker v-model="dateFrom" no-title @change="menuFrom = false" color="primary lighten-0"/>
        </v-menu>
      </v-col>
      <v-col cols="auto" class="py-0 px-0 mb-4 mb-md-0">
        <v-icon size="15">icon-minus</v-icon>
      </v-col>
      <v-col class="py-0 mb-4 mb-md-0">
        <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field :value="formattedDateTo" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuTo = true" readonly hide-details v-on="on"/>
          </template>
          <v-date-picker v-model="dateTo" no-title @change="menuTo = false" color="primary lighten-0"/>
        </v-menu>
      </v-col>
      <v-col cols="12" md="auto" class="py-0">
        <v-btn depressed class="mb-2 mb-md-0" :color="buttonColor" :loading="loading" :disabled="loading" @click="getIncallArchive">Opdater</v-btn>
      </v-col>
    </v-row>
    <div v-if="archiveList.length > 0" class="mt-8">
      <template v-for="item in archiveList">
        <v-chip link class="mr-7 mb-4" @click="downloadArchive(item.id)">
          <v-icon size="20" left>icon-download</v-icon>
          {{ item.date }}
        </v-chip>
      </template>
    </div>
  </v-container>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';

  export default {
    name: 'CallUpUpdateCSV',

    data: function () {
      return {
        menuFrom: false,
        dateFrom: null,
        menuTo: false,
        dateTo: null,
        loading: false,
        buttonColor: 'primary',
        archiveList: [],
      };
    },

    computed: {
      formattedDateFrom: function () {
        if (this.dateFrom) {
          return methods.formatDate(this.dateFrom);
        } else {
          return 'Fra';
        }
      },
      formattedDateTo: function () {
        if (this.dateTo) {
          return methods.formatDate(this.dateTo);
        } else {
          return 'Til';
        }
      },
    },

    methods: {
      downloadArchive: function (archiveId) {
        methods.downloadURL(api.archiveFileUrl(archiveId));
      },
      getIncallArchive: async function () {
        let queryData = {};
        queryData.From = new Date(this.dateFrom).toISOString();
        queryData.To = new Date(this.dateTo).toISOString();
        try {
          this.loading = true;
          let archiveData = await api.getIncallArchive(queryData);
          archiveData.data.forEach((item) => {
            item.date = `${methods.getSimpleDate(item.date)} ${methods.getSimpleTime(item.date)}`
          });
          this.archiveList = archiveData.data;
          this.loading = false;
        } catch (e) {
          this.archiveList = [];
          this.loading = false;
          this.buttonColor = 'error';
        }
      },
    },
  };
</script>