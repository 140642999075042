<template>
  <div>
    <UsersCreateDialog :queryParams="queryParams" :filterParams="filterParams" @updateUsersData="setUsers($event)"/>
    <div class="data-section">
      <v-data-table class="data-table" :headers="tableHeaders" :items="users" :expanded.sync="expanded" show-expand :loading-text="loadingText" loader-height="2" :loading="tableLoading" item-key="userId" hide-default-footer :mobile-breakpoint="1024" :server-items-length="totalUsers"  @update:options="serverSort">
        <template v-slot:item.fullName="{ item }">
          <span>{{ item.fullName ? item.fullName.trim() : '--' }}</span>
        </template>
        <template v-slot:item.userName="{ item }">
          <span>{{ item.userName ? item.userName.trim() : '--' }}</span>
        </template>
        <template v-slot:item.clinicNames="{ item }">
          <template v-if="item.clinicNames.length > 1">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-circle-multiple</v-icon>
              </template>
              <template v-for="clinicName in item.clinicNames">
                <div v-if="clinicName && clinicName.trim()" class="text-body-1">- {{ clinicName }}</div>
                <div v-else>-
                  <v-icon color="white">mdi-help-circle</v-icon>
                </div>
              </template>
            </v-tooltip>
          </template>
          <template v-else-if="item.clinicNames.length === 1">
            <span>{{ item.clinicNames[0] }}</span>
          </template>
          <template v-else-if="item.clinicNames.length === 0">
            <div class="text-body-2 grey--text">Ingen data...</div>
          </template>
        </template>
        <template v-slot:item.roles="{ item }">
          <span>{{ item.roles.length > 0 ? item.roles[0] : '--' }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          {{statusValue(item.status)}}
        </template>
        <template v-slot:item.createDate="{ item }">
          <DateCell :date="item.createDate" />
        </template>
        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
          <v-row justify="center">
            <v-col cols="auto">
              <v-icon size="24" class="grey--text text--darken-2" @click="toggleExpand(item, expand, isExpanded)">{{isExpanded ? 'icon-close' : 'mdi-square-edit-outline'}}</v-icon>
            </v-col>
          </v-row>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" style="height: auto;" class="px-0">
            <UsersExpanded :userId="item.userId" @updateCommonUserData="updateCommonUserData(item, $event)"/>
          </td>
        </template>
      </v-data-table>
      <v-pagination color="#e0e6ec" v-model="queryParams.CurrentPage" :length="totalPages" :disabled="tableLoading" :total-visible="7" class="py-4" @input="changeCurrentPage"/>
    </div>
  </div>
</template>

<script>
  import api from '../../api/api';
  import DateTimeCell from '../../components/common/DateTimeCell';
  import DateCell from '../../components/common/DateCell';
  import UsersCreateDialog from '../../components/users/UsersCreateDialog';
  import UsersExpanded from '../../components/users/UsersExpanded';
  import {USERS_STATUS_VALUES} from '@/constants/usersTypes';

  export default {
    name: 'UsersData',
    components: {UsersExpanded, UsersCreateDialog, DateCell, DateTimeCell},

    data: function () {
      return {
        expanded: [],
        users: [],
        tableHeaders: [
          {text: 'Navn', value: 'fullName', width: '15%', sortable: false},
          {text: 'Brugernavn', value: 'userName', width: '20%'},
          {text: 'Klinik', value: 'clinicNames', width: '20%', sortable: false},
          {text: 'Rolle', value: `roles`, width: '12%', sortable: false},
          {text: 'Status', value: 'status', width: '10%', sortable: false},
          {text: 'Opdater', value: 'createDate', width: '12%'},
          {value: 'data-table-expand', sortable: false},
        ],
        loadingText: 'Loading... Please wait',
        totalUsers: 0,
        totalPages: 0,
        queryParams: {
          CurrentPage: 1,
          PageSize: 20,
        },
      };
    },

    computed: {
      tableLoading: function () {
        return this.$store.state.users.tableLoading;
      },
      filterParams: function () {
        return this.$store.state.users.filterParams;
      },
    },

    watch: {
      filterParams: function () {
        this.queryParams.CurrentPage = 1;
        this.getUsers();
      },
    },

    methods: {
      sortOrderValue: function (val) {
        switch (val.sortBy[0]) {
          case 'userName':
            return 0;
          case 'createDate':
            return 1;
        }
      },
      serverSort: function (val) {
        this.queryParams.OrderBy = this.sortOrderValue(val);
        this.queryParams.OrderByAscending = val.sortDesc[0] !== undefined ? !val.sortDesc[0] : null;
        this.getUsers();
      },
      changeCurrentPage: function (value) {
        this.queryParams.CurrentPage = value;
        this.getUsers();
      },
      statusValue: function(statusValue) {
        return USERS_STATUS_VALUES[statusValue]
      },
      setUsers: function(data) {
        this.totalPages = Math.ceil(
            data.totalEntities / this.queryParams.PageSize,
        );
        data.entities.forEach((item) => {
          item.isExpandedRow = false;
        });
        this.users = data.entities;
        this.totalUsers = data.totalEntities;
      },
      toggleExpand: function (item, expand, isExpanded) {
        expand(!isExpanded);
        item.isExpandedRow = !isExpanded;
      },
      getUsers: async function () {
        try {
          this.users = [];
          this.$store.commit('users/updateTableLoading', true);
          let {data} = await api.getUsers({...this.queryParams, ...this.filterParams});
          this.setUsers(data)
          this.$store.commit('users/updateTableLoading', false);
        } catch (e) {
          this.users = [];
          this.$store.commit('users/updateTableLoading', false);
        }
      },
      updateCommonUserData: function(userItem, newUserData) {
        userItem.fullName = `${newUserData.firstName} ${newUserData.lastName}`;
        userItem.userName = newUserData.userName;
        userItem.status = newUserData.status;
      }
    }
  };
</script>
