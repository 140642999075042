export const CLINICS_STATUS_TYPES = {
    INACTIVE_KEY: 0,
    INACTIVE_NAME: 'Inaktiv',
    ACTIVE_KEY: 1,
    ACTIVE_NAME: 'Aktiv',
}

export const CLINICS_STATUS_VALUES = {
    [CLINICS_STATUS_TYPES.ACTIVE_KEY]: CLINICS_STATUS_TYPES.ACTIVE_NAME,
    [CLINICS_STATUS_TYPES.INACTIVE_KEY]: CLINICS_STATUS_TYPES.INACTIVE_NAME
}
