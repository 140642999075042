<template>
  <div>
    <v-container fluid class="py-6">
      <v-row align="center" justify="space-between" class="my-0">
        <v-col cols="12" lg="auto" class="py-0 mb-4 mb-lg-0">
          <span>Eksportere udvalgte kunder:</span>
        </v-col>
        <v-col class="py-0 mb-4 mb-md-0">
          <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field :value="formattedDateFrom" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuFrom = true" label="Dato" readonly hide-details v-on="on"/>
            </template>
            <v-date-picker :min="minDateFrom" :max="dateTo" v-model="dateFrom" no-title @change="menuFrom = false" color="primary lighten-0"/>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="py-0 px-0 mb-4 mb-md-0">
          <v-icon size="15">icon-minus</v-icon>
        </v-col>
        <v-col class="py-0 mb-4 mb-md-0">
          <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field :value="formattedDateTo" class="datepicker-input my-0 py-0" append-icon="mdi-calendar" @click:append="menuTo = true" readonly hide-details v-on="on"/>
            </template>
            <v-date-picker :min="dateFrom" :max="maxDateTo" v-model="dateTo" no-title @change="menuTo = false" color="primary lighten-0"/>
          </v-menu>
        </v-col>
        <v-col cols="12" md="auto" class="py-0">
          <v-btn depressed class="mb-2 mb-md-0" :color="getButtonColor" @click="getVisits" :loading="getLoading" :disabled="getLoading || exportLoading || !dateFrom && !dateTo">
            <v-icon left size="22" class="mr-4">icon-search</v-icon>
            Søg
          </v-btn>
        </v-col>
        <v-col cols="12" md="auto" class="py-0">
          <v-btn depressed outlined :color="exportButtonColor" :loading="exportLoading" :disabled="exportLoading || selected.length === 0" @click="exportVisits">Exporter valgte kunder</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table v-if="visits.length > 0" class="export-visits-table" v-model="selected" :item-class="isExportedClass" :headers="headers" :items="visits" item-key="id" :items-per-page="params.PageSize" :mobile-breakpoint="0" fixed-header show-select hide-default-footer/>
  </div>
</template>

<script>
  import api from '../../api/api';
  import methods from '../../utils/methods';

  export default {
    name: 'OverviewExportByDate',

    data: function () {
      return {
        menuFrom: false,
        dateFrom: null,
        menuTo: false,
        dateTo: null,
        headers: [
          {value: 'data-table-select', sortable: false, width: '5%'},
          {text: 'Nr.', value: 'id', sortable: false, width: '15%'},
          {text: 'CPR-nummer', value: 'cpr', sortable: false, width: '20%'},
          {text: 'Patient', value: 'patientName', sortable: false, width: '20%'},
          {text: 'Dato', value: 'createDate', sortable: false, width: '20%'},
          {text: 'Betalingst', value: 'paymentType', sortable: false, width: '20%'},
        ],
        visits: [],
        selected: [],
        params: {
          OrderBy: 5,
          OrderByAscending: false,
          PageSize: 1000,
        },
        getLoading: false,
        getButtonColor: 'primary',
        exportLoading: false,
        exportButtonColor: 'primary',
      };
    },

    computed: {
      minDateFrom: function () {
        if (this.dateTo) {
          let [year, month, day] = this.dateTo.split('-');
          return `${parseInt(year) - 1}-${month}-${day}`;
        } else {
          return null;
        }
      },
      maxDateTo: function () {
        if (this.dateFrom) {
          let [year, month, day] = this.dateFrom.split('-');
          return `${parseInt(year) + 1}-${month}-${day}`;
        } else {
          return null;
        }
      },
      formattedDateFrom: function () {
        if (this.dateFrom) {
          return methods.formatDate(this.dateFrom);
        } else {
          return 'Fra';
        }
      },
      formattedDateTo: function () {
        if (this.dateTo) {
          return methods.formatDate(this.dateTo);
        } else {
          return 'Til';
        }
      },
    },

    methods: {
      isExportedClass: function (item) {
        return item.exportedToCsv && 'teal lighten-5';
      },
      paymentType: function (paymentType) {
        switch (paymentType) {
          case 0:
            return 'Egenbet';
          case 1:
            return 'Forsikringssag';
          case 2:
            return 'Regionstandpleje';
          case 3:
            return 'BUT';
        }
      },
      getVisits: async function () {
        try {
          let dates = {};
          this.visits = [];
          this.selected = [];
          this.getLoading = true;
          this.dateFrom && (dates.DateFrom = new Date(this.dateFrom).toISOString());
          this.dateTo && (dates.DateTo = new Date(this.dateTo).toISOString());
          let {data} = await api.getVisits({...dates, ...this.params});
          data.paginationResult.entities.forEach((item) => {
            item.patientName = `${item.patientFirstName ? item.patientFirstName.trim() : '--'} ${item.patientLastName ? item.patientLastName.trim() : '..'}`;
            item.createDate = item.createDate ? methods.getDateFromUtc(item.createDate) : '--.--';
            item.paymentType = item.paymentType !== null ? this.paymentType(item.paymentType) : '--';
          });
          this.visits = data.paginationResult.entities;
          this.getLoading = false;
        } catch (e) {
          this.visits = [];
          this.selected = [];
          this.getButtonColor = 'error';
        }
      },
      exportVisits: async function () {
        try {
          let queryData = {};
          let visitIds = [];
          this.selected.forEach((item) => {
            visitIds.push(item.id);
          });
          queryData.ids = visitIds;
          this.exportLoading = true;
          let data = await api.exportVisitsById(queryData);
          methods.downloadBlob(URL.createObjectURL(methods.encodeBlob(data.data)), methods.getHeaderParameter('filename', data.headers['content-disposition']));
          this.$emit('updateVisits');
          this.visits = [];
          this.selected = [];
          this.dateFrom = null;
          this.dateTo = null;
          this.exportLoading = false;
        } catch (e) {
          this.exportLoading = false;
          this.exportButtonColor = 'error';
        }
      },
    },
  };
</script>
