<template>
  <v-container v-if="!loading" :class="['px-xl-7', isTransparent]" fluid>
    <v-row class="my-0 align-start">
      <v-col cols="12" lg="6" class="pr-lg-7">
        <v-row class="my-0 mb-5">
          <v-col class="py-0">
            <v-text-field v-model="address" label="Adresse" hide-details/>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-5">
          <v-col cols="4" class="py-0">
            <v-text-field v-model="zip" label="Postnr" hide-details/>
          </v-col>
          <v-col cols="8" class="py-0">
            <v-text-field v-model="city" label="Bynavn" hide-details/>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-5">
          <v-col class="py-0">
            <v-text-field v-model="email" label="Email" hide-details/>
            <span v-if="!visitItem.patientEmail" class="d-flex align-center caption mt-1 red--text"><v-icon size="20" class="mr-1 red--text">mdi-alert-circle</v-icon>Patienten har kun en systemmail</span>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-5">
          <v-col cols="6" class="py-0">
            <v-text-field v-model="phoneNumber" label="Tlf." hide-details/>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field v-model="mobilePhone" label="Mobil nr." hide-details/>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-5" align="center">
          <v-col cols="12" lg="auto" class="py-0 mb-2 mb-lg-0">
            <span class="text-body-1">Indkaldelsestype:</span>
          </v-col>
          <v-col cols="12" lg="auto" class="py-0">
            <v-radio-group hide-details row v-model="callUpType" class="my-0 pt-0">
              <v-radio class="my-2 mr-7" label="SMS" :value="2" color="primary" :disabled='!this.mobilePhone'/>
              <v-radio class="my-2 mr-7" label="Email" :value="0" color="primary"/>
              <v-radio class="my-2 mr-0" label="Brev" :value="1" color="primary"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-5">
          <v-col class="py-0">
            <v-textarea v-model="comment" label="Kommentar" outlined :auto-grow="true" :rows="2" row-height="35" hide-details/>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-4">
          <v-col cols="auto" class="py-0 ml-lg-auto">
            <span v-if="modifyDate" class="text-caption d-inline-block grey--text text--darken-2">{{ `Sidst redigeret af: ${visitItem.modifierFullName} ${modifyDate}` }}</span>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-4">
          <v-col cols="auto" class="py-0 ml-lg-auto">
            <v-btn depressed :color="sendIncallColor" :loading="sendIncallLoading" :disabled="sendIncallLoading" @click="sendIncall(true)">Send Indkaldelse</v-btn>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-4">
          <v-col cols="auto" class="py-0 ml-lg-auto">
            <v-btn depressed :color="updateIncallColor" :loading="updateIncallLoading" :disabled="updateIncallLoading" @click="updateIncall(false)">Opdater information</v-btn>
          </v-col>
        </v-row>
        <v-row class="my-0 mb-4">
          <v-col cols="auto" class="py-0 ml-lg-auto">
            <v-btn depressed :color="sendCallUpPhoneColor" :loading="sendCallUpPhoneLoading" :disabled="sendCallUpPhoneLoading" @click="sendCallUpPhone">Patienten har fået tid pr. telefon</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="pa-5" style="background: #0000000f; border-radius: 5px; border: 1px solid #ccc">
          <v-row class="my-0 mb-10">
            <v-col cols="12" md="6" class="py-0">
              <div :class="[captionClasses]">Behandling</div>
              <div v-if="treatmentTypeValue" class="text-body-1">{{ treatmentTypeValue }}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <div :class="[captionClasses]">Har tid</div>
              <div v-if="operationDate" class="text-body-1">{{ operationDate }}</div>
              <div v-else class="text-body-1 grey--text">Ingen data...</div>
            </v-col>
          </v-row>
          <CallUpLogs :visitItem="visitItem"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <v-progress-linear indeterminate color="blue-grey" :height="1"/>
  </div>
</template>

<script>
  import methods from '../../utils/methods';
  import HeadingBox from '../../components/common/HeadingBox';
  import api from '../../api/api';
  import CallUpLogs from './CallUpLogs';
  import {COMMON_TYPES} from '@/constants/commonTypes';

  export default {
    name: 'CallUpExpanded',

    components: {CallUpLogs, HeadingBox},

    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        loading: true,
        captionClasses: [...COMMON_TYPES.CAPTION_CLASSES],
        address: '',
        zip: '',
        city: '',
        email: '',
        phoneNumber: '',
        mobilePhone: '',
        callUpType: 0,
        comment: '',
        sendIncallLoading: false,
        sendIncallColor: 'primary',
        updateIncallLoading: false,
        updateIncallColor: 'primary',
        sendCallUpPhoneLoading: false,
        sendCallUpPhoneColor: 'primary',
      };
    },

    mounted: async function () {
      this.loading = true;
      let visitData = await api.getIncalls({VisitNumber: this.visitItem.id});
      setTimeout(() => {
        this.loading = false;
        this.$emit('updateCommonInfo', visitData.data.paginationResult.entities[0]);
        this.setEditableValues(this.visitItem);
      }, 500);
    },

    computed: {
      filterParams: function () {
        return this.$store.state.callUp.filterParams;
      },
      isTransparent: function () {
        let visible = false;
        for (let index = 0; index < this.filterParams.Statuses.length; index++) {
          const element = this.filterParams.Statuses[index];
          if (element === this.visitItem.status) {
            visible = true;
            break;
          }
        }
        if (!visible) return 'transparent-area';
      },
      treatmentTypes: function () {
        return this.$store.state.treatmentTypes;
      },
      treatmentTypeValue: function () {
        if (this.visitItem.treatmentType === null) return false;
        if (this.visitItem.treatmentType === -100) return this.treatmentTypeValue = 'Unknown treatment';
        for (let index = 0; index < this.treatmentTypes.length; index++) {
          const element = this.treatmentTypes[index];
          if (element.id === this.visitItem.treatmentType) {
            return element.name;
          }
        }
      },
      operationDate: function () {
        if (this.visitItem.operationDate) {
          return methods.getSimpleDate(this.visitItem.operationDate);
        } else {
          return false;
        }
      },
      modifyDate: function () {
        if (this.visitItem.modifyDate) {
          return `${methods.getSimpleDate(this.visitItem.modifyDate)} ${methods.getSimpleTime(this.visitItem.modifyDate)}`;
        } else {
          return false;
        }
      },
    },

    methods: {
      setEditableValues: function (visitItem) {
        this.address = visitItem.patientAddress ? visitItem.patientAddress : '';
        this.zip = visitItem.patientZip ? visitItem.patientZip : '';
        this.city = visitItem.patientCity ? visitItem.patientCity : '';
        this.email = visitItem.patientEmail ? visitItem.patientEmail : '';
        this.phoneNumber = visitItem.patientPhone ? visitItem.patientPhone : '';
        this.mobilePhone = visitItem.patientMobilePhone ? visitItem.patientMobilePhone : '';
        this.callUpType = visitItem.callUpType;
        this.comment = visitItem.callUpComment ? visitItem.callUpComment : '';
      },
      createQueryData: function (recallValue) {
        let queryData = {};
        queryData.recall = recallValue;
        this.visitItem.id && (queryData.visitId = this.visitItem.id);
        this.address && (queryData.patientAddress = this.address);
        this.zip && (queryData.patientZip = this.zip);
        this.city && (queryData.patientCity = this.city);
        this.email && (queryData.patientEmail = this.email);
        this.phoneNumber && (queryData.patientPhone = this.phoneNumber);
        this.mobilePhone && (queryData.patientMobilePhone = this.mobilePhone);
        this.comment && (queryData.incallComment = this.comment);
        queryData.incallType = this.callUpType;
        return queryData;
      },
      sendIncall: async function (recallValue) {
        try {
          this.sendIncallLoading = true;
          await api.sendIncall(this.createQueryData(recallValue));
          let newVisit = await api.getIncalls({VisitNumber: this.visitItem.id});
          this.setEditableValues(newVisit.data.paginationResult.entities[0]);
          this.$emit('updateCommonInfo', newVisit.data.paginationResult.entities[0]);
          this.sendIncallLoading = false;
          this.sendIncallColor = 'success';
        } catch (e) {
          this.sendIncallLoading = false;
          this.sendIncallColor = 'error';
        }
      },
      updateIncall: async function (recallValue) {
        try {
          this.updateIncallLoading = true;
          await api.sendIncall(this.createQueryData(recallValue));
          let newVisit = await api.getIncalls({VisitNumber: this.visitItem.id});
          this.setEditableValues(newVisit.data.paginationResult.entities[0]);
          this.$emit('updateCommonInfo', newVisit.data.paginationResult.entities[0]);
          this.updateIncallLoading = false;
          this.updateIncallColor = 'success';
        } catch (e) {
          this.updateIncallLoading = false;
          this.updateIncallColor = 'error';
        }
      },
      sendCallUpPhone: async function () {
        let queryData = {};
        this.visitItem.id && (queryData.visitId = this.visitItem.id);
        this.address && (queryData.patientAddress = this.address);
        this.zip && (queryData.patientZip = this.zip);
        this.city && (queryData.patientCity = this.city);
        this.email && (queryData.patientEmail = this.email);
        this.phoneNumber && (queryData.patientPhone = this.phoneNumber);
        this.mobilePhone && (queryData.patientMobilePhone = this.mobilePhone);
        this.comment && (queryData.incallComment = this.comment);
        queryData.incallType = this.callUpType;
        try {
          this.sendCallUpPhoneLoading = true;
          await api.sendCallUpPhone(queryData);
          let newVisit = await api.getIncalls({VisitNumber: this.visitItem.id});
          this.setEditableValues(newVisit.data.paginationResult.entities[0]);
          this.$emit('updateCommonInfo', newVisit.data.paginationResult.entities[0]);
          this.sendCallUpPhoneLoading = false;
          this.sendCallUpPhoneColor = 'success';
        } catch (e) {
          this.sendCallUpPhoneLoading = false;
          this.sendCallUpPhoneColor = 'error';
        }
      },
    },
  };
</script>
