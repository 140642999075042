export default {
  namespaced: true,
  state: {
    dataType: null,
    treatmentTableLoading: false,
    doctorTableLoading: false,
    filterParams: {},
  },
  mutations: {
    updateDataType(state, payload) {
      state.dataType = payload;
    },
    updateTreatmentTableLoading(state, payload) {
      state.treatmentTableLoading = payload;
    },
    updateDoctorTableLoading(state, payload) {
      state.doctorTableLoading = payload;
    },
    updateFilterParams(state, payload) {
      state.filterParams = payload;
    },
  },
};