<template>
  <div class="data-section statistics-data">
    <StatisticsTreatmentsData v-if="dataType === 0"/>
    <StatisticsDoctorsData v-if="dataType === 1"/>
    <StatisticsExport/>
  </div>
</template>

<script>
  import StatisticsTreatmentsData from './StatisticsTreatmentsData';
  import StatisticsDoctorsData from './StatisticsDoctorsData';
  import StatisticsExport from './StatisticsExport';

  export default {
    name: 'StatisticsData',

    components: {StatisticsExport, StatisticsDoctorsData, StatisticsTreatmentsData},

    computed: {
      dataType: function () {
        return this.$store.state.statistics.dataType;
      },
    },
  };
</script>