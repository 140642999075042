<template>
  <HeadingBox :class-array="['mb-13']" icon="mdi-printer" title="Udskrivningsbrev" subtitle="U-brev eventuelle røntgenbilleder / filer downloades her">
    <v-row class="my-0" v-if="visitItem.terminationLetters && visitItem.terminationLetters.length > 0">
      <OverviewLettersRow v-for="(letter, index) in visitItem.terminationLetters" :key='index' :letter='letter' :visitItem='visitItem' @updateVisitItem="updateVisitItem($event)"/>
    </v-row>
    <div v-else class="text-body-1 grey--text">Ingen data...</div>
  </HeadingBox>
</template>

<script>
  import HeadingBox from '../common/HeadingBox';
  import OverviewLettersRow from '@/components/overview/OverviewLettersRow.vue';

  export default {
    name: 'OverviewLetters',

    components: {OverviewLettersRow, HeadingBox},

    props: {
      visitItem: {
        type: Object,
      },
    },

    methods: {
      updateVisitItem: function (data) {
        this.$emit('updateVisitItem', data);
      },
    }
  };
</script>
