<template>
  <span :class="[statusColor, 'status-label', 'ml-3']"/>
</template>

<script>
  export default {
    name: 'CallUpStatusPin',

    props: {
      visitItem: {
        type: Object,
      },
    },

    computed: {
      statusTypes: function () {
        return this.$store.state.callUp.statusTypes;
      },
      statusColor: function () {
        for (let index = 0; index < this.statusTypes.length; index++) {
          const element = this.statusTypes[index];
          if (element.key === this.visitItem.status) {
            return element.color;
          }
        }
      },
    },
  };
</script>