import {USERS_STATUS_TYPES} from '@/constants/usersTypes';

export default {
    namespaced: true,
    state: {
        tableLoading: false,
        showDataTable: false,
        filterParams: {},
        activeClinicsLoading: false,
        activeClinics: [],
        statusItems: [
            {
                key: USERS_STATUS_TYPES.ACTIVE_KEY,
                value: USERS_STATUS_TYPES.ACTIVE_NAME
            },
            {
                key: USERS_STATUS_TYPES.PENDING_KEY,
                value: USERS_STATUS_TYPES.PENDING_NAME
            },
        ]
    },
    mutations: {
        updateTableLoading(state, payload) {
            state.tableLoading = payload;
        },
        updateFilterParams(state, payload) {
            state.filterParams = payload;
        },
        setActiveClinics(state, payload) {
            state.activeClinics = payload;
        },
        setActiveClinicsLoading(state, payload) {
            state.activeClinicsLoading = payload;
        },
        updateShowDataTable(state, payload) {
            state.showDataTable = payload;
        },
    },
};
