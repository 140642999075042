<template>
  <HeadingBox :class-array="['mb-13']" icon="mdi-message-text" title="Send besked" subtitle="Her kan der sendes beskeder imellem Kirurgiklinik og henviseren på den enkelte sag" :loading="messagesLoading">
    <div v-if="messagesList.length > 0">
      <div v-for="messageItem in messagesList" class="mb-2">
        <div v-if="messageItem.isFromAdmin">
          <v-alert border="left" color="grey lighten-2 d-inline-block" class="my-0" dense>
            <v-row justify="space-between" class="my-0">
              <v-col cols="auto" class="py-0">
                <div class="text-caption grey--text text--darken-2 font-weight-bold mb-1">Kirurgiklinik</div>
                <div class="text-caption grey--text text--darken-2">{{ messageItem.formattedDate }}</div>
              </v-col>
              <v-col cols="auto" class="py-0">
                <v-btn v-if="isAdmin" icon class="mt-n2 mr-n3" @click="messageItem.editMode = !messageItem.editMode">
                  <v-icon size="22" class="grey--text text--darken-2">mdi-square-edit-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <p class="my-0">{{ messageItem.text }}</p>
            <v-chip v-for="fileItem in messageItem.attachments" :key="fileItem.id" class="mr-2 mt-2" color="grey lighten-1" small link @click="downloadAttachment(visitItem.id, fileItem.id)">
              <span style="max-width: 200px" class="text-truncate d-inline-block">{{ fileItem.name }}</span>
            </v-chip>
          </v-alert>
        </div>
        <div v-if="!messageItem.isFromAdmin" class="text-right">
          <v-alert border="right" color="grey lighten-2 d-inline-block" class="my-0" dense>
            <v-row justify="space-between" class="my-0">
              <v-col cols="auto" class="py-0">
                <v-btn v-if="isDentist" icon class="mt-n2 ml-n3" @click="messageItem.editMode = !messageItem.editMode">
                  <v-icon size="22" class="grey--text text--darken-2">mdi-square-edit-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="py-0">
                <div class="text-caption grey--text text--darken-2 font-weight-bold mb-1">Henviser</div>
                <div class="text-caption grey--text text--darken-2">{{ messageItem.formattedDate }}</div>
              </v-col>
            </v-row>
            <p class="my-0">{{ messageItem.text }}</p>
            <v-chip v-for="fileItem in messageItem.attachments" :key="fileItem.id" class="ml-2 mt-2" color="grey lighten-1" small link @click="downloadAttachment(visitItem.id, fileItem.id)">
              <span style="max-width: 200px" class="text-truncate d-inline-block">{{ fileItem.name }}</span>
            </v-chip>
          </v-alert>
        </div>
        <OverviewEditArea v-if="messageItem.editMode" :messageItem="messageItem" @updateMessagesList="setMessages($event)" :visitItem="visitItem"/>
      </div>
    </div>
    <span v-else class="d-block text-body-1 grey--text">Ingen data...</span>
    <v-row class="mt-10">
      <v-col class="py-0">
        <v-textarea v-model="newMessage" label="Besked" auto-grow :rows="3" outlined hide-details/>
        <v-row align="center" justify="space-between" class="my-0">
          <v-col cols="12" md="auto" class="d-none d-md-flex">
            <span>Vedhæft filer:</span>
          </v-col>
          <v-col cols="12" md="3" class="mr-auto">
            <v-select class="my-0 py-0" :items="attachViews" item-text="name" item-value="key" v-model="attachViewSelected" placeholder="Vælg type" hide-details @change="changeAttachView"/>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn depressed outlined @click="markAsRead" :color="markAsReadButtonColor" :loading="markAsReadLoading" :disabled="markAsReadLoading">{{ markAsReadText }}</v-btn>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn depressed @click="sendMessage" :color="messageButtonColor" :loading="messageSendLoading" :disabled="messageSendLoading">Send besked</v-btn>
          </v-col>
        </v-row>
        <div v-if="attachViewSelected === 0" class="mt-5">
          <v-row v-for="(item, index) in xrayFiles" :key="item.id" align="center" class="my-0">
            <v-col cols="12" md="3" class="py-0">
              <v-select :items="xrayFileTypes" item-text="text" item-value="val" label="Image file type:" v-model="item.type"/>
            </v-col>
            <v-col cols="8" md="5" class="py-0">
              <v-file-input :value="item.file" show-size label="File" disabled :clearable="false"/>
            </v-col>
            <v-col class="py-0">
              <v-btn icon color="primary" @click="xrayRemoveFile(index)">
                <v-icon>icon-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" class="my-0 mt-5">
            <v-col cols="12" md="3" class="py-0">
              <v-select :items="xrayFileTypes" item-text="text" item-value="val" label="Image file type:" v-model="xrayNewType"/>
            </v-col>
            <v-col class="py-0">
              <div class="d-inline-block">
                <v-btn depressed color="primary" @click="xrayFileTrigger">
                  Gennemse...
                </v-btn>
                <input ref="fileUploader" class="d-none" :accept="availableFileTypes" multiple type="file" @input="xrayAddFile($event)"/>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="attachViewSelected === 1" class="mt-5">
          <div class="mb-2">
            <v-chip v-for="(item, index) in newMessageFiles" :key="item.id" class="mr-2 my-1" close @click:close="messageRemoveFile(index)">
              <span style="max-width: 200px" class="text-truncate d-inline-block">{{ item.file.name }}</span>
            </v-chip>
          </div>
          <v-btn depressed class="mt-3" color="primary" @click="messageFileTrigger">
            Gennemse...
          </v-btn>
          <input ref="fileAttach" class="d-none" type="file" multiple @input="messageAddFile($event)"/>
        </div>
      </v-col>
    </v-row>
  </HeadingBox>
</template>

<script>
  import methods from '../../utils/methods';
  import api from '../../api/api';
  import HeadingBox from '../common/HeadingBox';
  import OverviewEditArea from './OverviewEditArea';

  export default {
    name: 'OverviewMessages',

    components: {OverviewEditArea, HeadingBox},

    props: {
      visitItem: {
        type: Object,
      },
    },

    data: function () {
      return {
        messagesLoading: false,
        messagesList: [],
        newMessage: '',
        newMessageFiles: [],
        xrayNewType: 0,
        xrayFiles: [],
        messageButtonColor: 'primary',
        messageSendLoading: false,
        attachViews: [
          {
            key: 0,
            name: 'Røntgen',
          },
          {
            key: 1,
            name: 'Andre filer',
          },
        ],
        attachViewSelected: null,
        markAsReadLoading: false,
        markAsReadButtonColor: 'primary',
      };
    },

    mounted: async function () {
      this.getMessages();
    },

    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isDentist: function () {
        return this.$store.getters.isDentist;
      },
      xrayFileTypes: function () {
        return this.$store.state.xrayFileTypes;
      },
      availableFileTypes: function() {
        return this.xrayNewType === 4 ? '.dcm' : '.jpg,.jpeg,.gif,.png,.bmp'
      },
      markAsReadText: function () {
        switch (this.visitItem.haveUnansweredMessage) {
          case true:
            return 'Besked læst';
          case false:
            return 'Besked ulæst';
        }
      },
    },

    methods: {
      downloadAttachment: function (visitId, fileId) {
        methods.downloadURL(api.attachmentFileUrl(visitId, fileId));
      },
      changeAttachView: function () {
        this.newMessageFiles = [];
        this.xrayFiles = [];
        this.xrayNewType = 0;
      },
      messageFileTrigger: function () {
        this.$refs.fileAttach.click();
      },
      messageAddFile: function (data) {
        if (data.target.files.length > 0) {
          Object.keys(data.target.files).forEach(key => {
            let fileItem = data.target.files[key]
            let newId = crypto.randomUUID();
            this.newMessageFiles.push({
              id: newId,
              file: fileItem,
            });
          })
          data.target.value = '';
        }
      },
      messageRemoveFile: function (index) {
        this.$delete(this.newMessageFiles, index);
      },
      xrayFileTrigger: function () {
        this.$refs.fileUploader.click();
      },
      xrayAddFile: function (data) {
        if (data.target.files.length > 0) {
          Object.keys(data.target.files).forEach(key => {
            let fileItem = data.target.files[key]
            let newFileItem = {};
            let fileType = fileItem.name.split('.').pop().toLowerCase();
            switch (fileType) {
              case 'jpg':
              case 'jpeg':
              case 'gif':
              case 'png':
              case 'bmp':
              case 'dcm':
                newFileItem.id = crypto.randomUUID();
                newFileItem.file = fileItem;
                newFileItem.type = this.xrayNewType;
                this.xrayFiles.push(newFileItem);
                break;
              default:
                methods.showGlobalSnackbar('error', 'Denne filtype er ikke godkendt i dette felt.');
            }
          })
          data.target.value = '';
          this.xrayNewType = 0;
        }
      },
      xrayRemoveFile: function (index) {
        this.$delete(this.xrayFiles, index);
      },
      setMessages: function (data) {
        let newMessagesList = [];
        data.forEach((messageItem) => {
          messageItem.formattedDate = `${methods.getDateFromUtc(messageItem.createDate)} ${methods.getTimeFromUtc(messageItem.createDate)}`;
          messageItem.editMode = false;
          newMessagesList.push(messageItem);
        });
        this.messagesList = newMessagesList;
      },
      getMessages: async function () {
        this.messagesLoading = true;
        let messages = await api.getMessages(this.visitItem.id);
        this.messagesLoading = false;
        this.setMessages(messages.data);
      },
      sendMessage: async function () {
        try {
          let newData = new FormData();
          let xrayTypesArray = [];
          if (this.attachViewSelected === 0) {
            if (this.xrayFiles.length <= 0) return;
            newData.append('Text', `${this.newMessage} [ Rontgen uploaded: ${this.xrayFiles.map(item => item.file.name).join(', ')} ]`);
          } else {
            if (this.newMessage.trim().length <= 0 && this.newMessageFiles.length <= 0) return;
            newData.append('Text', this.newMessage.trim().length > 0 ? this.newMessage : 'Ingen tekst');
          }
          if (this.newMessageFiles.length > 0) {
            this.newMessageFiles.forEach((item) => {
              newData.append(`attachedFiles`, item.file);
            });
          }
          if (this.xrayFiles.length > 0) {
            this.xrayFiles.forEach((item) => {
              xrayTypesArray.push(item.type);
              newData.append(`Radiographs`, item.file);
            });
            newData.append('RadiographFileTypes', JSON.stringify(xrayTypesArray));
          }
          this.messageSendLoading = true;
          await api.sendMessage(newData, this.visitItem.id);
          let messages = await api.getMessages(this.visitItem.id);
          let newVisit = await api.getVisitInfo(this.visitItem.id);
          this.messageSendLoading = false;
          this.attachViewSelected = null;
          this.newMessage = '';
          this.newMessageFiles = [];
          this.xrayFiles = [];
          this.setMessages(messages.data);
          this.$emit('updateVisitItem', newVisit.data);
          this.$emit('updateCommonVisitInfo', newVisit.data);
        } catch (e) {
          this.messageSendLoading = false;
          this.messageButtonColor = 'error';
          if (e.response.status === 409) {
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      markAsRead: async function () {
        let newData = {};
        newData.visitId = this.visitItem.id;
        newData.read = this.visitItem.haveUnansweredMessage;
        try {
          this.markAsReadLoading = true;
          await api.markMessageAsRead(newData, this.visitItem.id);
          let newVisit = await api.getVisitInfo(this.visitItem.id);
          this.markAsReadLoading = false;
          this.$emit('updateVisitItem', newVisit.data);
          this.$emit('updateCommonVisitInfo', newVisit.data);
        } catch (e) {
          this.markAsReadLoading = false;
          this.markAsReadButtonColor = 'error';
        }
      },
    },
  };
</script>
