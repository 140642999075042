<template>
  <div class="intro-section mb-5">
    <v-container fluid class="py-5 px-lg-16">
      <v-row class="my-0 py-0" justify="center">
        <v-col cols="12" md="6" lg="5" class="py-0">
          <v-row class="my-0 py-0 mb-3">
            <v-col cols="12" class="py-0">
              <v-text-field v-model="patientName" label="Patient navn" @keyup.enter="setFilterParams"/>
            </v-col>
          </v-row>
          <v-row justify="space-between" class="my-0 py-0 mb-3">
            <v-col cols="12" md="5" class="py-0">
              <v-text-field label="Visitations nr." v-model="visitNumber" @keyup.enter="setFilterParams"/>
            </v-col>
            <v-col cols="7" md="4" class="py-0 pr-0">
              <v-text-field label="CPR-Nummer" v-model="cprNumber" maxlength="6" @paste.prevent="cprPasteHandler" @input="cprInputHandler" @keyup.enter="setFilterParams"/>
            </v-col>
            <v-col cols="4" md="3" class="py-0">
              <v-text-field ref="cprAdd" v-model="cprNumberAdd" maxlength="4" @keyup.enter="setFilterParams"/>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0 mb-6">
            <v-col cols="auto" class="py-0">
              <v-checkbox :class="['my-0', 'py-0']" v-model="onlyEdi" hide-details>
                <template v-slot:label>
                  EDI
                  <v-icon size="21" color="#48aa7b" class="ml-2">icon-edi</v-icon>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="5" class="py-0">
          <v-row justify="space-between" class="my-0 py-0 mb-3">
            <v-col class="py-0">
              <v-menu ref="menuFrom" v-model="menuFrom" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field :value="formattedDateFrom" class="datepicker-input" append-icon="mdi-calendar" @click:append="menuFrom = true" label="Dato" readonly v-on="on"/>
                </template>
                <v-date-picker v-model="dateFrom" no-title @change="menuFrom = false" color="primary lighten-0"/>
              </v-menu>
            </v-col>
            <v-col cols="auto" class="py-0 px-0" align-self="center">
              <v-icon size="15">icon-minus</v-icon>
            </v-col>
            <v-col class="py-0">
              <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field :value="formattedDateTo" class="datepicker-input" append-icon="mdi-calendar" @click:append="menuTo = true" readonly v-on="on"/>
                </template>
                <v-date-picker v-model="dateTo" no-title @change="menuTo = false" color="primary lighten-0"/>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0 mb-3" v-if="isAdmin || isSecretary">
            <v-col class="py-0">
              <v-autocomplete :items="doctors" item-text="name" item-value="id" label="Henviser" v-model="selectedDoctor" placeholder="Alle"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="intro-section__bottom-box">
      <v-container class="py-5 px-lg-5" fluid>
        <v-row justify="space-between" align="center" class="my-0 py-0">
          <v-col cols="auto" class="py-0 ml-lg-auto">
            <v-btn depressed class="mb-2 mr-2 mb-md-0" color="primary" outlined @click="clearFilters" :disabled="tableLoading">
              <v-icon left size="25" class="mr-4">icon-close</v-icon>
              Reset filtre
            </v-btn>
            <v-btn depressed class="mb-2 mb-md-0" color="primary" @click="setFilterParams" :disabled="tableLoading">
              <v-icon left size="22" class="mr-4">icon-search</v-icon>
              Søg
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
  import methods from '../../utils/methods';

  export default {
    name: 'TrackingFilters',

    data: function () {
      return {
        patientName: '',
        visitNumber: '',
        cprNumber: '',
        cprNumberAdd: '',
        menuFrom: false,
        dateFrom: null,
        menuTo: false,
        dateTo: null,
        selectedDoctor: null,
        onlyEdi: false,
      };
    },

    mounted: function() {
      this.setFilterParams();
      this.$store.commit('endingLetters/updateShowDataTable', true);
    },

    computed: {
      doctors: function () {
        let emptyItem = [
          {
            id: null,
            name: 'Alle',
          },
        ];
        if (this.$store.state.doctors.length > 0) {
          return [...emptyItem, ...this.$store.state.doctors];
        } else {
          return this.$store.state.doctors;
        }
      },
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      isSecretary: function () {
        return this.$store.getters.isSecretary;
      },
      tableLoading: function () {
        return this.$store.state.endingLetters.tableLoading;
      },
      formattedDateFrom: function () {
        if (this.dateFrom) {
          return methods.formatDate(this.dateFrom);
        } else {
          return 'Fra';
        }
      },
      formattedDateTo: function () {
        if (this.dateTo) {
          return methods.formatDate(this.dateTo);
        } else {
          return 'Til';
        }
      },
    },

    methods: {
      cprPasteHandler: function(event) {
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');
        if (pastedData?.length > 6) {
          if (pastedData.includes('-')) {
            this.cprNumber = pastedData.split('-')[0].slice(0, 6);
            this.cprNumberAdd = pastedData.split('-')[1].slice(0, 4);
          } else {
            this.cprNumber = pastedData.slice(0, 6);
            this.cprNumberAdd = pastedData.slice(6, 10);
          }
          this.$refs.cprAdd.focus();
        } else if (pastedData?.length === 6) {
          this.cprNumber = pastedData;
          this.$refs.cprAdd.focus();
        } else {
          this.cprNumber = pastedData;
        }
      },
      cprInputHandler: function (value) {
        if (value?.length === 6) {
          this.$refs.cprAdd.focus();
        }
      },
      clearFilters: function () {
        this.patientName = '';
        this.visitNumber = '';
        this.cprNumber = '';
        this.cprNumberAdd = '';
        this.dateFrom = null;
        this.dateTo = null;
        this.selectedDoctor = null;
        this.onlyEdi = false;
        this.setFilterParams();
      },
      filterParams: function () {
        let params = {};
        this.patientName && (params.PatientName = this.patientName);
        this.visitNumber && (params.VisitNumber = this.visitNumber);
        this.cprNumber && (params.Cpr = this.cprNumber);
        this.cprNumberAdd && (params.Cpr2 = this.cprNumberAdd);
        this.selectedDoctor && (params.DoctorId = this.selectedDoctor);
        this.dateFrom && (params.DateFrom = new Date(this.dateFrom).getTime() / 1000);
        this.dateTo && (params.DateTo = new Date(this.dateTo).getTime() / 1000);
        this.onlyEdi && (params.OnlyEdi = this.onlyEdi);
        return params;
      },
      setFilterParams: function () {
        if (!this.tableLoading) {
          this.$store.commit('endingLetters/updateFilterParams', this.filterParams());
        }
      },
    },
  };
</script>
